import {
  GET_PURCHASE_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_BY_TYPE_SUCCESS,
  GET_PURCHASE_GROUP_LIST_SUCCESS,
  GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS,
  CREATE_PURCHASE_SUCCESS,
  CREATE_PURCHASE_INIT,
  CREATE_PURCHASE_DONE,
  PURCHASE_REG_FILE_UPLOAD,
  PURCHASE_REG_FILE_UPLOAD_SUCCESS,
  VALIDATE_CSV_FILE,
  VALIDATE_CSV_FILE_SUCCESS,

  //gst2aPurchaseRegisterinvoice
  GSTR2A_PR_INVOICE_INIT,
  GSTR2A_PR_INVOICE_FINISH,
  GSTR2A_PR_INVOICE_SUCCESS,
  GSTR2A_PR_INVOICE_FAILED,

  //gst2aPurchaseRegisterSupplier 
  GSTR2A_PR_SUPPLIER_INIT,
  GSTR2A_PR_SUPPLIER_FINISH,
  GSTR2A_PR_SUPPLIER_SUCCESS,
  GSTR2A_PR_SUPPLIER_FAILED,
  DOWNLOAD_ERRORS_CSV_SUCCESS,
  DOWNLOAD_ERRORS_CSV,

  ITC_TRACKER_INIT,
  ITC_TRACKER_FINISH,
  ITC_TRACKER_SUCCESS,
  ITC_TRACKER_FAILED,

  CONTACT_INIT,
  CONTACT_FINISH,
  CONTACT_SUCCESS,
  CONTACT_FAILED,

  ITC_AVAILABLE_DATA_UPDATE,

  ITC_PENDING_2BMISSING_INIT,
  ITC_PENDING_2BMISSING_FINISH,
  ITC_PENDING_2BMISSING_SUCCESS,
  ITC_PENDING_2BMISSING_FAILED,


  DELETE_PURCHASE_INVOICE_LIST_SUCCESS,
  DELETE_PURCHASE_INVOICE_LIST,

  PURCHASE_INVOICE_DOWNLOAD_INIT,
  PURCHASE_INVOICE_DOWNLOAD_FINISH,

  ITC_TRACKER_EXCEL_DOWNLOAD_INIT,
  ITC_TRACKER_EXCEL_DOWNLOAD_FINISH,

  PURCHASE_SUPPLIER_DOWNLOAD_INIT,
  PURCHASE_SUPPLIER_DOWNLOAD_FINISH,
  UPDATE_CONTACT_BY_ID_SUCCESS,
  UPDATE_CONTACT_BY_ID,

} from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";


const initialState = {
  purchaseInvoiceList: [],
  invoiceListByType: [],
  purchaseGroupList: [],
  purchaseAsVoucherData: {},
  purchseSaveStatus: InsertFunctionStatus.NONE,
  purchaseCsvUploadResp: null,
  csvValidationResp: null,
  gstr2aPrInvoiceData: null,
  gstr2aPrSupplierData: null,
  itcTrackerData: [],
  contactData: {},
  itcTrackerData_report_date: '',
  itcTrackerMonthData: {},
  gstr2aPrSupplierStatus: true,
  csvErrorsResponce: null,
  purchaseInvDeleteStatus: null,

  prAutoReconcile: false,
  invoiceDownloadStatus: false,
  supplierDownloadStatus: false,
  itcTrackerReqStatus: true,
  itcTrackerExcelDownloadStatus: false,
  updateContactResponce: null
};
export default function purchaseReducer(state = initialState, action) {
  switch (action.type) {



    case "GSTR2A_2B_AUTO_RECONCILED": {
      return { ...state, prAutoReconcile: action.payload }
    }


    // Gstr2A Purchase register invoice

    case GSTR2A_PR_INVOICE_INIT: {
      return { ...state, gstr2aPrInvoiceReqStatus: true };
    }
    case GSTR2A_PR_INVOICE_FINISH: {
      return { ...state, gstr2aPrInvoiceReqStatus: false };
    }

    case GSTR2A_PR_INVOICE_SUCCESS: {
      return {
        ...state,
        gstr2aPrInvoiceData: action.payload,
      };
    }

    case GSTR2A_PR_INVOICE_INIT: {
      return {
        ...state,
        gstr2aPrInvoiceData: null,
      };
    }


    case PURCHASE_INVOICE_DOWNLOAD_INIT: {
      return { ...state, invoiceDownloadStatus: true }
    }
    case PURCHASE_INVOICE_DOWNLOAD_FINISH: {
      return { ...state, invoiceDownloadStatus: false }
    }


    case ITC_TRACKER_EXCEL_DOWNLOAD_INIT: {
      return { ...state, itcTrackerExcelDownloadStatus: true }
    }
    case ITC_TRACKER_EXCEL_DOWNLOAD_FINISH: {
      return { ...state, itcTrackerExcelDownloadStatus: false }
    }



    // Gstr2A Purchase register supplier

    case GSTR2A_PR_SUPPLIER_INIT: {
      return { ...state, gstr2aPrSupplierReqStatus: true };
    }
    case GSTR2A_PR_SUPPLIER_FINISH: {
      return { ...state, gstr2aPrSupplierReqStatus: false };
    }

    case GSTR2A_PR_SUPPLIER_SUCCESS: {
      return {
        ...state,

        gstr2aPrSupplierData: action.payload,

      };
    }

    case GSTR2A_PR_SUPPLIER_INIT: {
      return {
        ...state,
        gstr2aPrSupplierData: null
      };
    }


    case PURCHASE_SUPPLIER_DOWNLOAD_INIT: {
      return { ...state, supplierDownloadStatus: true }
    }
    case PURCHASE_SUPPLIER_DOWNLOAD_FINISH: {
      return { ...state, supplierDownloadStatus: false }
    }



    //itc tracker 

    case ITC_TRACKER_INIT: {
      return { ...state, itcTrackerReqStatus: true };
    }
    case ITC_TRACKER_FINISH: {
      return { ...state, itcTrackerReqStatus: false };
    }

    case ITC_TRACKER_SUCCESS: {
      return {
        ...state,
        itcTrackerData: action.payload.itc_data,
        itcTrackerData_report_date: action.payload.report_date
      };
    }

    case ITC_AVAILABLE_DATA_UPDATE: {
      return { ...state, itcTrackerData: action.payload }
    }

    //contacts 

    case CONTACT_INIT: {
      return { ...state, contactReqStatus: true };
    }
    case CONTACT_FINISH: {
      return { ...state, contactReqStatus: false };
    }

    case CONTACT_SUCCESS: {
      return { ...state, contactData: action.payload }

    }

    //itc month wise 

    case ITC_PENDING_2BMISSING_INIT: {
      return { ...state, itcTrackerMonthReqStatus: true };
    }
    case ITC_PENDING_2BMISSING_FINISH: {
      return { ...state, itcTrackerMonthReqStatus: false };
    }

    case ITC_PENDING_2BMISSING_SUCCESS: {
      return {
        ...state,
        itcTrackerMonthData: action.payload,
      };
    }
   


    case GET_PURCHASE_INVOICE_LIST_SUCCESS: {
      return { ...state, purchaseInvoiceList: action.payload };
    }
    case GET_INVOICE_LIST_BY_TYPE_SUCCESS: {
      return { ...state, invoiceListByType: action.payload };
    }
    case GET_PURCHASE_GROUP_LIST_SUCCESS: {
      return { ...state, purchaseGroupList: action.payload };
    }
    case GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS: {
      return { ...state, purchaseAsVoucherData: action.payload };
    }
    case CREATE_PURCHASE_SUCCESS: {
      return { ...state, purchseSaveStatus: InsertFunctionStatus.SUCCESS };
    }
    case CREATE_PURCHASE_INIT: {
      return { ...state, purchseSaveStatus: InsertFunctionStatus.NONE };
    }
    case CREATE_PURCHASE_DONE: {
      return { ...state, purchseSaveStatus: InsertFunctionStatus.DONE };
    }
    case PURCHASE_REG_FILE_UPLOAD: {
      return { ...state, purchaseCsvUploadResp: null };
    }
    case PURCHASE_REG_FILE_UPLOAD_SUCCESS: {
      return { ...state, purchaseCsvUploadResp: action.payload };
    }
    case VALIDATE_CSV_FILE: {
      return { ...state, csvValidationResp: null };
    }
    case VALIDATE_CSV_FILE_SUCCESS: {
      return { ...state, csvValidationResp: action.payload };
    }
    case DOWNLOAD_ERRORS_CSV: {
      return { ...state, csvErrorsResponce: null };
    }
    case DOWNLOAD_ERRORS_CSV_SUCCESS: {
      return { ...state, csvErrorsResponce: action.payload };
    }
    case DELETE_PURCHASE_INVOICE_LIST: {
      return { ...state, purchaseInvDeleteStatus: null };
    }
    case DELETE_PURCHASE_INVOICE_LIST_SUCCESS: {
      return { ...state, purchaseInvDeleteStatus: action.payload };
    }
    case UPDATE_CONTACT_BY_ID: {
      return { ...state, updateContactResponce: null };
    }
    case UPDATE_CONTACT_BY_ID_SUCCESS: {
      return { ...state, updateContactResponce: action.payload };
    }
    default:
      return state;
  }
}
