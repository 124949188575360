import {
  GET_EXPENSES,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAILURE,
  GET_BILL_PAY_RECEIVABLES,
  GET_BILL_PAY_RECEIVABLES_SUCCESS,
  GET_BILL_PAY_RECEIVABLES_FAILURE,
  GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
  expenseList: [],
  billPayReceivalbleList:[],
  billListByLedgerIdAndRefNo:[]
};
export default function expenseReduser(state = initialState, action) {
  switch (action.type) {
    case GET_EXPENSES: {

      return { ...state, expenseList: getList.inprogress };

    } case GET_EXPENSES_SUCCESS: {

      return { ...state, expenseList: action.payload };
      
    }case GET_BILL_PAY_RECEIVABLES: {

      return { ...state, billPayReceivalbleList: getList.inprogress };

    }case GET_BILL_PAY_RECEIVABLES_SUCCESS: {

      return { ...state, billPayReceivalbleList: action.payload };

    }case GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS: {

      return { ...state, billListByLedgerIdAndRefNo: action.payload };
    }
    
    
    default:
      return state;
  }
}
