import {FETCH_TRANSACTIONS_INIT, FETCH_TRANSACTIONS_FINISH, FETCH_TRANSACTIONS_SUCCESS, FETCH_TRANSACTIONS_FAILED} from '../../action-type/Gstr1';

const initialState = {
    fetchingTransaction: false,
    fetchTransactionSuccess: null,
    transactionsData: null,
};

const Gstr1TransactionsReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_TRANSACTIONS_INIT: 
            return {...state, fetchingTransaction: true}

        case FETCH_TRANSACTIONS_FINISH:
            return {...state, fetchingTransaction: false}
            
        case FETCH_TRANSACTIONS_SUCCESS:
            return {...state, transactionsData: action.payload, fetchTransactionSuccess: true}
            
        case FETCH_TRANSACTIONS_FAILED:
            return {...state, transactionsData: null, fetchTransactionSuccess: false}
        
        default:
            return state;
    }
};

export default Gstr1TransactionsReducer;