import {
    POST_CREATE_GROUP_DATA,
    POST_CREATE_GROUP_DATA_SUCCESS,
    POST_CREATE_GROUP_DATA_ERROR,
    CLEAR_POST_GROUP_DATA_RESP
   } from "src/actions/actionTypes";
   
   const initialState = {
     onGroupDataSubmit:''
   };

   export default function postGroupDataReducer(state = initialState, action) {
    switch (action.type) {
     case POST_CREATE_GROUP_DATA_SUCCESS: {
       return { ...state, onGroupDataSubmit: action.payload };
     }
     case POST_CREATE_GROUP_DATA_ERROR: {
      return { ...state, onGroupDataSubmit: action.payload };
    }
    case CLEAR_POST_GROUP_DATA_RESP: {
        return { ...state, onGroupDataSubmit: initialState };
      }
      default:
        return state;
    }
  }