import cookie from "react-cookies";
import { globalApiUrl } from "src/util/globalUrls";
const { default: Axios } = require("axios");
const tallyStaticRepAxios = Axios.create({
  baseURL: globalApiUrl + "tallystaticreports/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const tallyCrudRepAxios = Axios.create({
  baseURL: globalApiUrl + "tallycrudapi/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const tallyStockRepAxios = Axios.create({
  baseURL: globalApiUrl + "tallystockapi/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

tallyCrudRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyStaticRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tallyStockRepAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const tallyOrgCompaniesAxios = Axios.create({
  baseURL: globalApiUrl + "tallyuserorgcompanies​​​​​​​/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

tallyOrgCompaniesAxios.interceptors.request.use(
  (req) => {
    if (cookie.load("TENANT")) {
      req.headers["Tenant"] = cookie.load("TENANT");
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const productionUrl = Axios.create({
  baseURL: globalApiUrl + "python/",
});

const tallyLedgersAxios = Axios.create({
  baseURL: globalApiUrl + "tallystaticreports/ledgerInfo/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const tallyCompaniesListAxios = Axios.create({
  baseURL: globalApiUrl + "",
});

const tallyGroupsAxios = Axios.create({
  baseURL: globalApiUrl + "tallystaticreports/",
  headers: {
    Tenant: cookie.load("TENANT"),
  },
});

const productionUrlForLogin = Axios.create({
  baseURL: globalApiUrl + "python/",
  // headers: {
  //   Tenant: cookie.load("TENANT"),
  // },
});

productionUrlForLogin.interceptors.request.use(
  (req) => {
    // if (cookie.load("TENANT")) {
    //   req.headers["Tenant"] = cookie.load("TENANT");
    // }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const gstAPIBase = globalApiUrl + "finsightsgst/api/";

const urlWithoutAxios = globalApiUrl + "finsightsgst/api/"

const Gstr2aAxios = Axios.create({
  baseURL: globalApiUrl + "finsightsgst/api/",
});
// const Gstr2aAxios = Axios.create({
//   baseURL: "https://b8f6-103-167-130-156.ngrok.io/api/",
// });

const Gstr2aPurchaseRegister = Axios.create({
  baseURL: globalApiUrl + "finsightstallygstapi/gst",
});
const Gstr2aStaticRepoAxios = Axios.create({
  baseURL: globalApiUrl + "tallyreportsapi/reports/",
});

export {
  productionUrlForLogin,
  tallyGroupsAxios,
  tallyStaticRepAxios,
  tallyCrudRepAxios,
  tallyOrgCompaniesAxios,
  tallyStockRepAxios,
  productionUrl,
  tallyLedgersAxios,
  tallyCompaniesListAxios,
  gstAPIBase,
  Gstr2aAxios,
  Gstr2aPurchaseRegister,
  Gstr2aStaticRepoAxios,
  urlWithoutAxios
};
