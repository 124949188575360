import {
    OTP_REQUEST_INIT,
    OTP_REQUEST_FINISH,
    OTP_REQUEST_SUCCESS,
    OTP_AUTH_SUCCESS,
    AUTH_TOKEN_INIT,
    AUTH_TOKEN_FINISH,
    AUTH_TOKEN_VERIFIED,
    GET_USER_DETAILS_INIT,
    GET_USER_DETAILS_FINISH,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    SET_VALID_USERNAME,
    SET_VALID_GSTIN,
    SET_OTP,
    SET_USERNAME,
    SET_GSTIN,
    DELETE_GSTIN_INIT,
    DELETE_GSTIN_FINISH,
    DELETE_GSTIN_STATUS,
    DELETE_GSTIN_MESSAGE,
    GET_GSTIN_DETAILS_INIT,
    GET_GSTIN_DETAILS_FINISH,
    GSTIN_DETAILS,
    RESET_AUTHENTICATION_DATA,
    OTP_REQUEST_MESSAGE,
    AUTH_REQUEST_MESSAGE,
    REFRESH_API_MODAL,
    CHECK_FIRST_TIME_AUTH,

    AUTO_DOWNLOAD_GSTR2A_INIT,
    AUTO_DOWNLOAD_GSTR2A_FINISH,
    AUTO_DOWNLOAD_GSTR2A_SUCCESS,
    AUTO_DOWNLOAD_GSTR2A_FAILED,
    GSTIN_CHANGE_UPDATE,

    GET_SUPPLIER_GSTIN_DETAILS_INIT,
    GET_SUPPLIER_GSTIN_DETAILS_FINISH,
    SUPPLIER_GSTIN_DETAILS,

    TALLY_COMPANIES_DATA,
    TALLY_COMPANIES_DATA_INIT,
    TALLY_COMPANIES_DATA_FINISH,
    LINK_TALLY_COMPANY_INIT,
    LINK_TALLY_COMPANY_FINISH,
    LINK_TALLY_COMPANY_DATA,
    LINK_COMPANY_MODAL,
    LINK_TALLY_COMPANY_MESSAGE,

    GET_USER_BY_GSTIN_INIT,
    GET_USER_BY_GSTIN_FINISH,
    GET_USER_BY_GSTIN_SUCCESS,
    GET_USER_BY_GSTIN_FAILED,
    UPDATE_GET_USER_BY_GSTIN_DATA,


    UPDATE_GSTIN_USER_INIT,
    UPDATE_GSTIN_USER_FINISH,
    UPDATE_GSTIN_USER_SUCCESS,
    UPDATE_GSTIN_USER_FAILED,


    DELETE_GSTIN_ROLE_INIT,
    DELETE_GSTIN_ROLE_FINISH,
    DELETE_GSTIN_ROLE_SUCCESS,
    DELETE_GSTIN_ROLE_FAILED,
    SET_NO_COMPANIES_FLAG,
    GET_CONNECTED_TALLY_COMPANIES,
    GET_CONNECTED_TALLY_COMPANIES_SUCCESS,

} from 'src/action-type/gst-authentication';

const initialState = {
    OtpStatus: null,
    OtpStatusMessage: '',
    otpData: null,
    otpRequestSuccess: false,
    authSuccess: false,
    authMessage: '',
    authTokenStatus: false,
    authTokenVerified: null,
    userDetailsReqStatus: false,
    userDetailsStatus: null,
    userDetailsData: [],
    userDetailsMessage: null,
    refreshAPIModal: false,
    gstinDetailsReqStatus: false,
    gstinDetails: {},
    deleteGSTINReqStatus: false,
    deleteGSTINStatus: null,
    deleteGSTINMessage: '',
    username: '',
    gstin: '',
    userOTP: '',
    validUsername: null,
    validGSTIN: null,
    gstinChange: null,
    firstTimeAuth: false,

    autoDownload2AReqStatus: false,
    autoDownload2AData: null,
    autoDownload2AStatus: null,
    autoDownload2AMessage: '',

    supplierGstinDetailsReqStatus: false,
    supplierGstinDetails: {},

    tallyCompaniesDataReqStatus: false,
    tallyCompaniesData: null,

    linkTallyCompanyReqStatus: false,
    linkTallyCompanyData: null,
    linkTallyCompanyMessage: '',

    linkCompanyVisible: false,


    usersByGstinReqStatus: false,
    usersByGstinData: [],
    usersByGstinStatus: false,
    usersByGstinResponse: '',



    updateUserReqStatus: false,
    updateUserData: {},
    updateUserStatus: false,
    updateUserResponse: '',



    deleteUserReqStatus: false,
    deleteUserData: {},
    deleteUserStatus: false,
    deleteUserResponse: '',

    refreshGstinSession: false,
    noCompaniesFlag: null,


    downloadProgress: {},
    downloadDataID: null,
    connectedTallyCompanies: null,

    currentGSTIN: null,

    downloadDataSuccess: false,


    isDownloadCompleted: null,
}

const GstAuthReducer = (state = initialState, action) => {
    switch (action.type) {

        case "IS_DOWNLOAD_COMPLETED": {
            return {...state, isDownloadCompleted: action.payload}
        }

        case "DOWNLOAD_DATA_SUCCESS": {
            return {...state, downloadDataSuccess: action.payload}
        }

        case "SET_CURRENT_GSTIN": {
            return {...state, currentGSTIN: action.payload}
        }

        case "DOWNLOAD_PROGRESS_SUCCESS": {
            return { ...state, downloadProgress: action.payload }
        }

        // case "REPORT_PROGRESS_MODAL_CLOSE": {
        //     return {...state, downloadProgress: {}}
        // }

        case "DOWNLOAD_DATA_ID": {
            return { ...state, downloadDataID: action.payload }
        }



        case "REFRESH_GSTIN_SESSION": {
            return { ...state, refreshGstinSession: action.payload }
        }


        //// STARTS... delete_gstin_role

        case DELETE_GSTIN_ROLE_INIT: {
            return { ...state, deleteUserReqStatus: true, deleteUserData: {}, deleteUserStatus: false, deleteUserResponse: '' }
        }

        case DELETE_GSTIN_ROLE_FINISH: {
            return { ...state, deleteUserReqStatus: false }
        }

        case DELETE_GSTIN_ROLE_SUCCESS: {
            return { ...state, deleteUserData: action.payload[0], deleteUserResponse: action.payload[1], deleteUserStatus: true }
        }

        case DELETE_GSTIN_ROLE_FAILED: {
            return { ...state, deleteUserStatus: false, deleteUserResponse: action.payload }
        }

        //// ENDS... update_gstin_user



        //// STARTS... update_gstin_user

        case UPDATE_GSTIN_USER_INIT: {
            return { ...state, updateUserReqStatus: true, updateUserData: {}, updateUserStatus: false, updateUserResponse: '' }
        }

        case UPDATE_GSTIN_USER_FINISH: {
            return { ...state, updateUserReqStatus: false }
        }

        case UPDATE_GSTIN_USER_SUCCESS: {
            return { ...state, updateUserData: action.payload[0], updateUserResponse: action.payload[1], updateUserStatus: true }
        }

        case UPDATE_GSTIN_USER_FAILED: {
            return { ...state, updateUserStatus: false, updateUserResponse: action.payload }
        }

        //// ENDS... update_gstin_user

        ///// STARTS... get_users_by_gstin

        case GET_USER_BY_GSTIN_INIT: {
            return { ...state, usersByGstinReqStatus: true, usersByGstinData: [], usersByGstinStatus: false, usersByGstinResponse: '' }
        }

        case GET_USER_BY_GSTIN_FINISH: {
            return { ...state, usersByGstinReqStatus: false }
        }

        case GET_USER_BY_GSTIN_SUCCESS: {
            return { ...state, usersByGstinStatus: true, usersByGstinData: action.payload }
        }

        case GET_USER_BY_GSTIN_FAILED: {
            return { ...state, usersByGstinStatus: false, usersByGstinResponse: action.payload }
        }

        case UPDATE_GET_USER_BY_GSTIN_DATA: {
            return { ...state, usersByGstinData: action.payload }
        }

        ///// ENDS... get_users_by_gstin

        case LINK_COMPANY_MODAL: {
            return { ...state, linkCompanyVisible: action.payload }
        }


        /// STARTS link_tally_company

        case LINK_TALLY_COMPANY_INIT: {
            return { ...state, linkTallyCompanyReqStatus: true, linkTallyCompanyData: null, linkTallyCompanyMessage: '' }
        }

        case LINK_TALLY_COMPANY_FINISH: {
            return { ...state, linkTallyCompanyReqStatus: false }
        }

        case LINK_TALLY_COMPANY_DATA: {
            return { ...state, linkTallyCompanyData: action.payload }
        }

        case LINK_TALLY_COMPANY_MESSAGE: {
            return { ...state, linkTallyCompanyMessage: action.payload }
        }



        /// ENDS link_tally_company



        // STARTS get_tally_companies_by_user
        case TALLY_COMPANIES_DATA_INIT: {
            return { ...state, tallyCompaniesDataReqStatus: true, tallyCompaniesData: null }
        }

        case TALLY_COMPANIES_DATA_FINISH: {
            return { ...state, tallyCompaniesDataReqStatus: false, }
        }

        case TALLY_COMPANIES_DATA: {
            return { ...state, tallyCompaniesData: action.payload }
        }

        // ENDS get_tally_companies_by_user

        //// STARTS >>> Auto Download Gstr2A

        case AUTO_DOWNLOAD_GSTR2A_INIT: {
            return { ...state, autoDownload2AReqStatus: true, autoDownload2AData: null, autoDownload2AStatus: null, autoDownload2AMessage: '' }
        };

        case AUTO_DOWNLOAD_GSTR2A_FINISH: {
            return { ...state, autoDownload2AReqStatus: false }
        };

        case AUTO_DOWNLOAD_GSTR2A_SUCCESS: {
            return { ...state, autoDownload2AData: action.payload[0], autoDownload2AMessage: action.payload[1] }
        };

        case AUTO_DOWNLOAD_GSTR2A_FAILED: {
            return { ...state, autoDownload2AMessage: action.payload }
        };

        case "UPDATE_AUTO_DOWNLOAD_GSTR2A_FAILED": {
            return { ...state, autoDownload2AMessage: action.payload }
        };

        //// ENDS >>> Auto Download Gstr2A



        case CHECK_FIRST_TIME_AUTH: {
            return { ...state, firstTimeAuth: action.payload }
        }

        case GSTIN_CHANGE_UPDATE: {
            return { ...state, gstinChange: action.payload }
        }

        case SET_VALID_USERNAME: {
            return { ...state, validUsername: action.payload }
        }

        case SET_VALID_GSTIN: {
            return { ...state, validGSTIN: action.payload }
        }

        case SET_OTP: {
            return { ...state, userOTP: action.payload }
        }

        case SET_USERNAME: {
            return { ...state, username: action.payload }
        }

        case SET_GSTIN: {
            return { ...state, gstin: action.payload }
        }

        case DELETE_GSTIN_INIT: {
            return { ...state, deleteGSTINReqStatus: true }
        }

        case DELETE_GSTIN_FINISH: {
            return { ...state, deleteGSTINReqStatus: false }
        }

        case DELETE_GSTIN_STATUS: {
            return { ...state, deleteGSTINStatus: action.payload }
        }

        case DELETE_GSTIN_MESSAGE: {
            return { ...state, deleteGSTINMessage: action.payload }
        }

        case GET_GSTIN_DETAILS_INIT: {
            return { ...state, gstinDetailsReqStatus: true }
        }

        case GET_GSTIN_DETAILS_FINISH: {
            return { ...state, gstinDetailsReqStatus: false }
        }

        case GSTIN_DETAILS: {
            return { ...state, gstinDetails: action.payload }
        }

        case RESET_AUTHENTICATION_DATA: {
            return {
                ...state,
                OtpStatus: null,
                OtpStatusMessage: '',
                otpData: null,
                otpRequestSuccess: false,
                username: '',
                gstin: '',
                userOTP: '',
                validUsername: null,
                validGSTIN: null,
            }
        }

        case OTP_REQUEST_INIT:
            return { ...state, OtpStatus: 'pending' }

        case OTP_REQUEST_MESSAGE:
            return { ...state, OtpStatusMessage: action.payload }

        case AUTH_REQUEST_MESSAGE: {
            return { ...state, authMessage: action.payload }
        }

        case REFRESH_API_MODAL: {
            return { ...state, refreshAPIModal: action.payload }
        }

        case OTP_REQUEST_FINISH:
            return { ...state, OtpStatus: action.payload }

        case OTP_REQUEST_SUCCESS:
            return { ...state, otpData: action.payload, otpRequestSuccess: true }

        case OTP_AUTH_SUCCESS:
            return { ...state, authSuccess: true }

        case AUTH_TOKEN_INIT:
            return { ...state, authTokenStatus: true }

        case AUTH_TOKEN_FINISH:
            return { ...state, authTokenStatus: false }

        case AUTH_TOKEN_VERIFIED:
            return { ...state, authTokenVerified: action.payload }

        //Authentications
        case GET_USER_DETAILS_INIT: {
            return { ...state, userDetailsReqStatus: false }
        }
        case GET_USER_DETAILS_FINISH: {
            return { ...state, userDetailsReqStatus: true }
        }
        case GET_USER_DETAILS_SUCCESS: {
            // return {...state, userDetailsData: action.payload}
            return { ...state, userDetailsStatus: action.payload[0], userDetailsData: action.payload[1], userDetailsMessage: action.payload[2] }
        }
        case SET_NO_COMPANIES_FLAG: {
            return { ...state, noCompaniesFlag: action.payload }
        }
        case GET_USER_DETAILS_FAILED: {
            return { ...state, budgetVsActualStatus: false, budgetVsActualMessage: action.payload }
        }

        //supplier details 

        case GET_SUPPLIER_GSTIN_DETAILS_INIT: {
            return { ...state, supplierGstinDetailsReqStatus: true }
        }

        case GET_SUPPLIER_GSTIN_DETAILS_FINISH: {
            return { ...state, supplierGstinDetailsReqStatus: false }
        }

        case SUPPLIER_GSTIN_DETAILS: {
            return { ...state, supplierGstinDetails: action.payload }
        }
        case GET_CONNECTED_TALLY_COMPANIES: {
            return { ...state, connectedTallyCompanies: null }
        }
        case GET_CONNECTED_TALLY_COMPANIES_SUCCESS: {
            return { ...state, connectedTallyCompanies: action.payload }
        }

        default:
            return state
    }
};

export default GstAuthReducer;