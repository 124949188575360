import {
    SAVE_USER,
    SAVE_USER_SUCCESS,
    SAVE_USER_FAILURE,
    SAVE_USER_DEFAULT_STATUS,
    USER_LOGIN,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    CLEAR_LOGIN_RESP_DATA,
    GET_ORGANISATION_BY_ORG_ID,
    GET_ORGANISATION_BY_ORG_ID_SUCCESS,
    GET_ORGANISATION_BY_ORG_ID_FAILURE,
    GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS,
    SAVE_ORGANISATION,
    SAVE_ORGANISATION_SUCCESS,
    SAVE_ORGANISATION_FAILURE,
    SAVE_ORGANISATION_DEFAULT_STATUS,
    GET_ORGANISATION_BY_ORG_OWNER_ID,
    GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
    UPDATE_ORGANISATION,
    UPDATE_ORGANISATION_SUCCESS,
    UPDATE_ORGANISATION_FAILURE,
    UPDATE_ORGANISATION_DEFAULT_STATUS,

    EMAIL_TOKEN_SUCCESS,
    EMAIL_TOKEN_FAILURE,
    CLEAR_LOGIN_TOKEN,

    REQ_RESET_EMAIL,
    REQ_RESET_EMAIL_SUCCESS,
    REQ_RESET_EMAIL_ERROR,

    CONFIRM_PASSWORD,
    CONFIRM_PASSWORD_SUCCESS,
    CONFIRM_PASSWORD_ERROR,

    USER_REGISTER,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_ERROR,

    USER_EMAIl_EXISTS,
    USER_EMAIl_EXISTS_SUCCESS,
    USER_EMAIl_EXISTS_ERROR,

    CLEAR_EMAIL_EXIST_RESP,

    GET_PROFILES,
    GET_PROFILES_SUCCESS,
    GET_PROFILES_ERROR,

    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_ERROR,

    ASSIGN_USERS,
    ASSIGN_USERS_SUCCESS,
    ASSIGN_USERS_ERROR,
    CLEAR_AFER_LOGIN,

    GET_COMPANY_PROFILE_INFO,
    GET_COMPANY_PROFILE_INFO_SUCCESS,
    GET_COMPANY_PROFILE_INFO_ERROR,

    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR,

    INIT_USER_PROFILE_DATA,
    INIT_USER_PROFILE_DATA_SUCCESS,
    INIT_USER_PROFILE_DATA_ERROR,

    UPDATE_USER_PROFILE_DATA,
    UPDATE_USER_PROFILE_DATA_SUCCESS,
    UPDATE_USER_PROFILE_DATA_ERROR,

    INVITED_PENDING_USERS,
    INVITED_PENDING_USERS_SUCCESS,
    INVITED_PENDING_USERS_ERROR,

    GET_INVITED_USERS,
    GET_INVITED_USERS_SUCCESS,
    GET_INVITED_USERS_ERROR,

    GET_MY_TALLY_COMPANIES,
    GET_MY_TALLY_COMPANIES_SUCCESS,
    GET_MY_TALLY_COMPANIES_ERROR,
    OTP_VERIFICATION,
    OTP_VERIFICATION_RESET,
    OTP_VERIFICATION_SUCCESS,
    SUBMIT_OTP,
    SUBMIT_OTP_RESET,
    SUBMIT_OTP_SUCCESS,
    USER_VERIFICATION_SUCCESS,
    RESEND_INVITATION_LINK_SUCCESS,
    RESEND_VERIFICATION_LINK_SUCCESS,
    VERIFY_RESET_PASSWORD_SUCCESS,
    SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
    RESET_SOCIAL_AUTH_DATA,
    SAVE_SOCIAL_AUTH_DATA_SUCCESS,
    REGISTER_INVITEE_SUCCESS,
    DELETE_ASSIGN_USERS_SUCCESS,
    DELETE_ASSIGN_USERS,


} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus, loginInprogressStatus } from "src/util/enums";

const initialState = {
    userRegistered: InsertFunctionStatus.NONE,
    users: [],
    // loginUserData:[],
    loginUserDataNew: {},
    verifyEmailResp: {},
    organaisationData: {},
    orgSaveStatus: InsertFunctionStatus.NONE,
    orgUpdateStatus: InsertFunctionStatus.NONE,
    organisationsList: [],
    reqResetEmailResp: {},
    confirmPasswordResp: {},
    userRegistrationResp: {},
    userEmailExistsResp: {},
    profileList: [],
    roleList: [],
    assignUserResp: {},
    companyProfileInfo: {},
    updatePasswordResp: {},
    initProfileResp: {},
    updateUserProfileResp: {},
    getInvitedUserData: {},
    getMyTallyCompanies: [],
    otpVerificationData: null,
    otpSubmitResponce: null,
    inviteeStatus: {},
    resendInvitationLinkData: null,
    resendVerificatinLinkResp: null,
    verifyRestPassword: null,
    submitResetPassResp: null,
    saveSocialAuthStatus: InsertFunctionStatus.NONE,
    responceAfterInvite: {},
    deleteUserResponce: null
};

export default function registration(state = initialState, action) {
    switch (action.type) {
        case SAVE_USER: {
            return { ...state, userRegistered: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_USER_SUCCESS: {
            return { ...state, userRegistered: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_USER_FAILURE: {
            return { ...state, userRegistered: InsertFunctionStatus.FAILED };
        }
        case SAVE_USER_DEFAULT_STATUS: {
            return { ...state, userRegistered: InsertFunctionStatus.NONE };

        } case USER_LOGIN_SUCCESS: {
            return { ...state, loginUserData: action.payload };
        }
        case USER_LOGIN_FAILURE: {
            return { ...state, loginUserData: action.payload };
        }
        case CLEAR_LOGIN_RESP_DATA: {
            return { ...state, loginUserData: initialState };
        }
        case EMAIL_TOKEN_SUCCESS: {
            return { ...state, verifyEmailResp: action.payload };
        } case EMAIL_TOKEN_FAILURE: {
            return { ...state, verifyEmailResp: action.payload };
        } case CLEAR_LOGIN_TOKEN: {
            return { ...state, verifyEmailResp: initialState };
        }





        case REQ_RESET_EMAIL_SUCCESS: {
            return { ...state, reqResetEmailResp: action.payload };
        }
        case REQ_RESET_EMAIL_ERROR: {
            return { ...state, reqResetEmailResp: action.payload };
        }



        case CONFIRM_PASSWORD_SUCCESS: {
            return { ...state, confirmPasswordResp: action.payload };
        }
        case CONFIRM_PASSWORD_ERROR: {
            return { ...state, confirmPasswordResp: action.payload };
        }
        case USER_REGISTER: {
            return { ...state, userRegistrationResp: {} };
        }
        case USER_REGISTER_SUCCESS: {
            return { ...state, userRegistrationResp: action.payload };
        }
        case USER_REGISTER_ERROR: {
            return { ...state, userRegistrationResp: action.payload };
        }

        case USER_EMAIl_EXISTS_SUCCESS: {
            return { ...state, userEmailExistsResp: action.payload };
        }
        case USER_EMAIl_EXISTS_ERROR: {
            return { ...state, userEmailExistsResp: action.payload };
        }

        case CLEAR_EMAIL_EXIST_RESP: {
            return { ...state, userEmailExistsResp: initialState };
        }

        case GET_PROFILES_SUCCESS: {
            return { ...state, profileList: action.payload };
        }
        case GET_PROFILES_ERROR: {
            return { ...state, profileList: action.payload };
        }

        case GET_ROLES_SUCCESS: {
            return { ...state, roleList: action.payload };
        }
        case GET_ROLES_ERROR: {
            return { ...state, roleList: action.payload };
        }

        case ASSIGN_USERS_SUCCESS: {
            return { ...state, assignUserResp: action.payload };
        }
        case ASSIGN_USERS_ERROR: {
            return { ...state, assignUserResp: action.payload };
        }
        case CLEAR_AFER_LOGIN: {
            return { ...state, loginUserData: action.payload };
        }
        case USER_LOGIN_FAILURE: {
            return { ...state, loginUserData: action.payload };
        }
        case GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS: {
            return { ...state, organaisationData: InsertFunctionStatus.NONE };
        }
        case GET_ORGANISATION_BY_ORG_ID: {
            return { ...state, organaisationData: loginInprogressStatus };
        }
        case GET_ORGANISATION_BY_ORG_ID_SUCCESS: {
            return { ...state, organaisationData: action.payload };
        }
        case SAVE_ORGANISATION: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_ORGANISATION_SUCCESS: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_ORGANISATION_DEFAULT_STATUS: {
            return { ...state, orgSaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_ORGANISATION_BY_ORG_OWNER_ID: {
            return { ...state, organisationsList: getList.inprogress };
        }
        case GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS: {
            return { ...state, organisationsList: action.payload };
        }
        case UPDATE_ORGANISATION: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.INPROGRESS };
        }
        case UPDATE_ORGANISATION_SUCCESS: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.SUCCESS };
        }
        case UPDATE_ORGANISATION_DEFAULT_STATUS: {
            return { ...state, orgUpdateStatus: InsertFunctionStatus.NONE };
        }
        case GET_COMPANY_PROFILE_INFO_SUCCESS: {
            return { ...state, companyProfileInfo: action.payload }
        }
        case GET_COMPANY_PROFILE_INFO_ERROR: {
            return { ...state, companyProfileInfo: action.payload }
        }
        case UPDATE_PASSWORD_SUCCESS: {
            return { ...state, updatePasswordResp: action.payload }
        }
        case UPDATE_PASSWORD_ERROR: {
            return { ...state, updatePasswordResp: action.payload }
        }
        case INIT_USER_PROFILE_DATA_SUCCESS: {
            return { ...state, initProfileResp: action.payload }
        }
        case INIT_USER_PROFILE_DATA_ERROR: {
            return { ...state, initProfileResp: action.payload }
        }
        case UPDATE_USER_PROFILE_DATA_SUCCESS: {
            return { ...state, updateUserProfileResp: action.payload }
        }
        case UPDATE_USER_PROFILE_DATA_ERROR: {
            return { ...state, updateUserProfileResp: action.payload }
        }
        case GET_INVITED_USERS_SUCCESS: {
            return { ...state, getInvitedUserData: action.payload }
        }
        case GET_INVITED_USERS_ERROR: {
            return { ...state, getInvitedUserData: action.payload }
        }
        case GET_MY_TALLY_COMPANIES_SUCCESS: {
            return { ...state, getMyTallyCompanies: action.payload }
        }
        case GET_MY_TALLY_COMPANIES_ERROR: {
            return { ...state, getMyTallyCompanies: action.payload }
        }
        case OTP_VERIFICATION: {
            return { ...state, otpVerificationData: null }
        }
        case OTP_VERIFICATION_RESET: {
            return { ...state, otpVerificationData: {} }
        }
        case OTP_VERIFICATION_SUCCESS: {
            return { ...state, otpVerificationData: action.payload }
        }
        case SUBMIT_OTP: {
            return { ...state, otpSubmitResponce: null }
        }
        case SUBMIT_OTP_RESET: {
            return { ...state, otpSubmitResponce: {} }
        }
        case SUBMIT_OTP_SUCCESS: {
            return { ...state, otpSubmitResponce: action.payload }
        }
        case USER_VERIFICATION_SUCCESS: {
            return { ...state, inviteeStatus: action.payload }
        }
        case RESEND_INVITATION_LINK_SUCCESS: {
            return { ...state, resendInvitationLinkData: action.payload };
        }
        case RESEND_VERIFICATION_LINK_SUCCESS: {
            return { ...state, resendVerificatinLinkResp: action.payload };
        }
        case VERIFY_RESET_PASSWORD_SUCCESS: {
            return { ...state, verifyRestPassword: action.payload };
        }
        case SUBMIT_RESET_PASSWORD_DATA_SUCCESS: {
            return { ...state, submitResetPassResp: action.payload };
        }
        case RESET_SOCIAL_AUTH_DATA: {
            return { ...state, saveSocialAuthStatus: action.payload };
        }
        case SAVE_SOCIAL_AUTH_DATA_SUCCESS: {
            return { ...state, saveSocialAuthStatus: action.payload };
        }
        case REGISTER_INVITEE_SUCCESS: {
            return { ...state, responceAfterInvite: action.payload }
        }
        case DELETE_ASSIGN_USERS_SUCCESS: {
            return { ...state, deleteUserResponce: action.payload };
        }
        case DELETE_ASSIGN_USERS: {
            return { ...state, deleteUserResponce: null };
        }
        default:
            return state;
    }
}

