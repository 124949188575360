import {FETCH_SUMMARY_INIT, FETCH_SUMMARY_FINISH, FETCH_SUMMARY_SUCCESS, FETCH_SUMMARY_FAILED, SET_SUMMARY_YEAR, SET_SUMMARY_MONTH} from 'src/action-type/Gstr3b';

const initialState = {
    fetchingSummary: false,
    fetchSummarySuccess: null, 
    summaryYear: null,
    summaryMonth: null,
    data: null
}

function SummaryReducer3b(state = initialState, action) {

    switch(action.type) {
        case  FETCH_SUMMARY_INIT:
            return {...state, fetchingSummary: true}

        case FETCH_SUMMARY_FINISH:
            return {...state, fetchingSummary: false}

        case FETCH_SUMMARY_SUCCESS:
            return {...state, data: action.payload, fetchSummarySuccess: true}

        case FETCH_SUMMARY_FAILED:
            return {...state, data: null, fetchSummarySuccess: false}

        case SET_SUMMARY_YEAR:
            return {...state, summaryYear: action.payload}

        case SET_SUMMARY_MONTH:
            return {...state, summaryMonth: action.payload}

        default:
            return state;
    }

};

export default SummaryReducer3b;