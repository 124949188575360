//gstr-1 summary

export const FETCH_SUMMARY_INIT = "FETCH_SUMMARY_INIT";
export const FETCH_SUMMARY_FINISH = "FETCH_SUMMARY_FINISH";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAILED = "FETCH_SUMMARY_FAILED";


export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const CLEAR_CATEGORY_TRANSACTION = "CLEAR_CATEGORY_TRANSACTION";
export const TRANSACTION_FETCH_START = "TRANSACTION_FETCH_START";
export const TRANSACTION_FETCH_END = "TRANSACTION_FETCH_END";

// download status

export const DOWNLOAD_STATUS_INIT = "DOWNLOAD_STATUS_INIT";
export const DOWNLOAD_STATUS_FINISH = "DOWNLOAD_STATUS_FINISH";
export const DOWNLOAD_STATUS_SUCCESS = "DOWNLOAD_STATUS_SUCCESS";

export const DOWNLOAD_SINGLE_INIT = "DOWNLOAD_SINGLE_INIT";
export const DOWNLOAD_SINGLE_FINISH = "DOWNLOAD_SINGLE_FINISH";
export const DOWNLOAD_SINGLE_SUCCESS = "DOWNLOAD_SINGLE_SUCCESS";

// month selector

export const FROM_MONTH = "FROM_MONTH";
export const TO_MONTH = "TO_MONTH";

// download period

export const SET_DOWNLOAD_PERIOD = "SET_DOWNLOAD_PERIOD";

// gstr-1 transactions

export const FETCH_TRANSACTIONS_INIT = "FETCH_TRANSACTIONS_INIT";
export const FETCH_TRANSACTIONS_FINISH = "FETCH_TRANSACTIONS_FINISH";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_FAILED = "FETCH_TRANSACTIONS_FAILED";