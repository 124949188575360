import {
  RECONCILE_REPORT_INIT,
  RECONCILE_REPORT_FINISH,
  RECONCILE_REPORT_SUCCESS,
  RECONCILE_REPORT_FAILED,
  RECONCILE_REPORT_VIEW_MODAL,
  RECONCILE_DOWNLOAD_INIT,
  RECONCILE_DOWNLOAD_FINISH,
  RECONCILE_DOWNLOAD_MASTER_DATA,
  RECONCILE_DOWNLOAD_MASTER_MESSAGE,

  RECONCILE_REPORT_2B_INIT,
  RECONCILE_REPORT_2B_FINISH,
  RECONCILE_REPORT_2B_SUCCESS,
  RECONCILE_REPORT_2B_FAILED,


  RECONCILE_2B_DOWNLOAD_INIT,
  RECONCILE_2B_DOWNLOAD_FINISH,
  RECONCILE_2B_DOWNLOAD_MASTER_DATA,
  RECONCILE_2B_DOWNLOAD_FAILED,

  RECONCILE_DOWNLOAD_FROM_MASTER_INIT,
  RECONCILE_DOWNLOAD_FROM_MASTER_FINISH,
  RECONCILE_DOWNLOAD_FROM_MASTER_DATA,



  RECONCILE_CHECK_DOWNLOAD_INIT,
  RECONCILE_CHECK_DOWNLOAD_FINISH,
  RECONCILE_CHECK_DOWNLOAD_SUCCESS,
  RECONCILE_CHECK_DOWNLOAD_FAILED,


  //gst2aReconciliation Report
  GSTR2A_RECONCILIATON_REPORT_INIT,
  GSTR2A_RECONCILIATON_REPORT_FINISH,
  GSTR2A_RECONCILIATON_REPORT_SUCCESS,
  GSTR2A_RECONCILIATON_REPORT_FAILED,


  //Reconciliation Report Modal Details
  GSTR2A_RECONCILIATON_REPORT_DETAILS_INIT,
  GSTR2A_RECONCILIATON_REPORT_DETAILS_FINISH,
  GSTR2A_RECONCILIATON_REPORT_DETAILS_SUCCESS,
  GSTR2A_RECONCILIATON_REPORT_DETAILS_FAILED,
  GSTR2A_RECONCILIATON_REPORT_DETAILS_ID,
  GSTR2B_RECONCILIATON_REPORT_DETAILS_ID,


  //single mail

  GSTR2A_SINGLE_MAIL_INIT,
  GSTR2A_SINGLE_MAIL_FINISH,
  GSTR2A_SINGLE_MAIL_SUCCESS,
  GSTR2A_SINGLE_MAIL_FAILED,
  GSTR2A_SINGLE_MAIL_RESET,

  //bulk mail

  GSTR2A_BULK_MAIL_INIT,
  GSTR2A_BULK_MAIL_FINISH,
  GSTR2A_BULK_MAIL_SUCCESS,
  GSTR2A_BULK_MAIL_FAILED,
  GSTR2A_BULK_MAIL_RESET,

  GSTIN_CONTACT_MAIL_INIT,
  GSTIN_CONTACT_MAIL_FINISH,
  GSTIN_CONTACT_MAIL_SUCCESS,
  GSTIN_CONTACT_MAIL_FAILED,
  GSTIN_CONTACT_MAIL_RESET,

  GSTR2B_RECONCILIATON_REPORT_DETAILS_INIT,
  GSTR2B_RECONCILIATON_REPORT_DETAILS_FINISH,
  GSTR2B_RECONCILIATON_REPORT_DETAILS_SUCCESS,
  GSTR2B_RECONCILIATON_REPORT_DETAILS_FAILED,

  GSTR2B_RECONCILIATON_REPORT_INIT,
  GSTR2B_RECONCILIATON_REPORT_FINISH,
  GSTR2B_RECONCILIATON_REPORT_SUCCESS,
  GSTR2B_RECONCILIATON_REPORT_FAILED,
  RESET_RECONCILE_DOWNLOAD_DATA,
  RECONCILE_NOW_REPORT_SUCCESS,
  RECONCILE_NOW_REPORT_2B_SUCCESS,
  RESET_CHECK_DOWNLOAD_DATA,
  UPDATE_RECONCILE_REPORT_DATA,
  VIEW_RECON_REPORT,
  VIEW_RECON_REPORT_ID,
  VIEW_RECON_REPORT_TYPE

} from 'src/actions/actionTypes';


const inititalState = {
  reconcileDownloadReqStatus: false,
  reconcileDownloadData: null,
  reconcileDownloadMsg: '',


  reconcile2BDownloadReqStatus: false,
  reconcile2BDownloadData: null,
  reconcile2BDownloadMsg: '',

  reconcileReportView: false,

  reconcileNowRepoData: null,
  reconcileNowRepo2BData: null,

  reconcileReportReqStatus: false,
  reconcileReportStatus: null,
  reconcileReportData: {},
  reconcileReportMessage: null,

  reconcileReport2BReqStatus: false,
  reconcileReport2BStatus: null,
  reconcileReport2BData: {},
  reconcileReport2BMessage: null,

  reconcileDownloadFromMasterReq: false,
  reconcileDownloadFromMasterData: null,

  reconcileCheckDownloadReqStatus: false,
  reconcileCheckDownloadStatus: null,
  reconcileCheckDownloadData: [],
  reconcileCheckDownloadMessage: null,


  gstr2aReconciliationReportReqStatus: false,
  gstr2aReconciliationReportData: [],
  gstr2aReconciliationReportMessage: '',



  gstr2bReconciliationReportReqStatus: false,
  gstr2bReconciliationReportData: [],
  gstr2bReconciliationReportMessage: '',


  gstr2aReconciliationReportDetailsData: {},
  gstr2aReconciliationReportDetailsStatus: null,
  gstr2aReconciliationReportDetailsReqStatus: false,
  gstr2aReconciliationReportDetailsMessage: '',
  gstr2aReconciliationReportDetailsId: null,

  gstr2bReconciliationReportDetailsData: {},
  gstr2bReconciliationReportDetailsStatus: null,
  gstr2bReconciliationReportDetailsReqStatus: false,
  gstr2bReconciliationReportDetailsMessage: '',
  gstr2bReconciliationReportDetailsId: null,
  //single mail
  singleMailReqStatus: false,
  singleMailStatus: null,
  singleMailData: {},
  singleMailMessage: null,


  reconcileGstType: '',


  viewReconReport: false,
  viewReconReportID: null,
  viewReconReportType: '',

  gstinContactList:[],

}



export default function reconcileReducer(state = inititalState, action) {

  switch (action.type) {

    /// view Reconciled Report

    case VIEW_RECON_REPORT_TYPE: {
      return { ...state, viewReconReportType: action.payload }
    }

    case VIEW_RECON_REPORT_ID: {
      return { ...state, viewReconReportID: action.payload }
    }

    case VIEW_RECON_REPORT: {
      return { ...state, viewReconReport: action.payload }
    }

    //// reconcile 2A download starts

    case RECONCILE_DOWNLOAD_INIT: {
      return { ...state, reconcileDownloadReqStatus: true, reconcileDownloadData: null }
    }

    case RECONCILE_DOWNLOAD_FINISH: {
      return { ...state, reconcileDownloadReqStatus: false }
    }

    case RECONCILE_DOWNLOAD_MASTER_DATA: {
      return { ...state, reconcileDownloadData: action.payload }
    }

    case RECONCILE_DOWNLOAD_MASTER_MESSAGE: {
      return { ...state, reconcileDownloadMsg: action.payload }
    }


    //// reconcile 2A download ends...



    //// reconcile 2B download starts

    case RECONCILE_2B_DOWNLOAD_INIT: {
      return { ...state, reconcile2BDownloadReqStatus: true }
    }

    case RECONCILE_2B_DOWNLOAD_FINISH: {
      return { ...state, reconcile2BDownloadReqStatus: false }
    }

    case RECONCILE_2B_DOWNLOAD_MASTER_DATA: {
      return { ...state, reconcile2BDownloadData: action.payload }
    }


    case "RECONCILE_2B_DOWNLOAD_MASTER_MESSAGE": {
      return { ...state, reconcile2BDownloadMsg: action.payload }
    }

    //// reconcile 2B download ends...



    case RECONCILE_REPORT_VIEW_MODAL: {
      return { ...state, reconcileReportView: action.payload }
    }

    case RECONCILE_NOW_REPORT_SUCCESS: {
      return { ...state, reconcileNowRepoData: action.payload }
    }


    case RECONCILE_NOW_REPORT_2B_SUCCESS: {
      return { ...state, reconcileNowRepo2BData: action.payload }
    }




    //// reconcile report 2A starts

    case RECONCILE_REPORT_INIT: {
      return { ...state, reconcileReportReqStatus: true }
    }

    case RECONCILE_REPORT_FINISH: {
      return { ...state, reconcileReportReqStatus: false }
    }

    case RECONCILE_REPORT_SUCCESS: {
      return { ...state, reconcileReportStatus: action.payload[0], reconcileReportData: action.payload[1], reconcileReportMessage: action.payload[2] };
    }

    case RECONCILE_REPORT_FAILED: {
      return { ...state, reconcileReportStatus: action.payload[0], reconcileReportMessage: action.payload[1] };
    }

    //// reconcile report 2A ends



    //// reconcile report 2B starts

    case RECONCILE_REPORT_2B_INIT: {
      return { ...state, reconcileReport2BReqStatus: true }
    }

    case RECONCILE_REPORT_2B_FINISH: {
      return { ...state, reconcileReport2BReqStatus: false }
    }

    case RECONCILE_REPORT_2B_SUCCESS: {
      return { ...state, reconcileReport2BStatus: action.payload[0], reconcileReport2BData: action.payload[1], reconcileReport2BMessage: action.payload[2] };
    }

    case RECONCILE_REPORT_2B_FAILED: {
      return { ...state, reconcileReport2BStatus: action.payload[0], reconcileReport2BMessage: action.payload[1] };
    }

    //// reconcile report 2B ends


    case RECONCILE_DOWNLOAD_FROM_MASTER_INIT: {
      return { ...state, reconcileDownloadFromMasterReq: true }
    }

    case RECONCILE_DOWNLOAD_FROM_MASTER_FINISH: {
      return { ...state, reconcileDownloadFromMasterReq: false }
    }

    case RECONCILE_DOWNLOAD_FROM_MASTER_DATA: {
      return { ...state, reconcileDownloadFromMasterData: action.payload }
    }



    case RESET_RECONCILE_DOWNLOAD_DATA: {
      return { ...state, reconcileDownloadFromMasterData: null }
    }



    case RECONCILE_CHECK_DOWNLOAD_INIT: {
      return { ...state, reconcileCheckDownloadReqStatus: true };
    }
    case RECONCILE_CHECK_DOWNLOAD_FINISH: {
      return { ...state, reconcileCheckDownloadReqStatus: false };
    }

    case RECONCILE_CHECK_DOWNLOAD_SUCCESS: {
      return { ...state, reconcileCheckDownloadStatus: action.payload[0], reconcileCheckDownloadData: action.payload[1], reconcileCheckDownloadMessage: action.payload[2] };
    }

    case RECONCILE_CHECK_DOWNLOAD_FAILED: {
      return { ...state, reconcileCheckDownloadStatus: action.payload[0], reconcileCheckDownloadMessage: action.payload[1] };
    }


    case RESET_CHECK_DOWNLOAD_DATA: {
      return { ...state, reconcileCheckDownloadData: [] }
    }

    //gst2aReconciliation Report
    case GSTR2A_RECONCILIATON_REPORT_INIT: {
      return { ...state, gstr2aReconciliationReportReqStatus: true };
    }
    case GSTR2A_RECONCILIATON_REPORT_FINISH: {
      return { ...state, gstr2aReconciliationReportReqStatus: false };
    }

    case GSTR2A_RECONCILIATON_REPORT_SUCCESS: {
      return {
        ...state,
        gstr2aReconciliationReportStatus: action.payload[0],
        gstr2aReconciliationReportData: action.payload[1],
        gstr2aReconciliationReportMessage: action.payload[2],
      };
    }

    case GSTR2A_RECONCILIATON_REPORT_FAILED: {
      return {
        ...state,
        gstr2aReconciliationReportStatus: action.payload[0],
        gstr2aReconciliationReportMessage: action.payload[1],
      };
    }

    /// finish here...


    //gst2bReconciliation Report

    case GSTR2B_RECONCILIATON_REPORT_INIT: {
      return { ...state, gstr2bReconciliationReportReqStatus: true };
    }
    case GSTR2B_RECONCILIATON_REPORT_FINISH: {
      return { ...state, gstr2bReconciliationReportReqStatus: false };
    }

    case GSTR2B_RECONCILIATON_REPORT_SUCCESS: {
      return {
        ...state,
        gstr2bReconciliationReportStatus: action.payload[0],
        gstr2bReconciliationReportData: action.payload[1],
        gstr2bReconciliationReportMessage: action.payload[2],
      };
    }

    case GSTR2B_RECONCILIATON_REPORT_FAILED: {
      return {
        ...state,
        gstr2baReconciliationReportStatus: action.payload[0],
        gstr2bReconciliationReportMessage: action.payload[1],
      };
    }

    case GSTR2B_RECONCILIATON_REPORT_DETAILS_ID: {
      return {
        ...state, gstr2bReconciliationReportDetailsId: action.payload,
      }
    }

    /// finish here....


    // >>> Reconciliation Report Modal Details starts

    case GSTR2A_RECONCILIATON_REPORT_DETAILS_ID: {
      return {
        ...state, gstr2aReconciliationReportDetailsId: action.payload,
      }
    }

    case GSTR2A_RECONCILIATON_REPORT_DETAILS_INIT: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsReqStatus: true,
        gstr2aReconciliationReportDetailsData: {},
        gstr2aReconciliationReportDetailsStatus: null,
        gstr2aReconciliationReportDetailsId: action.payload,
        reconcileGstType: '2a'
      };
    }
    case GSTR2A_RECONCILIATON_REPORT_DETAILS_FINISH: {
      return { ...state, gstr2aReconciliationReportDetailsReqStatus: false };
    }

    case GSTR2A_RECONCILIATON_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsStatus: action.payload[0],
        gstr2aReconciliationReportDetailsData: action.payload[1],
        gstr2aReconciliationReportDetailsMessage: action.payload[2],
      };
    }

    case GSTR2A_RECONCILIATON_REPORT_DETAILS_FAILED: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsStatus: action.payload[0],
        gstr2aReconciliationReportDetailsMessage: action.payload[1],
      };
    }

    //Reconciliation Report Modal Details ends... <<<<<



    // >>> Reconciliation 2B Report Modal Details starts

    case GSTR2B_RECONCILIATON_REPORT_DETAILS_INIT: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsReqStatus: true,
        gstr2aReconciliationReportDetailsData: {},
        gstr2aReconciliationReportDetailsStatus: null,
        gstr2bReconciliationReportDetailsId: action.payload,
        reconcileGstType: '2b'
      };
    }
    case GSTR2B_RECONCILIATON_REPORT_DETAILS_FINISH: {
      return { ...state, gstr2aReconciliationReportDetailsReqStatus: false };
    }

    case GSTR2B_RECONCILIATON_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsStatus: action.payload[0],
        gstr2aReconciliationReportDetailsData: action.payload[1],
        gstr2aReconciliationReportDetailsMessage: action.payload[2],
      };
    }

    case GSTR2B_RECONCILIATON_REPORT_DETAILS_FAILED: {
      return {
        ...state,
        gstr2aReconciliationReportDetailsStatus: action.payload[0],
        gstr2aReconciliationReportDetailsMessage: action.payload[1],
      };
    }

    //Reconciliation 2B Report Modal Details ends... <<<<<

    case UPDATE_RECONCILE_REPORT_DATA: {

      return { ...state, gstr2aReconciliationReportDetailsData: action.payload }

    }

    //single mail

    case GSTR2A_SINGLE_MAIL_INIT: {
      return { ...state, singleMailReqStatus: true }
    }

    case GSTR2A_SINGLE_MAIL_FINISH: {
      return { ...state, singleMailReqStatus: false }
    }

    case GSTR2A_SINGLE_MAIL_SUCCESS: {
      return { ...state, singleMailMessage: action.payload };
    }

    case GSTR2A_SINGLE_MAIL_FAILED: {
      return { ...state, singleMailMessage: action.payload };
    }

    case GSTR2A_SINGLE_MAIL_RESET: {
      return { ...state, singleMailMessage: null };
    }

    //bulk mail

    case GSTR2A_BULK_MAIL_INIT: {
      return { ...state, bulkMailReqStatus: true }
    }

    case GSTR2A_BULK_MAIL_FINISH: {
      return { ...state, bulkMailReqStatus: false }
    }

    case GSTR2A_BULK_MAIL_SUCCESS: {
      return { ...state, bulkMailMessage: action.payload };
    }

    case GSTR2A_BULK_MAIL_FAILED: {
      return { ...state, bulkMailMessage: action.payload };
    }

    case GSTR2A_BULK_MAIL_RESET: {
      return { ...state, bulkMailMessage: null };
    }

    //contact gstin mail

    case GSTIN_CONTACT_MAIL_INIT: {
      return { ...state, gstinContactMailReqStatus: true }
    }

    case GSTIN_CONTACT_MAIL_FINISH: {
      return { ...state, gstinContactMailReqStatus: false }
    }

    case GSTIN_CONTACT_MAIL_SUCCESS: {
      return { ...state, gstinContactList: action.payload };

    }

    case GSTIN_CONTACT_MAIL_FAILED: {
      return { ...state, gstinContactMailMessage: action.payload };
    }

    default:
      return state;


  }

}