import {
  GSTR2A_SUMMARY_INVOICE_FAILED,
  GSTR2A_SUMMARY_INVOICE_FINISH,
  GSTR2A_SUMMARY_INVOICE_INIT,
  GSTR2A_SUMMARY_INVOICE_SUCCESS,
  GSTR2A_UPDATE_SUMMARY_INVOICE,
  GSTR2A_SUMMARY_CDN_INIT,
  GSTR2A_SUMMARY_CDN_FINISH,
  GSTR2A_SUMMARY_CDN_SUCCESS,
  GSTR2A_SUMMARY_CDN_FAILED,
  GSTR2A_SUMMARY_TDS_INIT,
  GSTR2A_SUMMARY_TDS_FINISH,
  GSTR2A_SUMMARY_TDS_SUCCESS,
  GSTR2A_SUMMARY_TDS_FAILED,
  GSTR2A_SUMMARY_IMPG_INIT,
  GSTR2A_SUMMARY_IMPG_FINISH,
  GSTR2A_SUMMARY_IMPG_SUCCESS,
  GSTR2A_SUMMARY_IMPG_FAILED,
  GSTR_YEARS_LIST,
  GSTR2A_DOWNLOAD_STATUS_INIT,
  GSTR2A_DOWNLOAD_STATUS_FINISH,
  GSTR2A_DOWNLOAD_STATUS_SUCCESS,
  GSTR2A_DOWNLOAD_STATUS_FAILED,

  GSTR2A_SUMMARY_TCS_INIT,
  GSTR2A_SUMMARY_TCS_FINISH,
  GSTR2A_SUMMARY_TCS_SUCCESS,
  GSTR2A_SUMMARY_TCS_FAILED,

  //gstr2a download status download button
  GSTR2A_DOWNLOAD_PERIOD,


  GSTR2A_INVOICE_DOWNLOAD_INIT,
  GSTR2A_INVOICE_DOWNLOAD_FINISH,
  GSTR2A_CDN_DOWNLOAD_INIT,
  GSTR2A_CDN_DOWNLOAD_FINISH,

  GSTR2A_TCS_DOWNLOAD_INIT,
  GSTR2A_TCS_DOWNLOAD_FINISH,

  GSTR2A_TDS_DOWNLOAD_INIT,
  GSTR2A_TDS_DOWNLOAD_FINISH,
  GSTR2A_IMPG_DOWNLOAD_INIT,
  GSTR2A_IMPG_DOWNLOAD_FINISH,
  //Finicial year list
  GSTR_SELECTED_FINANCIAL_YEAR,
  UPDATE_GSTR_2A_DOWNLOAD_STATUS_DATA,

  SET_INVOICE_TOTAL_VALUE,
  SET_INVOICE_TOTAL_TAXABLE_VALUE,
  SET_INVOICE_TOTAL_IGST,
  SET_INVOICE_TOTAL_SGST,
  SET_INVOICE_TOTAL_CGST,
  SET_INVOICE_TOTAL_CESS,
  SET_INVOICE_TOTAL_NUM,


  SET_CDN_TOTAL_VALUE,
  SET_CDN_TOTAL_TAXABLE_VALUE,
  SET_CDN_TOTAL_IGST,
  SET_CDN_TOTAL_SGST,
  SET_CDN_TOTAL_CGST,
  SET_CDN_TOTAL_CESS,
  SET_CDN_TOTAL_NUM,

  SET_TDS_TOTAL_VALUE,
  SET_TDS_TOTAL_IGST,
  SET_TDS_TOTAL_SGST,
  SET_TDS_TOTAL_CGST,
  SET_TDS_TOTAL_NUM,

  SET_IMPG_TOTAL_TAXABLE_VALUE,
  SET_IMPG_TOTAL_IGST,
  SET_IMPG_TOTAL_CESS,
  SET_IMPG_TOTAL_NUM,


  DOWNLOAD_FROM_MASTER_INIT,
  DOWNLOAD_FROM_MASTER_FINISH,
  DOWNLOAD_FROM_MASTER_DATA,

  GSTR2A_SINGLE_DOWNLOAD_SUCCESS,
  GSTR2A_SINGLE_DOWNLOAD_MSG,
  GSTIN_SESSION_STATUS,

  GSTR_YEARS_LIST_INIT,
  GSTR_YEARS_LIST_FINISH,
  GSTR2A_SUMMARY_INVOICE_DATA_UPDATE,

  GSTR2A_INELIGIBLE_CREDIT_FAILED,
  GSTR2A_INELIGIBLE_CREDIT_FINISH,
  GSTR2A_INELIGIBLE_CREDIT_INIT,
  GSTR2A_INELIGIBLE_CREDIT_SUCCESS,

  GSTR2A_INELIGIBLE_DATA_FAILED,
  GSTR2A_INELIGIBLE_DATA_FINISH,
  GSTR2A_INELIGIBLE_DATA_INIT,
  GSTR2A_INELIGIBLE_DATA_SUCCESS,
  GSTR2A_INELIGIBLE_DATA_UPDATE,
  GSTR2A_SUMMARY_TCS_UPDATE

} from "../actions/actionTypes";

const inititalState = {
  gstr2aInvoiceReqStatus: false,
  gstr2aInvoiceStatus: null,
  gstr2aInvoiceData: null,
  gstr2aInvoiceMessage: null,

  gstr2aCDNReqStatus: false,
  gstr2aCDNStatus: null,
  gstr2aCDNData: null,
  gstr2aCDNMessage: null,

  gstr2aTDSReqStatus: false,
  gstr2aTDSStatus: null,
  gstr2aTDSData: null,
  gstr2aTDSMessage: null,

  gstr2aIMPGReqStatus: false,
  gstr2aIMPGStatus: null,
  gstr2aIMPGData: null,
  gstr2aIMPGMessage: null,
  yearsList: { year_list: [], current_year: "" },
  downloadPeriod: [],
  financialYear: [],

  gstr2aInvoiceDownloadStatus: false,
  gstr2aCDNDownloadStatus: false,
  gstr2aTDSDownloadStatus: false,
  gstr2aIMPGDownloadStatus: false,
  gstr2aTCSDownloadStatus:false,

  gstr2aDownloadData: [],

  //gstr2aTCS
  gstr2aTCSReqStatus: false,
  gstr2aTCSStatus: null,
  gstr2aTCSData: null,
  gstr2aTCSMessage: null,


  invoiceTotalValue: null,
  invoiceTotalTaxable: null,
  invoiceTotalIGST: null,
  invoiceTotalSGST: null,
  invoiceTotalCGST: null,
  invoiceTotalCESS: null,
  invoiceTotalNum: null,


  cdnTotalValue: null,
  cdnTotalTaxable: null,
  cdnTotalIGST: null,
  cdnTotalSGST: null,
  cdnTotalCGST: null,
  cdnTotalCESS: null,
  cdnTotalNum: null,


  tdsTotalValue: null,
  tdsTotalIGST: null,
  tdsTotalSGST: null,
  tdsTotalCGST: null,
  tdsTotalNum: null,

  impgTotalTaxable: null,
  impgTotalIGST: null,
  impgTotalCESS: null,
  impgTotalNum: null,

  downloadFromMasterReq: false,
  downloadFromMasterData: null,


  GstrYearListReqStatus: false,

  gstr2aSingleDownloadData: null,
  gstr2aSingleDownloadMsg: '',
  gstinSessionStatus: null,

  // Ineligible Credit

  gstr2aIneligibleCreditReqStatus: false,
  gstr2aIneligibleCreditStatus: null,
  gstr2aIneligibleCreditData: null,
  gstr2aIneligibleCreditMessage: null,

  gstr2aIneligibleDataReqStatus: false,
  gstr2aIneligibleDataStatus: null,
  gstr2aIneligibleData: null,
  gstr2aIneligibleDataMessage: null,
};



export default function gstr2aReducer(state = inititalState, action) {
  switch (action.type) {


    case GSTR_YEARS_LIST_INIT: {
      return { ...state, GstrYearListReqStatus: true }
    }

    case GSTR_YEARS_LIST_FINISH: {
      return { ...state, GstrYearListReqStatus: false }
    }

    case DOWNLOAD_FROM_MASTER_INIT: {
      return { ...state, downloadFromMasterReq: true }
    }

    case DOWNLOAD_FROM_MASTER_FINISH: {
      return { ...state, downloadFromMasterReq: false }
    }

    case DOWNLOAD_FROM_MASTER_DATA: {
      return { ...state, downloadFromMasterData: action.payload }
    }





    case SET_IMPG_TOTAL_TAXABLE_VALUE: {
      return { ...state, impgTotalTaxable: action.payload }
    }

    case SET_IMPG_TOTAL_IGST: {
      return { ...state, impgTotalIGST: action.payload }
    }

    case SET_IMPG_TOTAL_CESS: {
      return { ...state, impgTotalCESS: action.payload }
    }

    case SET_IMPG_TOTAL_NUM: {
      return { ...state, impgTotalNum: action.payload }
    }







    case SET_TDS_TOTAL_VALUE: {
      return { ...state, tdsTotalValue: action.payload }
    }

    case SET_TDS_TOTAL_IGST: {
      return { ...state, tdsTotalIGST: action.payload }
    }

    case SET_TDS_TOTAL_SGST: {
      return { ...state, tdsTotalSGST: action.payload }
    }

    case SET_TDS_TOTAL_CGST: {
      return { ...state, tdsTotalCGST: action.payload }
    }


    case SET_TDS_TOTAL_NUM: {
      return { ...state, tdsTotalNum: action.payload }
    }


    case SET_CDN_TOTAL_VALUE: {
      return { ...state, cdnTotalValue: action.payload }
    }

    case SET_CDN_TOTAL_TAXABLE_VALUE: {
      return { ...state, cdnTotalTaxable: action.payload }
    }

    case SET_CDN_TOTAL_IGST: {
      return { ...state, cdnTotalIGST: action.payload }
    }

    case SET_CDN_TOTAL_SGST: {
      return { ...state, cdnTotalSGST: action.payload }
    }

    case SET_CDN_TOTAL_CGST: {
      return { ...state, cdnTotalCGST: action.payload }
    }

    case SET_CDN_TOTAL_CESS: {
      return { ...state, cdnTotalCESS: action.payload }
    }

    case SET_CDN_TOTAL_NUM: {
      return { ...state, cdnTotalNum: action.payload }
    }


    case SET_INVOICE_TOTAL_VALUE: {
      return { ...state, invoiceTotalValue: action.payload }
    }

    case SET_INVOICE_TOTAL_TAXABLE_VALUE: {
      return { ...state, invoiceTotalTaxable: action.payload }
    }

    case SET_INVOICE_TOTAL_IGST: {
      return { ...state, invoiceTotalIGST: action.payload }
    }

    case SET_INVOICE_TOTAL_SGST: {
      return { ...state, invoiceTotalSGST: action.payload }
    }

    case SET_INVOICE_TOTAL_CGST: {
      return { ...state, invoiceTotalCGST: action.payload }
    }

    case SET_INVOICE_TOTAL_CESS: {
      return { ...state, invoiceTotalCESS: action.payload }
    }

    case SET_INVOICE_TOTAL_NUM: {
      return { ...state, invoiceTotalNum: action.payload }
    }

    case GSTR2A_IMPG_DOWNLOAD_INIT: {
      return { ...state, gstr2aIMPGDownloadStatus: true }
    }
    case GSTR2A_IMPG_DOWNLOAD_FINISH: {
      return { ...state, gstr2aIMPGDownloadStatus: false }
    }


    case GSTR2A_TDS_DOWNLOAD_INIT: {
      return { ...state, gstr2aTDSDownloadStatus: true }
    }
    case GSTR2A_TDS_DOWNLOAD_FINISH: {
      return { ...state, gstr2aTDSDownloadStatus: false }
    }

    case GSTR2A_CDN_DOWNLOAD_INIT: {
      return { ...state, gstr2aCDNDownloadStatus: true }
    }
    case GSTR2A_CDN_DOWNLOAD_FINISH: {
      return { ...state, gstr2aCDNDownloadStatus: false }
    }

    case GSTR2A_TCS_DOWNLOAD_INIT: {
      return { ...state, gstr2aTCSDownloadStatus: true }
    }
    case GSTR2A_TCS_DOWNLOAD_FINISH: {
      return { ...state, gstr2aTCSDownloadStatus: false }
    }


    case GSTR2A_INVOICE_DOWNLOAD_INIT: {
      return { ...state, gstr2aInvoiceDownloadStatus: true }
    }
    case GSTR2A_INVOICE_DOWNLOAD_FINISH: {
      return { ...state, gstr2aInvoiceDownloadStatus: false }
    }

    case GSTR2A_SUMMARY_INVOICE_INIT: {
      return {
        ...state,
        gstr2aInvoiceReqStatus: true,
        gstr2aInvoiceStatus: null,
        gstr2aInvoiceData: null,
        gstr2aInvoiceMessage: null,
      };
    }

    case GSTR2A_SUMMARY_INVOICE_FINISH: {
      return { ...state, gstr2aInvoiceReqStatus: false };
    }

    case GSTR2A_SUMMARY_INVOICE_SUCCESS: {
      return {
        ...state,
        gstr2aInvoiceStatus: action.payload[0],
        gstr2aInvoiceData: action.payload[1],
        gstr2aInvoiceMessage: action.payload[2],
      };
    }

    case GSTR2A_UPDATE_SUMMARY_INVOICE: return {
      ...state, gstr2aInvoiceData: action.payload,
    }

    case GSTR2A_SUMMARY_INVOICE_DATA_UPDATE: {
      return { ...state, gstr2aInvoiceData: action.payload }
    }

    case GSTR2A_SUMMARY_INVOICE_FAILED: {
      return {
        ...state,
        gstr2aInvoiceStatus: action.payload[0],
        gstr2aInvoiceMessage: action.payload[1],
      };
    }

    case GSTR2A_SUMMARY_CDN_INIT: {
      return { ...state, gstr2aCDNReqStatus: true };
    }
    case GSTR2A_SUMMARY_CDN_FINISH: {
      return { ...state, gstr2aCDNReqStatus: false };
    }

    case GSTR2A_SUMMARY_CDN_SUCCESS: {
      return {
        ...state,
        gstr2aCDNStatus: action.payload[0],
        gstr2aCDNData: action.payload[1],
        gstr2aCDNMessage: action.payload[2],
      };
    }

    case GSTR2A_SUMMARY_CDN_FAILED: {
      return {
        ...state,
        gstr2aCDNStatus: action.payload[0],
        gstr2aCDNMessage: action.payload[1],
      };
    }

    case GSTR2A_SUMMARY_TDS_INIT: {
      return { ...state, gstr2aTDSReqStatus: true };
    }
    case GSTR2A_SUMMARY_TDS_FINISH: {
      return { ...state, gstr2aTDSReqStatus: false };
    }

    case GSTR2A_SUMMARY_TDS_SUCCESS: {
      return {
        ...state,
        gstr2aTDSStatus: action.payload[0],
        gstr2aTDSData: action.payload[1],
        gstr2aTDSMessage: action.payload[2],
      };
    }

    case GSTR2A_SUMMARY_TDS_FAILED: {
      return {
        ...state,
        gstr2aTDSStatus: action.payload[0],
        gstr2aTDSMessage: action.payload[1],
      };
    }

    case GSTR2A_SUMMARY_IMPG_INIT: {
      return { ...state, gstr2aIMPGReqStatus: true };
    }
    case GSTR2A_SUMMARY_IMPG_FINISH: {
      return { ...state, gstr2aIMPGReqStatus: false };
    }

    case GSTR2A_SUMMARY_IMPG_SUCCESS: {
      return {
        ...state,
        gstr2aIMPGStatus: action.payload[0],
        gstr2aIMPGData: action.payload[1],
        gstr2aIMPGMessage: action.payload[2],
      };
    }

    case GSTR2A_SUMMARY_IMPG_FAILED: {
      return {
        ...state,
        gstr2aIMPGStatus: action.payload[0],
        gstr2aIMPGMessage: action.payload[1],
      };
    }

    case GSTR2A_DOWNLOAD_STATUS_INIT: {
      return { ...state, gstr2aDownloadReqStatus: true };
    }
    case GSTR2A_DOWNLOAD_STATUS_FINISH: {
      return { ...state, gstr2aDownloadReqStatus: false };
    }

    case UPDATE_GSTR_2A_DOWNLOAD_STATUS_DATA: {
      return { ...state, gstr2aDownloadData: action.payload }
    }

    case GSTR2A_DOWNLOAD_STATUS_SUCCESS: {
      return {
        ...state,
        gstr2aDownloadStatus: action.payload[0],
        gstr2aDownloadData: action.payload[1],
        gstr2aDownloadMessage: action.payload[2],
      };
    }

    case GSTR2A_DOWNLOAD_STATUS_FAILED: {
      return {
        ...state,
        gstr2aDownloadStatus: action.payload[0],
        gstr2aDownloadMessage: action.payload[1],
      };
    }

    case GSTR2A_SINGLE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        gstr2aSingleDownloadData: action.payload,
      };
    }

    case GSTR2A_SINGLE_DOWNLOAD_MSG: {
      return { ...state, gstr2aSingleDownloadMsg: action.payload }
    }

    case GSTIN_SESSION_STATUS: {
      return { ...state, gstinSessionStatus: action.payload }
    }

    case GSTR_YEARS_LIST: {
      return { ...state, yearsList: action.payload };
    }

    // gstr2a download status download button
    case GSTR2A_DOWNLOAD_PERIOD: {
      return { ...state, downloadPeriod: action.payload };
    }

    case GSTR_SELECTED_FINANCIAL_YEAR: {
      return { ...state, financialYear: action.payload };
    }

    //Gstr2aTCS
    case GSTR2A_SUMMARY_TCS_INIT: {
      return { ...state, gstr2aTCSReqStatus: true };
    }
    case GSTR2A_SUMMARY_TCS_FINISH: {
      return { ...state, gstr2aTCSReqStatus: false };
    }

    case GSTR2A_SUMMARY_TCS_SUCCESS: {
      return {
        ...state,
        gstr2aTCSStatus: action.payload[0],
        gstr2aTCSData: action.payload[1],
        gstr2aTCSMessage: action.payload[2],
      };
    }

    case GSTR2A_SUMMARY_TCS_UPDATE: {
      return {...state, gstr2aTCSData: action.payload}
    }

    case GSTR2A_SUMMARY_TCS_FAILED: {
      return {
        ...state,
        gstr2aTCSStatus: action.payload[0],
        gstr2aTCSMessage: action.payload[1],
      };
    }

    // Ineligible credit for delete

    case GSTR2A_INELIGIBLE_CREDIT_INIT: {
      return {
        ...state,
        gstr2aIneligibleCreditReqStatus: true,
        gstr2aIneligibleCreditStatus: null,
        gstr2aIneligibleCreditData: null,
        gstr2aIneligibleCreditMessage: null,
      };
    }

    case GSTR2A_INELIGIBLE_CREDIT_FINISH: {
      return { ...state, gstr2aIneligibleCreditReqStatus: false };
    }

    case GSTR2A_INELIGIBLE_CREDIT_SUCCESS: {
      return {
        ...state,
        gstr2aIneligibleCreditStatus: action.payload[0],
        gstr2aIneligibleCreditData: action.payload[1],
        gstr2aIneligibleCreditMessage: action.payload[2],
      };
    }

    case GSTR2A_INELIGIBLE_CREDIT_FAILED: {
      return {
        ...state,
        gstr2aIneligibleCreditStatus: action.payload[0],
        gstr2aIneligibleCreditMessage: action.payload[1],
      };
    }

    // Ineligible credit get data 

    case GSTR2A_INELIGIBLE_DATA_INIT: {
      return {
        ...state,
        gstr2aIneligibleDataReqStatus: true,
        gstr2aIneligibleDataStatus: null,
        gstr2aIneligibleData: null,
        gstr2aIneligibleDataMessage: null,
      };
    }

    case GSTR2A_INELIGIBLE_DATA_FINISH: {
      return { ...state, gstr2aIneligibleDataReqStatus: false };
    }

    case GSTR2A_INELIGIBLE_DATA_SUCCESS: {
      return {
        ...state,
        gstr2aIneligibleDataStatus: action.payload[0],
        gstr2aIneligibleData: action.payload[1],
        gstr2aIneligibleDataMessage: action.payload[2],
      };
    }

    case GSTR2A_INELIGIBLE_DATA_UPDATE: {
      return {
        ...state,
        gstr2aIneligibleData: action.payload,
      };
    }

    case GSTR2A_INELIGIBLE_DATA_FAILED: {
      return {
        ...state,
        gstr2aIneligibleDataStatus: action.payload[0],
        gstr2aIneligibleDataMessage: action.payload[1],
      };
    }


    default:
      return state;
  }
}
