export const OTP_REQUEST_INIT = "OTP_REQUEST_INIT";
export const OTP_REQUEST_FINISH = "OTP_REQUEST_FINISH";
export const OTP_REQUEST_SUCCESS = "OTP_REQUEST_SUCCESS";
export const OTP_AUTH_SUCCESS = "OTP_AUTH_SUCCESS";
export const AUTH_TOKEN_INIT = "AUTH_TOKEN_INIT";
export const AUTH_TOKEN_FINISH = "AUTH_TOKEN_FINISH";
export const AUTH_TOKEN_VERIFIED = "AUTH_TOKEN_VERIFIED";

export const CHECK_FIRST_TIME_AUTH = "CHECK_FIRST_TIME_AUTH";

export const GET_BUSINESS_DETAILS_INIT = "GET_BUSINESS_DETAILS_INIT";
export const GET_BUSINESS_DETAILS_FINISH = "GET_BUSINESS_DETAILS_FINISH";
export const GET_BUSINESS_DETAILS_SUCCESS = "GET_BUSINESS_DETAILS_SUCCESS";
export const GET_BUSINESS_DETAILS_FAILED = "GET_BUSINESS_DETAILS_FAILED";

//Authentication user Details

export const GET_USER_DETAILS_INIT = "GET_USER_DETAILS_INIT";
export const GET_USER_DETAILS_FINISH = "GET_USER_DETAILS_FINISH";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";

export const SET_VALID_USERNAME = "SET_VALID_USERNAME";
export const SET_VALID_GSTIN = "SET_VALID_GSTIN";
export const SET_OTP = "SET_OTP";
export const SET_USERNAME = "SET_USERNAME";
export const SET_GSTIN = "SET_GSTIN";
export const DELETE_GSTIN_INIT = "DELETE_GSTIN_INIT";
export const DELETE_GSTIN_FINISH = "DELETE_GSTIN_FINISH";
export const DELETE_GSTIN_STATUS = "DELETE_GSTIN_STATUS";
export const DELETE_GSTIN_MESSAGE = "DELETE_GSTIN_MESSAGE";
export const GET_GSTIN_DETAILS_INIT = "GET_GSTIN_DETAILS_INIT";
export const GET_GSTIN_DETAILS_FINISH = "GET_GSTIN_DETAILS_FINISH";
export const GSTIN_DETAILS = "GSTIN_DETAILS";

export const GET_SUPPLIER_GSTIN_DETAILS_INIT = "GET_SUPPLIER_GSTIN_DETAILS_INIT";
export const GET_SUPPLIER_GSTIN_DETAILS_FINISH = "GET_SUPPLIER_GSTIN_DETAILS_FINISH";
export const SUPPLIER_GSTIN_DETAILS = "SUPPLIER_GSTIN_DETAILS";

export const RESET_AUTHENTICATION_DATA = "RESET_AUTHENTICATION_DATA";
export const OTP_REQUEST_MESSAGE = "OTP_REQUEST_MESSAGE";
export const AUTH_REQUEST_MESSAGE = "AUTH_REQUEST_MESSAGE";
export const REFRESH_API_MODAL = "REFRESH_API_MODAL";
export const GSTIN_CHANGE_UPDATE = "GSTIN_CHANGE_UPDATE";

/// auto_download_gstr2a API

export const AUTO_DOWNLOAD_GSTR2A_INIT = "AUTO_DOWNLOAD_GSTR2A_INIT";
export const AUTO_DOWNLOAD_GSTR2A_FINISH = "AUTO_DOWNLOAD_GSTR2A_FINISH";
export const AUTO_DOWNLOAD_GSTR2A_SUCCESS = "AUTO_DOWNLOAD_GSTR2A_SUCCESS";
export const AUTO_DOWNLOAD_GSTR2A_FAILED = "AUTO_DOWNLOAD_GSTR2A_FAILED";


// get_tally_companies_by_user

export const TALLY_COMPANIES_DATA_INIT = "TALLY_COMPANIES_DATA_INIT";
export const TALLY_COMPANIES_DATA_FINISH = "TALLY_COMPANIES_DATA_FINISH";
export const TALLY_COMPANIES_DATA = "TALLY_COMPANIES_DATA";

/// link_tally_company

export const LINK_TALLY_COMPANY_INIT = "LINK_TALLY_COMPANY_INIT";
export const LINK_TALLY_COMPANY_FINISH = "LINK_TALLY_COMPANY_FINISH";
export const LINK_TALLY_COMPANY_DATA = "LINK_TALLY_COMPANY_DATA";
export const LINK_TALLY_COMPANY_MESSAGE = "LINK_TALLY_COMPANY_MESSAGE";


/// link company modal

export const LINK_COMPANY_MODAL = "LINK_COMPANY_MODAL";


///// get_users_by_gstin


export const GET_USER_BY_GSTIN_INIT = "GET_USER_BY_GSTIN_INIT";
export const GET_USER_BY_GSTIN_FINISH = "GET_USER_BY_GSTIN_FINISH";
export const GET_USER_BY_GSTIN_SUCCESS = "GET_USER_BY_GSTIN_SUCCESS";
export const GET_USER_BY_GSTIN_FAILED = "GET_USER_BY_GSTIN_FAILED";

export const UPDATE_GET_USER_BY_GSTIN_DATA = "UPDATE_GET_USER_BY_GSTIN_DATA";


///// update_gstin_user

export const UPDATE_GSTIN_USER_INIT = "UPDATE_GSTIN_USER_INIT";
export const UPDATE_GSTIN_USER_FINISH = "UPDATE_GSTIN_USER_FINISH";
export const UPDATE_GSTIN_USER_SUCCESS = "UPDATE_GSTIN_USER_SUCCESS";
export const UPDATE_GSTIN_USER_FAILED = "UPDATE_GSTIN_USER_FAILED";


///// delete_gstin_role

export const DELETE_GSTIN_ROLE_INIT = "DELETE_GSTIN_ROLE_INIT";
export const DELETE_GSTIN_ROLE_FINISH = "DELETE_GSTIN_ROLE_FINISH";
export const DELETE_GSTIN_ROLE_SUCCESS = "DELETE_GSTIN_ROLE_SUCCESS";
export const DELETE_GSTIN_ROLE_FAILED = "DELETE_GSTIN_ROLE_FAILED";

export const SET_NO_COMPANIES_FLAG = "SET_NO_COMPANIES_FLAG";


export const GET_CONNECTED_TALLY_COMPANIES = "GET_CONNECTED_TALLY_COMPANIES";
export const GET_CONNECTED_TALLY_COMPANIES_SUCCESS = "GET_CONNECTED_TALLY_COMPANIES_SUCCESS";
export const GET_CONNECTED_TALLY_COMPANIES_FAILURE = "GET_CONNECTED_TALLY_COMPANIES_FAILURE";
