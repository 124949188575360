import {
  FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS,
  FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS,
  FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS,
  GET_LEDGERS_LIST_SUCCESS,
  TOGGLE_LEDGER_TRANSACTION_AGGR_CHART,
  TOP_PAYABLES_LIST_SUCCESS,
  TOP_RECEIVABLES_LIST_SUCCESS,
  GET_LEDGERS_LIST_REPORT_SUCCESS,
  GET_LEDGERS_BY_TYPES,
  GET_LEDGERS_BY_TYPES_SUCCESS,
  GET_LEDGERS_BY_TYPES_FAILURE,
  GET_LEDGERS_BY_ID,
  GET_LEDGERS_BY_ID_SUCCESS,
  GET_LEDGERS_BY_ID_FAILURE,
  GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS,
  GET_LEDGERS_BY_USERS_INPROGESS,


  GET_LEDGERS_BY_USERS_SUCCESS,


  

  GET_UNDERGROUP_LIST,
  GET_UNDERGROUP_LIST_SUCCESS,
  GET_UNDERGROUP_LIST_FAILURE,

  CREATE_LEDGER,
  CREATE_LEDGER_SUCCESS,
  CREATE_LEDGER_ERROR,

  CLEAR_CREATE_LEDGER_RESP,
  GET_COMPANY_PROFILE_INFO,
  GET_COMPANY_PROFILE_INFO_SUCCESS,
  GET_COMPANY_PROFILE_INFO_ERROR,

  GET_LEDGER_MAILING_DETAILS,
  GET_LEDGER_MAILING_DETAILS_SUCCESS,
  GET_LEDGER_MAILING_DETAILS_FAILURE,

  GET_LEDGER_BANK_ACC_DETAILS,
  GET_LEDGER_BANK_ACC_DETAILS_SUCCESS,
  GET_LEDGER_BANK_ACC_DETAILS_FAILURE,

  GET_LEDGER_STATUTORY_DETAILS,
  GET_LEDGER_STATUTORY_DETAILS_SUCCESS,
  GET_LEDGER_STATUTORY_DETAILS_FAILURE,

  GET_LEDGER_PARTY_DETAILS,
  GET_LEDGER_PARTY_DETAILS_SUCCESS,
  GET_LEDGER_PARTY_DETAILS_FAILURE,

  GET_LEDGER_OTHER_DETAILS,
  GET_LEDGER_OTHER_DETAILS_SUCCESS,
  GET_LEDGER_OTHER_DETAILS_FAILURE,
  GET_TRANSACTIONS_BY_LEDGER_ID,



} from "src/actions/actionTypes";
import { getList } from "src/util/enums";

const inititalState = {
  allLedgerTransactions: [],
  againstLedgerGroupingData: [],
  againstLedgerGroupingByTransactionTypeData: [],
  againstLedgerGroupingByMonthData: [],
  againstLedgerGroupingByDateData: [],
  ledgerTransactions: [],
  expandedLedgerTransactions: {},
  toggleChart: {
    ledgerTransactionAggrByTranType: false,
  },
  ledgersList: [],
  topReceivablesList: [],
  topPayablesList: [],
  ledgerReportsList:[],
  ledgerListByTypes:[],
  ledgerData:[],
  tansactionListByLedgerId:[],

  ledgerListByUsers:[],
  underGroupList:{},
  createLedgerResp:{},


  ledgerListByUsers:[],


  underGroupList:{},
  createLedgerResp:{},
  ledgerMailingDetails:{},
  ledgerBankAccgDetails:{},
  ledgerStatutorygDetails:{},
  ledgerPartyDetails:{},
  ledgerOtherDetails:{}


};

export function ledger(state = inititalState, action) {
  switch (action.type) {
    case FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS: {
      return { ...state, allLedgerTransactions: action.payload };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS: {
      return { ...state, againstLedgerGroupingData: action.payload };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByTransactionTypeData: action.payload,
      };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByMonthData: action.payload,
      };
    }
    case FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS: {
      return {
        ...state,
        againstLedgerGroupingByDateData: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS: {
      return {
        ...state,
        ledgerTransactions: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS: {
      return {
        ...state,
        expandedLedgerTransactions: action.payload,
      };
    }
    case FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS: {
      return { ...state, againstLedgerGroupingData: action.payload };
    }
    case TOGGLE_LEDGER_TRANSACTION_AGGR_CHART: {
      return {
        ...state,
        toggleChart: {
          ...state.toggleChart,
          ledgerTransactionAggrByTranType: action.payload,
        },
      };
    }
    case GET_LEDGERS_LIST_SUCCESS: {
      return {
        ...state,
        ledgersList: action.payload,
      };
    }
    case TOP_RECEIVABLES_LIST_SUCCESS: {
      return {
        ...state,
        topReceivablesList: action.payload,
      };
    }
    case TOP_PAYABLES_LIST_SUCCESS: {
      return {
        ...state,
        topPayablesList: action.payload,
      };
    };
    case GET_LEDGERS_LIST_REPORT_SUCCESS:{
      return {...state , ledgerReportsList: action.payload}
    };
    case GET_LEDGERS_BY_TYPES: {
      return {...state , ledgerListByTypes: getList.inprogress}
    }
    case GET_LEDGERS_BY_TYPES_SUCCESS: {
      return {...state , ledgerListByTypes: action.payload}
    }
    case GET_LEDGERS_BY_ID: {
      return {...state , ledgerData: getList.inprogress}
    }
    case GET_LEDGERS_BY_ID_SUCCESS: {
      return {...state , ledgerData: action.payload}
    }
    case GET_TRANSACTIONS_BY_LEDGER_ID: {
      return {...state , tansactionListByLedgerId: getList.inprogress}
    }
    case GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS: {
      return {...state , tansactionListByLedgerId: action.payload}
    }
    case GET_LEDGERS_BY_USERS_INPROGESS: {
      return {...state , ledgerListByUsers: getList.inprogress}
    }
    case GET_LEDGERS_BY_USERS_SUCCESS: {
      return {...state , ledgerListByUsers: action.payload}
    }



    case CREATE_LEDGER_SUCCESS: {
      return {...state , createLedgerResp: action.payload}
    }
    case CREATE_LEDGER_ERROR: {
      return {...state , createLedgerResp: action.payload}
    }
    case CLEAR_CREATE_LEDGER_RESP: {
      return {...state , createLedgerResp: inititalState.createLedgerResp}
    }

    case GET_UNDERGROUP_LIST_SUCCESS: {
      return {...state , underGroupList: action.payload}
    }
    case GET_UNDERGROUP_LIST_FAILURE: {
      return {...state , underGroupList: action.payload}
    }

    case GET_LEDGER_MAILING_DETAILS_SUCCESS: {
      return {...state , ledgerMailingDetails: action.payload}
    }
    case GET_LEDGER_MAILING_DETAILS_FAILURE: {
      return {...state , ledgerMailingDetails: action.payload}
    }
    case GET_LEDGER_BANK_ACC_DETAILS_SUCCESS: {
      return {...state , ledgerBankAccgDetails: action.payload}
    }
    case GET_LEDGER_BANK_ACC_DETAILS_FAILURE: {
      return {...state , ledgerBankAccgDetails: action.payload}
    }
    case GET_LEDGER_STATUTORY_DETAILS_SUCCESS: {
      return {...state , ledgerStatutorygDetails: action.payload}
    }
    case GET_LEDGER_STATUTORY_DETAILS_FAILURE: {
      return {...state , ledgerStatutorygDetails: action.payload}
    }
    case GET_LEDGER_PARTY_DETAILS_SUCCESS: {
      return {...state , ledgerPartyDetails: action.payload}
    }
    case GET_LEDGER_PARTY_DETAILS_FAILURE: {
      return {...state , ledgerPartyDetails: action.payload}
    }
    case GET_LEDGER_OTHER_DETAILS_SUCCESS: {
      return {...state , ledgerOtherDetails: action.payload}
    }
    case GET_LEDGER_OTHER_DETAILS_FAILURE: {
      return {...state , ledgerOtherDetails: action.payload}
    }


    default:
      return state;
  }
}
