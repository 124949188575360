import {
    GSTR2B_SUMMARY_INIT,
    GSTR2B_SUMMARY_FINISH,
    GSTR2B_SUMMARY_SUCCESS,
    GSTR2B_SUMMARY_FAILED,
    GSTR2B_B2B_SUMMARY_INIT,
    GSTR2B_B2B_SUMMARY_FINISH,
    GSTR2B_B2B_SUMMARY_SUCCESS,
    GSTR2B_B2B_SUMMARY_FAILED,

    GSTR2B_CDN_SUMMARY_INIT,
    GSTR2B_CDN_SUMMARY_FINISH,
    GSTR2B_CDN_SUMMARY_SUCCESS,
    GSTR2B_CDN_SUMMARY_FAILED,

    GSTR2B_ISD_SUMMARY_INIT,
    GSTR2B_ISD_SUMMARY_FINISH,
    GSTR2B_ISD_SUMMARY_SUCCESS,
    GSTR2B_ISD_SUMMARY_FAILED,


    GSTR2B_B2B_DOCUMENT_INIT,
    GSTR2B_B2B_DOCUMENT_FINISH,
    GSTR2B_B2B_DOCUMENT_SUCCESS,
    GSTR2B_B2B_DOCUMENT_FAILED,


    GSTR2B_CDN_DOCUMENT_INIT,
    GSTR2B_CDN_DOCUMENT_FINISH,
    GSTR2B_CDN_DOCUMENT_SUCCESS,
    GSTR2B_CDN_DOCUMENT_FAILED,

    GSTR2B_IMPG_DOCUMENT_INIT,
    GSTR2B_IMPG_DOCUMENT_FINISH,
    GSTR2B_IMPG_DOCUMENT_SUCCESS,
    GSTR2B_IMPG_DOCUMENT_FAILED,

    GSTR2B_DOWNLOAD_STATUS_INIT,
    GSTR2B_DOWNLOAD_STATUS_FINISH,
    GSTR2B_DOWNLOAD_STATUS_SUCCESS,
    GSTR2B_DOWNLOAD_STATUS_FAILED,
    GSTR2B_DOWNLOAD_PERIOD,
    GSTR2B_SINGLE_DOWNLOAD_SUCCESS,
    GSTR2B_DETAILS_DATA,

    GSTR2B_SUMMARY_DOWNLOAD_INIT,
    GSTR2B_SUMMARY_DOWNLOAD_FINISH,


    GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_INIT,
    GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_FINISH,
    ACTIVE_TAB_INDEX,

} from 'src/actions/actionTypes';

const initialState = {
    gstr2bSUPPLIER_DOCUMENTReqStatus: false,
    gstr2bSummaryStatus: null,
    gstr2bSummaryData: {},
    gstr2bSummaryMessage: null,

    gstr2bB2BSummaryReqStatus: false,
    gstr2bB2BSummaryStatus: null,
    gstr2bB2BSummaryData: null,
    gstr2bB2BSummaryMessage: null,

    gstr2bCDNSummaryReqStatus: false,
    gstr2bCDNSummaryStatus: null,
    gstr2bCDNSummaryData: null,
    gstr2bCDNSummaryMessage: null,

    gstr2bISDSummaryReqStatus: false,
    gstr2bISDSummaryStatus: null,
    gstr2bISDSummaryData: null,
    gstr2bISDSummaryMessage: null,


    gstr2bB2BDocumentReqStatus: false,
    gstr2bB2BDocumentStatus: null,
    gstr2bB2BDocumentData: null,
    gstr2bB2BDocumentMessage: null,

    gstr2bCDNDocumentReqStatus: false,
    gstr2bCDNDocumentStatus: null,
    gstr2bCDNDocumentData: null,
    gstr2bDetailsData: {},

    gstr2bCDNDocumentMessage: null,

    gstr2bIMPGDocumentReqStatus: false,
    gstr2bIMPGDocumentStatus: null,
    gstr2bIMPGDocumentData: null,
    gstr2bIMPGDocumentMessage: null,

    gstr2bDownloadData: [],
    gstr2bDownloadStatus: true,

    downloadPeriod2b: [],
    gstr2bSingleDownloadData: null,


    gstr2bSingleDownloadMsg: '',
    // gstinSessionStatus: null, 



    invoiceTotalTaxable: null,
    invoiceTotalIGST: null,
    invoiceTotalSGST: null,
    invoiceTotalCGST: null,
    invoiceTotalCESS: null,
    invoiceTotalNum: null,


    cdnTotalValue: null,
    cdnTotalTaxable: null,
    cdnTotalIGST: null,
    cdnTotalSGST: null,
    cdnTotalCGST: null,
    cdnTotalCESS: null,
    cdnTotalNum: null,


    impgTotalTaxable: null,
    impgTotalIGST: null,
    impgTotalCESS: null,
    impgTotalNum: null,

    gstr2bSummaryDownloadStatus: {status: false, period: ''},
    gstr2bSupplierDocumentDownloadStatus :false,
    defaultTab:null

};

function Gstr2bReducer(state = initialState, action) {
    switch (action.type) {


        case GSTR2B_SUMMARY_DOWNLOAD_INIT: {
            return { ...state, gstr2bSummaryDownloadStatus: {status: true, period: action.payload} }
        }
        case GSTR2B_SUMMARY_DOWNLOAD_FINISH: {
            return { ...state, gstr2bSummaryDownloadStatus: {status: false, period: action.payload} }
        }

        case GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_INIT: {
            return { ...state, gstr2bSupplierDocumentDownloadStatus: true }
        }
        case GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_FINISH: {
            return { ...state, gstr2bSupplierDocumentDownloadStatus: false }
        }

        case "SET_2B_IMPG_TOTAL_TAXABLE_VALUE": {
            return { ...state, impgTotalTaxable: action.payload }
        }
        case "SET_2B_IMPG_TOTAL_IGST": {
            return { ...state, impgTotalIGST: action.payload }
        }
        case "SET_2B_IMPG_TOTAL_CESS": {
            return { ...state, impgTotalCESS: action.payload }
        }
        case "SET_2B_IMPG_TOTAL_NUM": {
            return { ...state, impgTotalNum: action.payload }
        }



        case "SET_2B_CDN_TOTAL_TAXABLE_VALUE": {
            return { ...state, cdnTotalTaxable: action.payload }
        }

        case "SET_2B_CDN_TOTAL_IGST": {
            return { ...state, cdnTotalIGST: action.payload }
        }

        case "SET_2B_CDN_TOTAL_SGST": {
            return { ...state, cdnTotalSGST: action.payload }
        }

        case "SET_2B_CDN_TOTAL_CGST": {
            return { ...state, cdnTotalCGST: action.payload }
        }

        case "SET_2B_CDN_TOTAL_CESS": {
            return { ...state, cdnTotalCESS: action.payload }
        }

        case "SET_2B_CDN_TOTAL_NUM": {
            return { ...state, cdnTotalNum: action.payload }
        }


        case "SET_2B_INVOICE_TOTAL_TAXABLE_VALUE": {
            return { ...state, invoiceTotalTaxable: action.payload }
        }


        case "SET_2B_INVOICE_TOTAL_NUM": {
            return { ...state, invoiceTotalNum: action.payload }
        }


        case "SET_2B_INVOICE_TOTAL_IGST": {
            return { ...state, invoiceTotalIGST: action.payload }
        }

        case "SET_2B_INVOICE_TOTAL_SGST": {
            return { ...state, invoiceTotalSGST: action.payload }
        }
        case "SET_2B_INVOICE_TOTAL_CGST": {
            return { ...state, invoiceTotalCGST: action.payload }
        }
        case "SET_2B_INVOICE_TOTAL_CESS": {
            return { ...state, invoiceTotalCESS: action.payload }
        }


        case GSTR2B_DOWNLOAD_PERIOD: {
            return { ...state, downloadPeriod2b: action.payload };
        }

        case GSTR2B_DOWNLOAD_STATUS_INIT: {
            return { ...state, gstr2bDownloadStatus: true };
        }
        case GSTR2B_DOWNLOAD_STATUS_FINISH: {
            return { ...state, gstr2bDownloadStatus: false };
        }

        case GSTR2B_DOWNLOAD_STATUS_SUCCESS: {
            return {
                ...state,
                gstr2bDownloadStatus: false,
                gstr2bDownloadData: action.payload[1],
                gstr2bDownloadMessage: action.payload[2],
            };
        }

        case GSTR2B_DOWNLOAD_STATUS_FAILED: {
            return {
                ...state,
                gstr2bDownloadStatus: false,
                gstr2bDownloadMessage: action.payload[1],
            };
        }

        case GSTR2B_SINGLE_DOWNLOAD_SUCCESS: {
            return {
                ...state,
                gstr2bSingleDownloadData: action.payload,
            };
        }


        case "GSTR2B_SINGLE_DOWNLOAD_MSG": {
            return { ...state, gstr2bSingleDownloadMsg: action.payload }
        }


        case "UPDATE_GSTR_2B_DOWNLOAD_STATUS_DATA": {
            return { ...state, gstr2bDownloadData: action.payload }
        }

        // case "GSTIN_SESSION_STATUS": {
        //     return {...state, gstinSessionStatus: action.payload }
        // }


        case GSTR2B_IMPG_DOCUMENT_INIT:
            return { ...state, gstr2bIMPGDocumentReqStatus: true }

        case GSTR2B_IMPG_DOCUMENT_FINISH:
            return { ...state, gstr2bIMPGDocumentReqStatus: false }

        case GSTR2B_IMPG_DOCUMENT_SUCCESS:
            return { ...state, gstr2bIMPGDocumentStatus: action.payload[0], gstr2bIMPGDocumentData: action.payload[1], gstr2bIMPGDocumentMessage: action.payload[2] }

        case GSTR2B_IMPG_DOCUMENT_FAILED:
            return { ...state, gstr2bIMPGDocumentStatus: action.payload[0], gstr2bIMPGDocumentMessage: action.payload[1] }









        case GSTR2B_CDN_DOCUMENT_INIT:
            return { ...state, gstr2bCDNDocumentReqStatus: true }

        case GSTR2B_CDN_DOCUMENT_FINISH:
            return { ...state, gstr2bCDNDocumentReqStatus: false }

        case GSTR2B_CDN_DOCUMENT_SUCCESS:
            return { ...state, gstr2bCDNDocumentStatus: action.payload[0], gstr2bCDNDocumentData: action.payload[1], gstr2bCDNDocumentMessage: action.payload[2] }

        case GSTR2B_CDN_DOCUMENT_FAILED:
            return { ...state, gstr2bCDNDocumentStatus: action.payload[0], gstr2bCDNDocumentMessage: action.payload[1] }

        case GSTR2B_DETAILS_DATA:
            return { ...state, gstr2bDetailsData: action.payload }



        case GSTR2B_B2B_DOCUMENT_INIT:
            return { ...state, gstr2bB2BDocumentReqStatus: true }

        case GSTR2B_B2B_DOCUMENT_FINISH:
            return { ...state, gstr2bB2BDocumentReqStatus: false }

        case GSTR2B_B2B_DOCUMENT_SUCCESS:
            return { ...state, gstr2bB2BDocumentStatus: action.payload[0], gstr2bB2BDocumentData: action.payload[1], gstr2bB2BDocumentMessage: action.payload[2] }

        case GSTR2B_B2B_DOCUMENT_FAILED:
            return { ...state, gstr2bB2BDocumentStatus: action.payload[0], gstr2bB2BDocumentMessage: action.payload[1] }


        case "UPDATE_GSTR2B_INVOICE_DATA": {
            return { ...state, gstr2bB2BDocumentData: action.payload }
        }


        case GSTR2B_ISD_SUMMARY_INIT:
            return { ...state, gstr2bISDSummaryReqStatus: true }

        case GSTR2B_ISD_SUMMARY_FINISH:
            return { ...state, gstr2bISDSummaryReqStatus: false }

        case GSTR2B_ISD_SUMMARY_SUCCESS:
            return { ...state, gstr2bISDSummaryStatus: action.payload[0], gstr2bISDSummaryData: action.payload[1], gstr2bISDSummaryMessage: action.payload[2] }

        case GSTR2B_ISD_SUMMARY_FAILED:
            return { ...state, gstr2bISDSummaryStatus: action.payload[0], gstr2bISDSummaryMessage: action.payload[1] }


        case GSTR2B_CDN_SUMMARY_INIT:
            return { ...state, gstr2bCDNSummaryReqStatus: true }

        case GSTR2B_CDN_SUMMARY_FINISH:
            return { ...state, gstr2bCDNSummaryReqStatus: false }

        case GSTR2B_CDN_SUMMARY_SUCCESS:
            return { ...state, gstr2bCDNSummaryStatus: action.payload[0], gstr2bCDNSummaryData: action.payload[1], gstr2bCDNSummaryMessage: action.payload[2] }

        case GSTR2B_CDN_SUMMARY_FAILED:
            return { ...state, gstr2bCDNSummaryStatus: action.payload[0], gstr2bCDNSummaryMessage: action.payload[1] }

        case GSTR2B_B2B_SUMMARY_INIT:
            return { ...state, gstr2bB2BSummaryReqStatus: true }

        case GSTR2B_B2B_SUMMARY_FINISH:
            return { ...state, gstr2bB2BSummaryReqStatus: false }

        case GSTR2B_B2B_SUMMARY_SUCCESS:
            return { ...state, gstr2bB2BSummaryStatus: action.payload[0], gstr2bB2BSummaryData: action.payload[1], gstr2bB2BSummaryMessage: action.payload[2] }

        case GSTR2B_B2B_SUMMARY_FAILED:
            return { ...state, gstr2bB2BSummaryStatus: action.payload[0], gstr2bB2BSummaryMessage: action.payload[1] }

        case GSTR2B_SUMMARY_INIT:
            return { ...state, gstr2bSummaryReqStatus: true }

        case GSTR2B_SUMMARY_FINISH:
            return { ...state, gstr2bSummaryReqStatus: false }

        case GSTR2B_SUMMARY_SUCCESS:
            return { ...state, gstr2bSummaryStatus: action.payload[0], gstr2bSummaryData: action.payload[1], gstr2bSummaryMessage: action.payload[2] }

        case GSTR2B_SUMMARY_FAILED:
            return { ...state, gstr2bSummaryStatus: action.payload[0], gstr2bSummaryMessage: action.payload[1] }
        case ACTIVE_TAB_INDEX:
                return { ...state, defaultTab: action.payload}

        default:
            return state;
    }
};

export default Gstr2bReducer;