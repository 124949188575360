import React, { useState, useEffect } from 'react'
import Logofull from "src/assets/icons/logo-large.png";
import {
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import ToastMessage from "src/components/toast-message/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { verifyRegisteringUser, resendEmailForVerification } from 'src/actions/registration';
import { css } from "@emotion/core";

import cookie from 'react-cookies'


import { useLocation } from "react-router-dom";
import { ScaleLoader } from 'react-spinners';

const VerifyRegistration = () => {
    const inviteeStatus = useSelector(state => state?.registration?.inviteeStatus)
    const responceAfterInvitereg = useSelector(state => state?.registration?.responceAfterInvite)
    const resendInvitationLinkData = useSelector(state => state.registration?.resendInvitationLinkData)
    const history = useHistory()
    const { search } = useLocation();
    const token = search.replace('?token=', '');
    const dispatch = useDispatch()
    const [showContent, setShowContent] = useState("")
    const [emailForLogIn, setemailForLogIn] = useState()
    const [passwordForlogIn, setpasswordForlogIn] = useState()
    const [currentUserEmail, setcurrentUserEmail] = useState()
    useEffect(() => {
        if (responceAfterInvitereg && Object.keys(responceAfterInvitereg).length > 0) {
            setmailContent(responceAfterInvitereg?.response)
            if (responceAfterInvitereg?.status) {
                setShowContent("user-already-registred")
                setemailForLogIn(responceAfterInvitereg?.invitee_email)
                setloading(false)
            } else {
                setShowContent("user-verification-failed")
                setloading(false)
            }
        }
    }, [responceAfterInvitereg])
    useEffect(() => {
        setcurrentUserEmail(token.split("&email=")[1])
    }, [token])
    useEffect(() => {
        if (token) {
            const data = {
                "token": token.split("&email=")[0]
            }
            dispatch(verifyRegisteringUser(data))
        } else {
            // if user logged in navigate to dashboard
            // if user not logged in navigate to login
            if (cookie.load("GST_USER_DATA")) {
                if (cookie.load("SELECTED_GSTIN_DETAIL")) {
                    history.push("/authentication")
                } else {
                    history.push("/no-gst")
                }
            }
        }
    }, [token])
    useEffect(() => {
        setloading(true)
        setmailContent("")
        if (inviteeStatus && Object.keys(inviteeStatus).length > 0) {
            setmailContent(inviteeStatus?.response)
            if (inviteeStatus?.status && !inviteeStatus?.resend) {
                setShowContent("User successfully verified")
                setloading(false)
            } else if (!inviteeStatus?.status && !inviteeStatus?.resend) {
                if (inviteeStatus?.response == "User is already verified") {
                    setShowContent("User is already verified")
                    setloading(false)
                } else if (inviteeStatus?.response == "Invalid Token") {
                    setloading(false)
                    setShowContent("user-verification-failed")
                }
            } else if (!inviteeStatus?.status && inviteeStatus?.resend) {
                setloading(false)
                setShowContent("Registration link expired")
            }

        }
    }, [inviteeStatus])
    const [loading, setloading] = useState(true)
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    const [showToast, setshowToast] = useState(false)
    const [responseMsg, setResponseMsg] = useState("")
    useEffect(() => {
        if (resendInvitationLinkData) {
            if (resendInvitationLinkData?.status) {
                setResponseMsg(resendInvitationLinkData?.response);
                setToastSeverity('success');
                setshowToast(true)
                setTimeout(() => {
                    setshowToast(false)
                }, 3000);
            } else {
                setResponseMsg(resendInvitationLinkData?.response);
                setToastSeverity('error');
                setshowToast(true)
                setTimeout(() => {
                    setshowToast(false)
                }, 3000);
            }
        }

    }, [resendInvitationLinkData])
    const [mailContent, setmailContent] = useState("")

    const [ToastSeverity, setToastSeverity] = useState('');

    const onClickOnLogin = () => {
        history.push("/login")
    }


    const resendVefication = () => {
        const data = {
            "email": currentUserEmail
        }
        dispatch(resendEmailForVerification(data))
    }
    return (
        <>
            <div className="">
                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="6" lg="6" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center p-5 inside-border-radius">
                                            {!loading &&
                                                <div>
                                                    {showContent == "User successfully verified" && <div className="success-full">
                                                        <span className="verify-success-icon-view">
                                                            <i className="fas fa-user" aria-hidden="true"></i>
                                                        </span>
                                                        <h5 className="bold-label mb-4 mt-4">Successful</h5>
                                                        <hr className="hr-light-border mt-2 mb-2 w-25"></hr>
                                                        <div className="pl-2 pr-2">
                                                            <h6 className="bold-label mb-4 line-height-custom">{mailContent}</h6>
                                                            {/* {!emailForLogIn && <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickGetStarted()}><i class="fas fa-hand-point-up"></i> Get Started</button>} */}
                                                            {<button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickOnLogin()}><i class="fas fa-hand-point-up"></i> Login</button>}
                                                        </div>
                                                    </div>}
                                                    {showContent == "User is already verified" &&
                                                        <div>
                                                            <span className="verify-warning-icon-view">
                                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <h5 className="bold-label mb-4 mt-4">{mailContent}</h5>
                                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                                            <div className="pl-2 pr-2">
                                                                {/* <h6 className="status-font mb-4 line-height-custom">{mailContent}</h6> */}
                                                                {/* <h6 className="bold-label mb-4">Or</h6> */}
                                                                {/* <button onClick={resendVefication} className="btn btn-primary btn-md btn-shadow">Resend Verification Link</button> */}
                                                                <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickOnLogin()}><i class="fas fa-hand-point-up"></i> Login</button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {showContent == "Registration link expired" &&
                                                        <div>
                                                            <span className="verify-warning-icon-view">
                                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <h5 className="bold-label mb-4 mt-4">{mailContent}</h5>
                                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                                            <div className="pl-2 pr-2">
                                                                {/* <h6 className="status-font mb-4 line-height-custom">{mailContent}</h6> */}
                                                                {/* <h6 className="bold-label mb-4">Or</h6> */}
                                                                <button onClick={resendVefication} className="btn btn-primary btn-md btn-shadow">Resend Verification Link</button>
                                                                {/* onClick={resendVefication} */}
                                                            </div>
                                                        </div>
                                                    }
                                                    {showContent == "user-verification-failed" &&
                                                        <div>
                                                            <span className="verify-warning-icon-view">
                                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <h5 className="bold-label mb-4 mt-4">{mailContent}</h5>
                                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                                            <div className="pl-2 pr-2">

                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            }

                                            {loading && <div className="card-body p-3 text-center">
                                                <div className="mt-5 mb-5 text-center">
                                                    <ScaleLoader
                                                        css={override}
                                                        // size={60 , 5}
                                                        height={50}
                                                        width={5}
                                                        margin={5}
                                                        color={"#f58b3d"}
                                                        loading={loading}
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                    </CCardBody>
                                </CCard>
                                {/* <h6 className="text-center">Already Have an Account? <Link to="/login" className="text-success bold-label">Login</Link></h6> */}
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
            {showToast &&
                <ToastMessage
                    severity={ToastSeverity}
                    summary={ToastSeverity == "error" ? "Error" : "Success"}
                    detail={responseMsg}
                />
            }
        </>
    )
}


export default VerifyRegistration
