import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from "src/assets/icons/app_logo.png";
import fav from "src/assets/icons/small-logo.png";
import loginimage from "src/assets/icons/login-image.png";
import Logofull from "src/assets/icons/logo-large.png";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useFormik, Formik } from "formik"
import ToastMessage from "src/components/toast-message/ToastMessage";
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogin, emailVerify, initUserProfileData } from 'src/actions/registration';
import axios from "axios";
import { productionUrl } from "src/config/axios.config"
import cookie from 'react-cookies'


import { useLocation } from "react-router-dom";
import { CLEAR_AFER_LOGIN, GET_USER_COMPANIES_IN_LOGIN_SUCCESS } from 'src/actions/actionTypes';

const VerifyEmail = (props) => {
    const location = useLocation();
    const [userEmail,setUserEmail] = useState('to your entered email')
    useEffect(() => {
        const search = location?.search
        const email = search.split('=')[1]
        setUserEmail(email)
     }, []);
    return (
        <>
            <div className="">

                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="7" lg="8" md="12" sm="12">
                                <CCard className="border-0">
                                    <CCardBody className="p-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                <div className="p-2 verify-bg">
                                                    <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center p-5 inside-border-radius">
                                            <span className="verify-icon-view">
                                                <i className="far fa-envelope" aria-hidden="true"></i>
                                            </span>
                                            <h5 className="bold-label mb-4 mt-4">Check Your Email </h5>
                                            <hr className="hr-light-border mt-4 mb-4 w-25"></hr>
                                            <div className="pl-2 pr-2">
                                            {/* <h6 className="bold-label mb-2 line-height-custom">An email with a verification link has been sent to <span className="bold-label text-primary">{userEmail}</span>.</h6> */}
                                            <h6 className="status-font mb-2 line-height-custom">We've sent a message to the email provided with a link to activate your account. This helps us verify your identity.</h6>
                                                {/* <h6 className="bold-label mb-2 line-height-custom">An email with a verification code has been sent to <span className="bold-label text-primary">jhondoe@yopmail.com</span>.</h6> */}
                                                {/* <h6 className="status-font mb-2 mt-2 min-font-size">Please click on the link that has just been sent to your email account to verify your email and continue the registration process.</h6> */}
                                                {/* <button className="btn btn-primary btn-md btn-shadow">Click Here</button> */}
                                                {/* <h6 className="status-font mb-2 mt-2 min-font-size">The code will expire in 10 minitues. If you would like a new code, or you haven't received the email, <a href="#" className="bold-label text-primary">Click Here </a> to send a new code. </h6> */}
                                            </div>    
                                        </div>
                                       
                                    </CCardBody>
                                </CCard>
                                {/* <hr className="hr-light-border mt-5"></hr> */}
                                        {/* <h6 className="text-center">Already Having an Account? <Link to="/login" className="text-success bold-label">Login</Link></h6>   */}
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        </>
    )
}


export default VerifyEmail
