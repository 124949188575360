import { 
  DASHBOARD_FINISH,
  DASHBOARD_INIT,
    DASHBOARD_SUCCESS,
    GET_CURRENT_ASSET_VALUE,
    GET_CURRENT_ASSET_VALUE_SUCCESS,
    GET_CURRENT_LIABILITIES_VALUE,
    GET_CURRENT_LIABILITIES_VALUE_SUCCESS,
    GET_FIXED_ASSET_VALUE,
    GET_FIXED_ASSET_VALUE_SUCCESS,
    GET_PROFIT_AND_LOSS,
    GET_PROFIT_AND_LOSS_FAILURE,
    GET_PROFIT_AND_LOSS_SUCCESS,
    GET_PURCHASE_VALUE,
    GET_PURCHASE_VALUE_SUCCESS,
    GET_SALES_VALUE,
    GET_SALES_VALUE_SUCCESS,
    SET_DASHBOARD_EXTERNAL_SUPPLIER_DETAILS,
    DASHBOARD_FAILED
   } from "src/actions/actionTypes";
import { getList } from "src/util/enums";
  
const initialState = {
    profitAndLoss: {},
    salesValue: {},
    purchaseValue: {},
    fixedAssetValue: {},
    currentAssetValue: {},
    currentLiabilities: {},


    dashboardReqStatus: true,
    dashboardStatus: null,
    dashboardData: {},
    dashboardMessage: null,
    externalSuppierDetails: {},
};
  
  export default function dashBoardReducer(state = initialState, action) {
    switch (action.type) {
      case GET_PROFIT_AND_LOSS_SUCCESS: {
        return { ...state, profitAndLoss: action.payload };
      }
      case GET_PURCHASE_VALUE_SUCCESS: {
        return { ...state, purchaseValue: action.payload };
      }
      case GET_SALES_VALUE_SUCCESS: {
        return { ...state, salesValue: action.payload };
      }
      case GET_FIXED_ASSET_VALUE_SUCCESS: {
        return { ...state, fixedAssetValue: action.payload };
      }
      case GET_CURRENT_ASSET_VALUE_SUCCESS: {
        return { ...state, currentAssetValue: action.payload };
      }
      case GET_CURRENT_LIABILITIES_VALUE_SUCCESS: {
        return { ...state, currentLiabilities: action.payload };
      }
      case GET_PROFIT_AND_LOSS: {
        return { ...state, profitAndLoss: getList.inprogress };
      }
      case GET_PURCHASE_VALUE: {
        return { ...state, purchaseValue: getList.inprogress };
      }
      case GET_SALES_VALUE: {
        return { ...state, salesValue: getList.inprogress };
      }
      case GET_FIXED_ASSET_VALUE: {
        return { ...state, fixedAssetValue: getList.inprogress };
      }
      case GET_CURRENT_ASSET_VALUE: {
        return { ...state, currentAssetValue: getList.inprogress };
      }
      case GET_CURRENT_LIABILITIES_VALUE: {
        return { ...state, currentLiabilities: getList.inprogress };
      }


      case DASHBOARD_INIT: {
        return {
          ...state,
          dashboardReqStatus: true, dashboardData: {}, dashboardMessage: '', };
      }
  
      case DASHBOARD_FINISH: {
        return { ...state, dashboardReqStatus: false };
      }
  
      case DASHBOARD_SUCCESS: { 
        return {
          ...state,
          dashboardStatus: action.payload[0],
          dashboardData: action.payload[1],
          dashboardMessage: action.payload[2],
        };
      }

      case DASHBOARD_FAILED: {
        return {...state,
          dashboardStatus: action.payload[0],          
          dashboardMessage: action.payload[1],        
        }
      }

      case SET_DASHBOARD_EXTERNAL_SUPPLIER_DETAILS: return {
        ...state, externalSuppierDetails : action.payload,
      }

      default:
        return state;
    }
  }
  