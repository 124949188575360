import {FETCH_CATEGORY_SUCCESS, CLEAR_CATEGORY_TRANSACTION, TRANSACTION_FETCH_START, TRANSACTION_FETCH_END, FETCH_SUMMARY_INIT, FETCH_SUMMARY_FINISH, FETCH_SUMMARY_SUCCESS, FETCH_SUMMARY_FAILED} from '../../action-type/Gstr1';

const initialState = {
    fetchingSummary: false,
    fetchingTransaction: false,
    fetchSummarySuccess: null,
    data: null,
    categoryTransactions: null,
};

const Gstr1Reducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_SUMMARY_INIT: 
            return {...state, fetchingSummary: true}

        case FETCH_SUMMARY_FINISH:
            return {...state, fetchingSummary: false}
            
        case FETCH_SUMMARY_SUCCESS:
            return {...state, data: action.payload, fetchSummarySuccess: true}
            
        case FETCH_SUMMARY_FAILED:
            return {...state, data: null, fetchSummarySuccess: false}

        case TRANSACTION_FETCH_START:
            return {...state, fetchingTransaction: true}
            
        case TRANSACTION_FETCH_END:
            return {...state, fetchingTransaction: false}

        case CLEAR_CATEGORY_TRANSACTION:
            return {...state, categoryTransactions: null}

        case FETCH_CATEGORY_SUCCESS:
            return {...state, categoryTransactions: action.payload}
        
        default:
            return state;
    }
};

export default Gstr1Reducer;