export const FETCH_SUMMARY_INIT = "FETCH_SUMMARY_INIT";
export const FETCH_SUMMARY_FINISH = "FETCH_SUMMARY_FINISH";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAILED = "FETCH_SUMMARY_FAILED";

// download status

export const DOWNLOAD_STATUS_INIT = "DOWNLOAD_STATUS_INIT";
export const DOWNLOAD_STATUS_FINISH = "DOWNLOAD_STATUS_FINISH";
export const DOWNLOAD_STATUS_SUCCESS = "DOWNLOAD_STATUS_SUCCESS";
export const DOWNLOAD_STATUS_FAILED = "DOWNLOAD_STATUS_FAILED";


// download status single

export const DOWNLOAD_STATUS_SINGLE_INIT = "DOWNLOAD_STATUS_SINGLE_INIT";
export const DOWNLOAD_STATUS_SINGLE_FINISH = "DOWNLOAD_STATUS_SINGLE_FINISH";
export const DOWNLOAD_STATUS_SINGLE_SUCCESS = "DOWNLOAD_STATUS_SINGLE_SUCCESS";
export const DOWNLOAD_STATUS_SINGLE_FAILED = "DOWNLOAD_STATUS_SINGLE_FAILED";

// summary year and month

export const SET_SUMMARY_YEAR = "SET_SUMMARY_YEAR";
export const SET_SUMMARY_MONTH = "SET_SUMMARY_MONTH";


// month selector

export const FROM_MONTH = "FROM_MONTH";
export const TO_MONTH = "TO_MONTH";


// download period

export const SET_DOWNLOAD_PERIOD = "SET_DOWNLOAD_PERIOD";