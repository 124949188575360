import {
  ATTACHMENT_UPLOAD,
  ATTACHMENT_UPLOAD_FAILURE,
  ATTACHMENT_UPLOAD_SUCCESS,
  GET_ATTACHMENT_LIST,
  GET_ATTACHMENT_LIST_SUCCESS,
  GET_ATTACHMENT_LIST_FAILURE,
  ATTACHMENT_DELETE,
  ATTACHMENT_DELETE_SUCCESS,
  ATTACHMENT_DELETE_FAILURE,
  ATT_INIT_STATUS
} from "src/actions/actionTypes";
import { AttachmentUploadStatus } from "src/util/enums";

const initialState = {
  uploadStatus: AttachmentUploadStatus.NONE,
  attachmentList:[],
  attDeleteStatus:AttachmentUploadStatus.NONE,
};

export default function attachmentsReducer(state = initialState, action) {
  switch (action.type) {
    case ATTACHMENT_UPLOAD: {
      return { ...state, uploadStatus: AttachmentUploadStatus.INPROGRESS };
    }
    case ATTACHMENT_UPLOAD_SUCCESS: {
      return { ...state, uploadStatus: AttachmentUploadStatus.SUCCESS };
    }
    case ATTACHMENT_UPLOAD_FAILURE: {
      return { ...state, uploadStatus: AttachmentUploadStatus.FAILED };
    }
    case GET_ATTACHMENT_LIST_SUCCESS: {
      return { ...state, attachmentList: action.payload };
    }
    case ATTACHMENT_DELETE: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.INPROGRESS};
    }
    case ATTACHMENT_DELETE_SUCCESS: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.SUCCESS };
    }
    case ATTACHMENT_DELETE_FAILURE: {
      return { ...state, attDeleteStatus: AttachmentUploadStatus.FAILED };
    }
    case ATT_INIT_STATUS: {
      return { ...state, uploadStatus: AttachmentUploadStatus.NONE };
    }
    
    default:
      return state;
  }
}
