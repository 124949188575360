import {

    SUPPLIER_INIT,
    SUPPLIER_FINISH,
    SUPPLIER_DATA,
    SUPPLIER_FAILED,

    GSTR2B_SUPPLIER_INIT,
    GSTR2B_SUPPLIER_FINISH,
    GSTR2B_SUPPLIER_SUCCESS,
    GSTR2B_SUPPLIER_FAILED,

    GET_SINGLE_SUPPLIER_INIT,
    GET_SINGLE_SUPPLIER_FINISH,
    GET_SINGLE_SUPPLIER_DATA,
    GET_SINGLE_SUPPLIER_FAILED,

    GET_SUPPLIER_TRANSACTIONS_INIT,
    GET_SUPPLIER_TRANSACTIONS_FINISH,
    GET_SUPPLIER_TRANSACTIONS_DATA,
    GET_SUPPLIER_TRANSACTIONS_FAILED,

    GET_SUPPLIER_EMAIL_HISTORY_INIT,
    GET_SUPPLIER_EMAIL_HISTORY_FINISH,
    GET_SUPPLIER_EMAIL_HISTORY_DATA,
    GET_SUPPLIER_EMAIL_HISTORY_FAILED,

    GET_SUPPLIER_EMAIL_PREVIEW_INIT,
    GET_SUPPLIER_EMAIL_PREVIEW_FINISH,
    GET_SUPPLIER_EMAIL_PREVIEW_DATA,
    GET_SUPPLIER_EMAIL_PREVIEW_FAILED,
    GSTR_PAIR_INIT,
    GSTR_PAIR_FINISH,
    GSTR_PAIR_SUCCESS,
    GSTR_PAIR_FAILED,
    GSTR_UN_PAIR_INIT,
    GSTR_UN_PAIR_FINISH,
    GSTR_UN_PAIR_SUCCESS,
    GSTR_UN_PAIR_FAILED,

} from "src/actions/actionTypes";

const initialState = {
    supplierReqStatus: false,
    supplierData: null,
    SuppplierMessage: '',

    getSingleSupplierReqStatus: false,
    getSingleSupplierData: {},
    getSingleSupplierMessage: '',


    Gstr2BSupplierReqStatus: false,
    Gstr2BSupplierData: null,
    Gstr2BSuppplierMessage: '',

    getSupplierEmailHitoryReqStatus: false,
    getSupplierEmailHitoryData: [],
    getSupplierEmailHitoryMessage: '',

    getSupplierEmailPreviewReqStatus: false,
    getSupplierEmailPreviewData: {},
    getSupplierEmailPreviewMessage: '',

    getSupplierTransactionsReqStatus: false,
    getSupplierTransactionsData: {},
    getSupplierTransactionsMessage: '',

    GstrPairReqStatus: false,
    GstrPairData: null,
    GstrpairMessage: '',

    GstrUnPairReqStatus: false,
    GstrUnPairData: null,
    GstrUnPairMessage: '',

};

export default function supplierReducer(state = initialState, action) {
    switch (action.type) {


        case GSTR2B_SUPPLIER_INIT: {
            return { ...state, Gstr2BSupplierReqStatus: true, Gstr2BSupplierData: null, Gstr2BSuppplierMessage: '' }
        }

        case GSTR2B_SUPPLIER_FINISH: {
            return { ...state, Gstr2BSupplierReqStatus: false }
        }

        case GSTR2B_SUPPLIER_SUCCESS: {
            return { ...state, Gstr2BSupplierData: action.payload }
        }

        case GSTR2B_SUPPLIER_FAILED: {
            return { ...state, Gstr2BSuppplierMessage: action.payload }
        }

        case SUPPLIER_INIT: {
            return { ...state, supplierReqStatus: true, supplierData: null, SuppplierMessage: ''}
        }

        case SUPPLIER_FINISH: {
            return { ...state, supplierReqStatus: false }
        }

        case SUPPLIER_DATA: {
            return { ...state, supplierData: action.payload }
        }

        case SUPPLIER_FAILED: {
            return { ...state, SuppplierMessage: action.payload }
        }

        //supplier 2a 2b purchase register

        case GET_SINGLE_SUPPLIER_INIT: {
            return { ...state, getSingleSupplierReqStatus: true }
        }

        case GET_SINGLE_SUPPLIER_FINISH: {
            return { ...state, getSingleSupplierReqStatus: false }
        }

        case GET_SINGLE_SUPPLIER_DATA: {
            return { ...state, getSingleSupplierData: action.payload }
        }

        case GET_SINGLE_SUPPLIER_FAILED: {
            return { ...state, getSingleSupplierMessage: action.payload }
        }


        case "UPDATE_SUPPLIER_DATA": {
            return { ...state, supplierData: action.payload }
        }

        case "UPDATE_GSTR2B_SUPPLIER_DATA": {
            return { ...state, Gstr2BSupplierData: action.payload }
        }

        case "UPDATE_GET_SINGLE_SUPPLIER_DATA": {
            return { ...state, getSingleSupplierData: action.payload }
        }

        // supplier email history

        case GET_SUPPLIER_EMAIL_HISTORY_INIT: {
            return { ...state, getSupplierEmailHitoryReqStatus: true }
        }

        case GET_SUPPLIER_EMAIL_HISTORY_FINISH: {
            return { ...state, getSupplierEmailHitoryReqStatus: false }
        }

        case GET_SUPPLIER_EMAIL_HISTORY_DATA: {
            return { ...state, getSupplierEmailHitoryData: action.payload }
        }

        case GET_SUPPLIER_EMAIL_HISTORY_FAILED: {
            return { ...state, getSupplierEmailHitoryMessage: action.payload }
        }

         // supplier email preview

         case GET_SUPPLIER_EMAIL_PREVIEW_INIT: {
            return { ...state, getSupplierEmailPreviewReqStatus: true }
        }

        case GET_SUPPLIER_EMAIL_PREVIEW_FINISH: {
            return { ...state, getSupplierEmailPreviewReqStatus: false }
        }

        case GET_SUPPLIER_EMAIL_PREVIEW_DATA: {
            return { ...state, getSupplierEmailPreviewData: action.payload }
        }

        case GET_SUPPLIER_EMAIL_PREVIEW_FAILED: {
            return { ...state, getSupplierEmailPreviewMessage: action.payload }
        }

        // supplier transactions 

        case GET_SUPPLIER_TRANSACTIONS_INIT: {
            return { ...state, getSupplierTransactionsReqStatus: true }
        }

        case GET_SUPPLIER_TRANSACTIONS_FINISH: {
            return { ...state, getSupplierTransactionsReqStatus: false }
        }

        case GET_SUPPLIER_TRANSACTIONS_DATA: {
            return { ...state, getSupplierTransactionsData: action.payload }
        }

        case GET_SUPPLIER_TRANSACTIONS_FAILED: {
            return { ...state, getSupplierTransactionsMessage: action.payload }
        }

        case GSTR_PAIR_INIT: {
            return { ...state, GstrPairReqStatus: true, GstrPairData: null }
        }

        case GSTR_PAIR_FINISH: {
            return { ...state, GstrPairReqStatus: false }
        }

        case GSTR_PAIR_SUCCESS: {
            return { ...state, GstrPairData: action.payload}
        }

        case GSTR_PAIR_FAILED: {
            return { ...state, GstrPairData: action.payload }
        }

        case GSTR_UN_PAIR_INIT: {
            return { ...state, GstrUnPairReqStatus: true, GstrPairData: null }
        }

        case GSTR_UN_PAIR_FINISH: {
            return { ...state, GstrUnPairReqStatus: false }
        }

        case GSTR_UN_PAIR_SUCCESS: {
            return { ...state, GstrUnPairData: action.payload }
        }

        case GSTR_UN_PAIR_FAILED: {
            return { ...state, GstrUnPairMessage: action.payload }
        }
        default:
            return state;
    }
}
