import {
    GET_PAN_OR_GST_NO_SUCCESS,
    GET_USER_COMPANIES_SUCCESS,
    RECONCILE_REASONS_LIST,

    UPDATE_RECONCILE_REASONS_INIT,
    UPDATE_RECONCILE_REASONS_FINISH,
    UPDATE_RECONCILE_REASONS_SUCCESS,
    UPDATE_RECONCILE_REASONS_FAILED,

} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
   panOrGst: "",
   userCompanies: [],
   reconciliationReasonsList: [],

   updateReasonReqStatus: false,
   updateReasonData: [],
   updateReasonStatus: null,

   anyDeskReqStatus: false,
   anyDeskSuccess: false,
};


export default function miscReducer(state = initialState, action) {
    switch (action.type) {

        case "ANY_DESK_SUPPORT_INTI": {
            return {...state , anyDeskReqStatus: true}
        }
        
        case "ANY_DESK_SUPPORT_FINISH": {
            return {...state , anyDeskReqStatus: false}
        }

        case "ANY_DESK_SUPPORT_SUCCESS": {
            return {...state, anyDeskSuccess: action.payload}
        }

        //// update reconcile reasons starts here..

        case UPDATE_RECONCILE_REASONS_INIT: {
            return {...state, updateReasonReqStatus: true, updateReasonData: [], updateReasonStatus: null}
        }

        case UPDATE_RECONCILE_REASONS_FINISH: {
            return {...state, updateReasonReqStatus: false}
        }

        case UPDATE_RECONCILE_REASONS_SUCCESS: {
            return {... state, updateReasonStatus: action.payload[0], updateReasonData: action.payload[1]}
        }

        case UPDATE_RECONCILE_REASONS_FAILED: {
            return {...state, updateReasonStatus: action.payload}
        }
        //// update reconcile reasons ends here..

        case RECONCILE_REASONS_LIST: {
            return {...state, reconciliationReasonsList: action.payload}
        }

        case GET_PAN_OR_GST_NO_SUCCESS: {
            return { ...state, panOrGst: action.payload };
        }
        case GET_USER_COMPANIES_SUCCESS: {
            return { ...state, userCompanies: action.payload };
        }
        default:
            return state;
    }
}
