import {DOWNLOAD_STATUS_INIT, DOWNLOAD_STATUS_FINISH, DOWNLOAD_STATUS_SUCCESS, DOWNLOAD_SINGLE_INIT, DOWNLOAD_SINGLE_FINISH, DOWNLOAD_SINGLE_SUCCESS, TO_MONTH, FROM_MONTH, SET_DOWNLOAD_PERIOD} from 'src/action-type/Gstr1';

const initialState = {
    isFetching: false,
    singleFetching: false,
    singleDownloadStatus: null,
    data: null,
    fromMonth: null,
    toMonth: null,
    downloadPeriod: null,
};

function DownloaStatusReducer(state=initialState, action) {
    switch(action.type) {
        case DOWNLOAD_STATUS_INIT:
            return {...state, isFetching: true}

        case DOWNLOAD_STATUS_FINISH:
            return {...state, isFetching: action.payload}

        case DOWNLOAD_STATUS_SUCCESS:
            return {...state, data: action.payload}

        case DOWNLOAD_SINGLE_INIT:
            return {...state, singleFetching: true}

        case DOWNLOAD_SINGLE_FINISH:
            return {...state, singleFetching: action.payload}
        
        case DOWNLOAD_SINGLE_SUCCESS:
            return {...state, singleDownloadStatus: action.payload}

        case FROM_MONTH:
            return {...state, fromMonth: action.payload}

        case TO_MONTH:
            return {...state, toMonth: action.payload}

        case SET_DOWNLOAD_PERIOD:
            return {...state, downloadPeriod: action.payload}

        default:
            return state;
    }
};

export default DownloaStatusReducer;