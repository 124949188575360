import { tallyOrgCompaniesAxios, productionUrl, tallyCompaniesListAxios, productionUrlForLogin, Gstr2aAxios, urlWithoutAxios } from "src/config/axios.config";
import axios from "axios";
import {
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  SAVE_USER_DEFAULT_STATUS,
  SAVE_ORGANISATION,
  SAVE_ORGANISATION_SUCCESS,
  SAVE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_ORGANISATION_BY_ORG_ID,
  GET_ORGANISATION_BY_ORG_ID_SUCCESS,
  GET_ORGANISATION_BY_ORG_ID_FAILURE,
  GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS,
  GET_ORGANISATION_BY_ORG_OWNER_ID,
  GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
  GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE,
  GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS,

  EMAIL_TOKEN,
  EMAIL_TOKEN_SUCCESS,
  EMAIL_TOKEN_FAILURE,
  REQ_RESET_EMAIL,
  REQ_RESET_EMAIL_SUCCESS,
  REQ_RESET_EMAIL_ERROR,

  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_ERROR,

  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,

  USER_EMAIl_EXISTS,
  USER_EMAIl_EXISTS_SUCCESS,
  USER_EMAIl_EXISTS_ERROR,

  GET_PROFILES,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_ERROR,

  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,

  ASSIGN_USERS,
  ASSIGN_USERS_SUCCESS,
  ASSIGN_USERS_ERROR,


  GET_USER_COMPANIES_IN_LOGIN,
  GET_USER_COMPANIES_IN_LOGIN_SUCCESS,
  GET_USER_COMPANIES_IN_LOGIN_FAILURE,

  GET_COMPANY_PROFILE_INFO,
  GET_COMPANY_PROFILE_INFO_SUCCESS,
  GET_COMPANY_PROFILE_INFO_ERROR,

  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,

  INIT_USER_PROFILE_DATA,
  INIT_USER_PROFILE_DATA_SUCCESS,
  INIT_USER_PROFILE_DATA_ERROR,

  UPDATE_USER_PROFILE_DATA,
  UPDATE_USER_PROFILE_DATA_SUCCESS,
  UPDATE_USER_PROFILE_DATA_ERROR,

  GET_INVITED_USERS,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_ERROR,

  GET_MY_TALLY_COMPANIES,
  GET_MY_TALLY_COMPANIES_SUCCESS,
  GET_MY_TALLY_COMPANIES_ERROR,
  GET_BOOK_START_AND_END_DATE,
  GET_BOOK_START_AND_END_DATE_SUCCESS,
  GET_BOOK_START_AND_END_DATE_FAILURE,
  SAVE_SOCIAL_AUTH_DATA,
  SAVE_SOCIAL_AUTH_DATA_SUCCESS,
  SAVE_SOCIAL_AUTH_DATA_FAILURE,
  RESEND_VERIFICATION_LINK,
  RESEND_VERIFICATION_LINK_SUCCESS,
  RESEND_VERIFICATION_LINK_FAILURE,
  APPLE_SIGN_IN_FAILED,
  RESEND_INVITATION_LINK,
  RESEND_INVITATION_LINK_SUCCESS,
  RESEND_INVITATION_LINK_FAILURE,
  USER_VERIFICATION,
  USER_VERIFICATION_SUCCESS,
  USER_VERIFICATION_FAILURE,
  VERIFY_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  SUBMIT_RESET_PASSWORD_DATA,
  SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
  SUBMIT_RESET_PASSWORD_DATA_FAILURE,
  REGISTER_INVITEE,
  REGISTER_INVITEE_SUCCESS,
  REGISTER_INVITEE_FAILURE,
  DELETE_ASSIGN_USERS,
  DELETE_ASSIGN_USERS_SUCCESS,
  DELETE_ASSIGN_USERS_FAILURE,

} from "../actionTypes";
import Axios from "axios";
import { globalApiUrl } from "src/util/globalUrls";
// const dispatch = useDispatch()

const saveUser = (data) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: SAVE_USER,
  });

  return tallyOrgCompaniesAxios.post('registration/saveUser', data).then(
    (res) =>
      dispatch({
        type: SAVE_USER_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: SAVE_USER_FAILURE,
        payload: err,
      })
  );
};
const saveOrganisation = (formData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: SAVE_ORGANISATION,
  });
  return tallyOrgCompaniesAxios.post('registration/insert/organaisation', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then(
    (res) =>
      dispatch({
        type: SAVE_ORGANISATION_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: SAVE_ORGANISATION_FAILURE,
        payload: err,
      })
  );
};
const updateOrganisation = (formData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: UPDATE_ORGANISATION,
  });
  return tallyOrgCompaniesAxios.post('registration/update/organaisation', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then(
    (res) =>
      dispatch({
        type: UPDATE_ORGANISATION_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_ORGANISATION_FAILURE,
        payload: err,
      })
  );
};

// const userLogin = (email,password) => (dispatch) => {
//   // Intitial Dispatch // can be used to show loader
//   dispatch({
//     type: USER_LOGIN,
//   });

//   return productionUrlForLogin.post(`api/user_login/`, {"email":email,"password":password}).then(

//     (res) =>
//           dispatch({
//         type: USER_LOGIN_SUCCESS,
//         payload: res.data,
//       }),
//     (err) =>
//       dispatch({
//         type: USER_LOGIN_FAILURE,
//         payload: err,
//       })
//   );
// };



const userLogin = (email, password) => (dispatch) => {
  dispatch({
    type: USER_LOGIN,
  });

  return Gstr2aAxios.post(`user_login/`, { "email": email, "password": password }).then(

    (res) =>
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: USER_LOGIN_FAILURE,
        payload: err,
      })
  );
};


const emailVerify = (token) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: EMAIL_TOKEN,
  });

  return productionUrl.post('api/email_verify/', token).then(
    (res) => {
      dispatch({
        type: EMAIL_TOKEN_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: EMAIL_TOKEN_FAILURE,
        payload: err,
      })
  );
};




const reqResetEmail = (userEmailData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: REQ_RESET_EMAIL,
  });

  return Gstr2aAxios.post('reset_password_email/', userEmailData).then(
    (res) => {
      dispatch({
        type: REQ_RESET_EMAIL_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: REQ_RESET_EMAIL_ERROR,
        payload: err,
      })
  );
};



const passwordResetComplete = (confirmPasswordDetails) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: CONFIRM_PASSWORD,
  });

  return productionUrl.post('api/password_reset_complete/', confirmPasswordDetails).then(
    (res) => {
      dispatch({
        type: CONFIRM_PASSWORD_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: CONFIRM_PASSWORD_ERROR,
        payload: err,
      })
  );
};


const userRegistration = (userDetails) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: USER_REGISTER,
  });

  return Gstr2aAxios.post('user_registration/', userDetails).then(
    (res) => {
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_REGISTER_ERROR,
        payload: err,
      })
  );
};




const checkEmailExists = (checkUserDetailsData) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: USER_EMAIl_EXISTS,
  });

  return productionUrl.post('api/checkUserDetails/', checkUserDetailsData).then(
    (res) => {
      dispatch({
        type: USER_EMAIl_EXISTS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_EMAIl_EXISTS_ERROR,
        payload: err,
      })
  );
};

const getProfiles = () => (dispatch) => {
  dispatch({
    type: GET_PROFILES,
  });

  return productionUrl.get('api/getProfiles/').then(
    (res) => {
      dispatch({
        type: GET_PROFILES_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: GET_PROFILES_ERROR,
        payload: err,
      })
  );
};



const getRoles = () => (dispatch) => {
  dispatch({
    type: GET_ROLES,
  });

  return Gstr2aAxios.get('get_roles/').then(
    (res) => {
      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: GET_ROLES_ERROR,
        payload: err,
      })
  );
};


const assignToUser = (userInvitationData) => (dispatch) => {
  dispatch({
    type: ASSIGN_USERS,
  });

  return Gstr2aAxios.post('user_invitation/', userInvitationData).then(
    (res) => {
      dispatch({
        type: ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: ASSIGN_USERS_ERROR,
        payload: err,
      })
  );
};

const assignToUserUpdate = (userInvitationData) => (dispatch) => {
  dispatch({
    type: ASSIGN_USERS,
  });

  return Gstr2aAxios.post('update_gst_company_user/', userInvitationData).then(
    (res) => {
      dispatch({
        type: ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: ASSIGN_USERS_ERROR,
        payload: err,
      })
  );
};





const getOrganisationById = (id) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: GET_ORGANISATION_BY_ORG_ID,
  });

  return tallyOrgCompaniesAxios.post(`registration/getOrganisationDetailsByOrgId?orgId=${id}`, {}).then(
    (res) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_ID_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_ID_FAILURE,
        payload: err,
      })
  );
};

const getOrganisationsListByOwnerId = (id) => (dispatch) => {
  // Intitial Dispatch // can be used to show loader
  dispatch({
    type: GET_ORGANISATION_BY_ORG_OWNER_ID,
  });

  return tallyOrgCompaniesAxios.post(`registration/getOrganisationListByOwnerId?ownerId=b1d8d885-705f-4c0d-9ac4-b95df5077c14`, {}).then(
    (res) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE,
        payload: err,
      })
  );


};

const getCompanyProfileInfo = (tc_uuid) => (dispatch) => {
  dispatch({
    type: GET_COMPANY_PROFILE_INFO,
  });

  return productionUrl.post('api/getCompanyProfileInfo/', tc_uuid).then(
    (res) =>

      dispatch({
        type: GET_COMPANY_PROFILE_INFO_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_COMPANY_PROFILE_INFO_ERROR,
        payload: err,
      })
  );
};

const updateProfilePassword = (updatePassParam) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD,
  });

  return productionUrl.post('api/update_user_password/', updatePassParam).then(

    (res) =>
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: err,
      })
  );
};

const initUserProfileData = (uuid) => (dispatch) => {
  dispatch({
    type: INIT_USER_PROFILE_DATA,
  });

  return productionUrl.post('api/get_user_profile_info/', uuid).then(


    (res) =>
      dispatch({
        type: INIT_USER_PROFILE_DATA_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: INIT_USER_PROFILE_DATA_ERROR,
        payload: err,
      })
  );
};

const updateUserProfileData = (formdata) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_PROFILE_DATA,
  });

  return productionUrl.post('api/update_user_profile_info/', formdata).then(

    (res) =>
      dispatch({
        type: UPDATE_USER_PROFILE_DATA_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: UPDATE_USER_PROFILE_DATA_ERROR,
        payload: err,
      })
  );
};


const getInvitedUsers = (uuid) => (dispatch) => {
  dispatch({
    type: GET_INVITED_USERS,
  });

  return Gstr2aAxios.post('get_gst_company_users/', uuid).then(

    (res) =>
      dispatch({
        type: GET_INVITED_USERS_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_INVITED_USERS_ERROR,
        payload: err,
      })
  );
};

const deleteInvitedActiveUser = (qdata) => (dispatch) => {
  dispatch({
    type: DELETE_ASSIGN_USERS,
  });
  return axios({
    method: 'delete',
    url: urlWithoutAxios + `delete_gst_company_user/`,
    data: qdata,
  }).then(
    (res) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_FAILURE,
        payload: err,
      })
    }
  )
};

const deleteInvitation = (qdata) => (dispatch) => {
  dispatch({
    type: DELETE_ASSIGN_USERS,
  });
  return axios({
    method: 'delete',
    url: urlWithoutAxios + `delete_invitation/`,
    data: qdata,
  }).then(
    (res) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_SUCCESS,
        payload: res.data,
      })
    },
    (err) => {
      dispatch({
        type: DELETE_ASSIGN_USERS_FAILURE,
        payload: err,
      })
    }
  )
};
const getOrganisationsListByUserId = (userId) => (dispatch) => {
  dispatch({
    type: GET_MY_TALLY_COMPANIES,
  });

  return tallyCompaniesListAxios.get(`tallyuserorgcompanies/tally/companies/getTallyCompaniesByuserId?userId=${userId}`).then(

    (res) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_SUCCESS,
        payload: res.data,
      }),
    (err) =>
      dispatch({
        type: GET_MY_TALLY_COMPANIES_ERROR,
        payload: err,
      })
  );
};

const saveSocialAuthData = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SOCIAL_AUTH_DATA,
  });
  return Gstr2aAxios.post(`social_auth_login/`, data).then(

    (res) => {
      dispatch({
        type: SAVE_SOCIAL_AUTH_DATA_SUCCESS,
        payload: res.data,
      })

      
    },
    (err) =>
      dispatch({
        type: SAVE_SOCIAL_AUTH_DATA_FAILURE,
        payload: err,
      })
  );
};

const appleAuth = (params) => {
  return (dispatch) => {
    return axios({
      method: 'POST',
      url: globalApiUrl + 'finsightsgst/api/apple_login/',
      data: params,
    }).then(res => res)
      .then(response => {
        if (response.data.loginValidateStatus) {
          dispatch({
            type: SAVE_SOCIAL_AUTH_DATA_SUCCESS,
            payload: response.data,
          })
        } else {
          dispatch({ type: APPLE_SIGN_IN_FAILED, payload: response.data.response })
        }
      })
  }
};

const resendVeificatinLink = (data) => (dispatch) => {
  dispatch({
    type: RESEND_VERIFICATION_LINK,
  });

  return Gstr2aAxios.post('resend_verification_link/', data).then(
    (res) => {
      dispatch({
        type: RESEND_VERIFICATION_LINK_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: RESEND_VERIFICATION_LINK_FAILURE,
        payload: err,
      })
  );
};

const resendEmailForVerification = (data) => (dispatch) => {
  dispatch({
    type: RESEND_INVITATION_LINK,
  });

  return Gstr2aAxios.post('resend_verification_link/', data).then(
    (res) => {
      dispatch({
        type: RESEND_INVITATION_LINK_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: RESEND_INVITATION_LINK_FAILURE,
        payload: err,
      })
  );
};

const verifyRegisteringUser = (token) => (dispatch) => {
  dispatch({
    type: USER_VERIFICATION,
  });

  return Gstr2aAxios.post('verify_registration/', token).then(
    (res) => {
      dispatch({
        type: USER_VERIFICATION_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_VERIFICATION_FAILURE,
        payload: err,
      })
  );
};

const verifyRestPassword = (data) => (dispatch) => {
  dispatch({
    type: VERIFY_RESET_PASSWORD,
  });

  return Gstr2aAxios.post('verify_reset_password/', data).then(
    (res) => {
      dispatch({
        type: VERIFY_RESET_PASSWORD_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: VERIFY_RESET_PASSWORD_FAILURE,
        payload: err,
      })
  );
};

const submitResetPassword = (data) => (dispatch) => {
  dispatch({
    type: SUBMIT_RESET_PASSWORD_DATA,
  });

  return Gstr2aAxios.post('reset_password/', data).then(
    (res) => {
      dispatch({
        type: SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: SUBMIT_RESET_PASSWORD_DATA_FAILURE,
        payload: err,
      })
  );
};

const registerUnRegisteredUser = (data) => (dispatch) => {
  dispatch({
    type: REGISTER_INVITEE,
  });

  return Gstr2aAxios.post('register_invitee/', data).then(
    (res) => {
      dispatch({
        type: REGISTER_INVITEE_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: REGISTER_INVITEE_FAILURE,
        payload: err,
      })
  );
};

const verifyUser = (token) => (dispatch) => {
  dispatch({
    type: USER_VERIFICATION,
  });

  return Gstr2aAxios.post('verify_invitation/', token).then(
    (res) => {
      dispatch({
        type: USER_VERIFICATION_SUCCESS,
        payload: res.data,
      })
    },
    (err) =>
      dispatch({
        type: USER_VERIFICATION_FAILURE,
        payload: err,
      })
  );
};


export {
  getOrganisationsListByUserId,
  getInvitedUsers,
  updateUserProfileData,
  initUserProfileData,
  updateProfilePassword,
  getCompanyProfileInfo,
  getProfiles,
  assignToUser,
  getRoles,
  passwordResetComplete,
  checkEmailExists,
  userRegistration,
  saveUser,
  saveOrganisation,
  userLogin,
  getOrganisationById,
  getOrganisationsListByOwnerId,
  updateOrganisation,
  emailVerify,
  reqResetEmail,
  saveSocialAuthData,
  appleAuth,
  resendVeificatinLink,
  resendEmailForVerification,
  verifyRegisteringUser,
  verifyRestPassword,
  submitResetPassword,
  assignToUserUpdate,
  registerUnRegisteredUser,
  verifyUser,
  deleteInvitedActiveUser,
  deleteInvitation
};
