import {
  GET_ALL_VOUCHERS_SUCCESS,
  GET_QUESTION_TRANSACTIONS_LIST_SUCCESS,
  GET_SELECTED_VOUCHER_DETAILS_SUCCESS,
  SAVE_VOUCHER_REMARKS,
  SAVE_VOUCHER_REMARKS_SUCCESS,
  SAVE_VOUCHER_REMARKS_FAILURE,
  GET_VOUCHERS_BETWEEN_DATES,
  GET_VOUCHERS_BETWEEN_DATES_SUCCESS,
  VOUCHER_REMARKS_DEFAULT,
  GET_VOUCHER_REMARKS_BY_ID_SUCCESS,
  GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS,
  GET_TRANSACTIONS_BY_COMBINATION_SUCCESS,
  SET_FILTERED_JSON,
  RESET_FILTERED_JSON,
  GET_TRANSACTIONS_TYPES_SUCCESS,
  GET_REGISTERS_BY_TYPE_AND_DATES,
  GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS,
  GET_ALL_VOUCHERS,
  GET_EXPENSES_BY_AMOUNTS_SUCCESS,
  GET_EXPENSES_THIS_MONTH_SUCCESS,
  GET_ALL_VOUCHERS_BY_USER,
  GET_ALL_VOUCHERS_BY_USER_SUCCESS,
  GET_PAYMENT_OR_RECIPT_DATA_SUCCESS,
  GET_JOURNAL_DATA_SUCCESS,
  GET_PAYMENT_OR_RECIPT_DATA
} from "src/actions/actionTypes";

import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
  vouchers: [],
  selectedVoucherId: -1,
  selectedVoucherDetails: {},
  questionTransactions: [],
  saveRemarksStatus: InsertFunctionStatus.NONE,
  selectedVoucherRemarks: {},
  vouchersBetweendates: [],
  vouchersByAmount: [],
  vouchersByCombination: [],
  filteredJson: {},
  transactionTypes: [],
  registersList: [],
  expensesByAmount:[],
  expensesByThisMonth:[],
  vouchersByUser: [],
  paymentOrReceiptData:{},
  journalData:{}
};

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VOUCHERS: {
      return { ...state, vouchers: getList.inprogress };
    }
    case GET_ALL_VOUCHERS_SUCCESS: {
      return { ...state, vouchers: action.payload };
    }
    case GET_SELECTED_VOUCHER_DETAILS_SUCCESS: {
      return { ...state, selectedVoucherDetails: action.payload };
    }
    case GET_QUESTION_TRANSACTIONS_LIST_SUCCESS: {
      return { ...state, questionTransactions: action.payload };
    }
    case SAVE_VOUCHER_REMARKS: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.INPROGRESS };
    }
    case SAVE_VOUCHER_REMARKS_SUCCESS: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.SUCCESS };
    }
    case SAVE_VOUCHER_REMARKS_FAILURE: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.FAILED };
    }
    case GET_VOUCHER_REMARKS_BY_ID_SUCCESS: {
      return { ...state, selectedVoucherRemarks: action.payload };
    }
    case GET_VOUCHERS_BETWEEN_DATES: {
      return { ...state, vouchers: getList.inprogress };
    }
    case GET_VOUCHERS_BETWEEN_DATES_SUCCESS: {
      return { ...state, vouchers: action.payload };
    }
    case VOUCHER_REMARKS_DEFAULT: {
      return { ...state, saveRemarksStatus: InsertFunctionStatus.NONE };
    }
    case GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS: {
      return { ...state, vouchersByAmount: action.payload };
    }
    case GET_TRANSACTIONS_BY_COMBINATION_SUCCESS: {
      return { ...state, vouchersByCombination: action.payload };
    }
    case SET_FILTERED_JSON: {
      return { ...state, filteredJson: action.payload };
    }
    case RESET_FILTERED_JSON: {
      return { ...state, filteredJson: {} };
    }
    case GET_TRANSACTIONS_TYPES_SUCCESS: {
      return { ...state, transactionTypes: action.payload };
    }
    case GET_REGISTERS_BY_TYPE_AND_DATES: {
      return { ...state, registersList: getList.inprogress };
    }
    case GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS: {
      return { ...state, registersList: action.payload };
    }
    case GET_EXPENSES_BY_AMOUNTS_SUCCESS: {
      return { ...state, expensesByAmount: action.payload };
    }
    case GET_EXPENSES_THIS_MONTH_SUCCESS: {
      return { ...state, expensesByThisMonth: action.payload };
    }
    case GET_ALL_VOUCHERS_BY_USER: {
      return { ...state, vouchersByUser: getList.inprogress };
    }
    case GET_ALL_VOUCHERS_BY_USER_SUCCESS: {
      return { ...state, vouchersByUser: action.payload };
    }
    case GET_PAYMENT_OR_RECIPT_DATA: {
      return { ...state, paymentOrReceiptData: getList.inprogress };
    }
    case GET_PAYMENT_OR_RECIPT_DATA_SUCCESS: {
      return { ...state, paymentOrReceiptData: action.payload };
    }
    case GET_JOURNAL_DATA_SUCCESS: {
      return { ...state, journalData: action.payload };
    }
    default:
      return state;
  }
}
