import {
    GET_USER_COMPANIES_IN_LOGIN,
    GET_USER_COMPANIES_IN_LOGIN_SUCCESS,
    GET_USER_COMPANIES_IN_LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    STORE_TANSACTION_DATA,
    SELECTED_THEME,
    SELECTED_GSTIN_LIST_STORE
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus, loginInprogressStatus } from "src/util/enums";

const initialState = {
    loginUserData: [],
    transactIonData: {},
    selectedTheme: null,
    selectedGstnList:null
};

export default function userReduser(state = initialState, action) {
    switch (action.type) {
        case GET_USER_COMPANIES_IN_LOGIN_SUCCESS: {
            return { ...state, loginUserData: action.payload };
        }case LOGOUT_SUCCESS: {
            return { ...state, loginUserData: action.payload };
        }case STORE_TANSACTION_DATA: {
            return { ...state, transactIonData: action.payload };
        }case SELECTED_THEME: {
            return { ...state, selectedTheme: action.payload };
        }case SELECTED_GSTIN_LIST_STORE: {
            return { ...state, selectedGstnList: action.payload };
        }
        
        default:
            return state;
    }
}

