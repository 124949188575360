import {
    GET_STOCK_ITEMS,
    GET_STOCK_ITEMS_SUCCESS,
    GET_STOCK_GROUPS,
    GET_STOCK_GROUPS_SUCCESS,
    GET_STOCK_VOUCHERS,
    GET_STOCK_VOUCHERS_SUCCESS,
    GET_GODOWN,
    GET_GODOWN_SUCCESS,
    GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
    stockItems: [],
    stockGroups: [],
    stockVoucherList: [],
    godownList: [],
    stockItemListByTypeId:[]
};

export default function invenoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STOCK_ITEMS : {
            return { ...state, stockItems: getList.inprogress };
        }
        case GET_STOCK_ITEMS_SUCCESS : {
            return { ...state, stockItems: action.payload };
        }
        case GET_STOCK_GROUPS : {
            return { ...state, stockGroups: getList.inprogress };
        }
        case GET_STOCK_GROUPS_SUCCESS : {
            return { ...state, stockGroups: action.payload };
        }
        case GET_STOCK_VOUCHERS : {
            return { ...state, stockVoucherList: getList.inprogress};
        }
        case GET_STOCK_VOUCHERS_SUCCESS : {
            return { ...state, stockVoucherList: action.payload};
        }
        case GET_GODOWN : {
            return { ...state, godownList: getList.inprogress};
        }
        case GET_GODOWN_SUCCESS : {
            return { ...state, godownList: action.payload};
        }case GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS : {
            return { ...state, stockItemListByTypeId: action.payload};
        }
        default:
            return state;
    }
}

