import {
    SAVE_QUERY,
    SAVE_QUERY_SUCCESS,
    SAVE_QUERY_FAILURE,
    GET_QUERY_BY_TYPE_SUCCESS,
    QUERY_SAVE_DEFAULT,
    GET_QUERY_COUNT_INFO_SUCCESS,
    GET_QUERIES_BY_QUERY_GROUP_SUCCESS,
    GET_QUERY_GROUP_LIST,
    GET_QUERY_GROUP_LIST_SUCCESS,
    SAVE_MESSAGE,
    SAVE_MESSAGE_SUCCESS,
    SAVE_MESSAGE_FAILURE,
    GET_QUERY_DATA_SUCCESS,
    GET_QUERY_COUNT_INFO
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
    querySaveStatus: InsertFunctionStatus.NONE,
    queriesList: [],
    queryCountInfo: [],
    queriesListByGroup: [],
    queriesGropList: [],
    chatStatus: InsertFunctionStatus.NONE,
    queryData:[]
};

export default function queryReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_QUERY: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case SAVE_QUERY_SUCCESS: {
            return { ...state, querySaveStatus: action.payload };
        }
        case SAVE_QUERY_FAILURE: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_QUERY_BY_TYPE_SUCCESS: {
            return { ...state, queriesList: action.payload };
        }
        case QUERY_SAVE_DEFAULT: {
            return { ...state, querySaveStatus: InsertFunctionStatus.NONE };
        }
        case GET_QUERY_COUNT_INFO: {
            return { ...state, queryCountInfo: getList.inprogress };
        }
        case GET_QUERY_COUNT_INFO_SUCCESS: {
            return { ...state, queryCountInfo: action.payload };
        }
        case GET_QUERIES_BY_QUERY_GROUP_SUCCESS: {
            return { ...state, queriesListByGroup: action.payload };
        }
        case GET_QUERY_GROUP_LIST_SUCCESS: {
            return { ...state, queriesGropList: action.payload };
        }
        case GET_QUERY_DATA_SUCCESS: {
            return { ...state, queryData: action.payload };
        }
        default:
            return state;
    }
}
