export const FETCH_MATCHED_QUESTIONS = "FETCH_MATCHED_QUESTIONS";
export const FETCH_MATCHED_QUESTIONS_SUCCESS =
  "FETCH_MATCHED_QUESTIONS_SUCCESS";
export const FETCH_MATCHED_QUESTIONS_FAILURE =
  "FETCH_MATCHED_QUESTIONS_FAILURE";

export const QUESTION_SELECTED = "QUESTION_SELECTED";
export const QUESTION_RESET = "QUESTION_RESET";

export const FETCH_ALL_LEDGER_TRANSACTIONS = "FETCH_ALL_LEDGER_TRANSACTIONS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_SUCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_DATA =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA";
export const FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_DATA_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_DATA_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_TRANSACTION_TYPE_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_MONTH_FAILURE";

export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_SUCCESS";
export const FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_FAILURE =
  "FETCH_AGAINST_LEDGER_GROUPING_BY_DATE_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BETWEEN_DATES_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_VOUCHER_ID_FAILURE";

export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_SUCCESS";
export const FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_FAILURE =
  "FETCH_ALL_LEDGER_TRANSACTIONS_BY_TRANSACTION_TYPE_AND_BETWEEN_DATES_FAILURE";

export const TOGGLE_LEDGER_TRANSACTION_AGGR_CHART =
  "TOGGLE_LEDGER_TRANSACTION_AGGR_CHART";

export const GET_BANK_LEDGER_CLOSING_BALANCES =
  "GET_BANK_LEDGER_CLOSING_BALANCES";
export const GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_SUCCESS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_FAILURE =
  "GET_BANK_LEDGER_CLOSING_BALANCES_FAILURE";

export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_SUCCESS";
export const GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_FAILURE =
  "GET_BANK_LEDGER_CLOSING_BALANCES_LAST_30_DAYS_FAILURE";

export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY";
export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_SUCCESS";
export const GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_FAILURE =
  "GET_BANK_LEDGERS_PAYMENT_RECEIPT_SUMMARY_FAILURE";

export const GET_GROUP_LEDGER_TRANSACTIONS = "GET_GROUP_LEDGER_TRANSACTIONS";
export const GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS =
  "GET_GROUP_LEDGER_TRANSACTIONS_SUCCESS";
export const GET_GROUP_LEDGER_TRANSACTIONS_FAILURE =
  "GET_GROUP_LEDGER_TRANSACTIONS_FAILURE";

//#@ VOUCHER REGION STARTS

export const GET_ALL_VOUCHERS = "GET_ALL_VOUCHERS";
export const GET_ALL_VOUCHERS_SUCCESS = "GET_ALL_VOUCHERS_SUCCESS";
export const GET_ALL_VOUCHERS_FAILURE = "GET_ALL_VOUCHERS_FAILURE";

export const GET_VOUCHERS_BETWEEN_DATES = "GET_VOUCHERS_BETWEEN_DATES";
export const GET_VOUCHERS_BETWEEN_DATES_SUCCESS =
  "GET_VOUCHERS_BETWEEN_DATES_SUCCESS";
export const GET_VOUCHERS_BETWEEN_DATES_FAILURE =
  "GET_VOUCHERS_BETWEEN_DATES_FAILURE";

export const GET_SELECTED_VOUCHER_DETAILS = "GET_SELECTED_VOUCHER_DETAILS";
export const GET_SELECTED_VOUCHER_DETAILS_SUCCESS =
  "GET_SELECTED_VOUCHER_DETAILS_SUCCESS";
export const GET_SELECTED_VOUCHER_DETAILS_FAILURE =
  "GET_SELECTED_VOUCHER_DETAILS_FAILURE";

export const SAVE_VOUCHER_REMARKS = "SAVE_VOUCHER_REMARKS";
export const SAVE_VOUCHER_REMARKS_SUCCESS = "SAVE_VOUCHER_REMARKS_SUCCESS";
export const SAVE_VOUCHER_REMARKS_FAILURE = "SAVE_VOUCHER_REMARKS_FAILURE";
export const VOUCHER_REMARKS_DEFAULT = "VOUCHER_REMARKS_DEFAULT";

export const GET_VOUCHER_REMARKS_BY_ID = "GET_VOUCHER_REMARKS_BY_ID";
export const GET_VOUCHER_REMARKS_BY_ID_SUCCESS = "GET_VOUCHER_REMARKS_BY_ID";
export const GET_VOUCHER_REMARKS_BY_ID_FAILURE =
  "GET_VOUCHER_REMARKS_BY_ID_FAILURE";

export const GET_ALL_VOUCHERS_BY_USER = "GET_ALL_VOUCHERS_BY_USER";
export const GET_ALL_VOUCHERS_BY_USER_SUCCESS =
  "GET_ALL_VOUCHERS_BY_USER_SUCCESS";
export const GET_ALL_VOUCHERS_BY_USER_FAILURE =
  "GET_ALL_VOUCHERS_BY_USER_FAILURE";

//#@ VOUCHER REGION ENDS

export const GET_LEDGERS_LIST = "GET_LEDGERS_LIST";
export const GET_LEDGERS_LIST_SUCCESS = "GET_LEDGERS_LIST_SUCCESS";
export const GET_LEDGERS_LIST_FAILURE = "GET_LEDGERS_LIST_FAILURE";

export const GET_LEDGERS_LIST_REPORT = "GET_LEDGERS_LIST_REPORT";
export const GET_LEDGERS_LIST_REPORT_SUCCESS =
  "GET_LEDGERS_LIST_REPORT_SUCCESS";
export const GET_LEDGERS_LIST_REPORT_FAILURE =
  "GET_LEDGERS_LIST_REPORT_FAILURE";

export const GET_LEDGERS_BY_TYPES = "GET_LEDGERS_BY_TYPES";
export const GET_LEDGERS_BY_TYPES_SUCCESS = "GET_LEDGERS_BY_TYPES_SUCCESS";
export const GET_LEDGERS_BY_TYPES_FAILURE = "GET_LEDGERS_BY_TYPES_FAILURE";

export const GET_LEDGERS_BY_USERS = "GET_LEDGERS_BY_USERS";
export const GET_LEDGERS_BY_USERS_SUCCESS = "GET_LEDGERS_BY_USERS_SUCCESS";
export const GET_LEDGERS_BY_USERS_FAILURE = "GET_LEDGERS_BY_USERS_FAILURE";
export const GET_LEDGERS_BY_USERS_INPROGESS = "GET_LEDGERS_BY_USERS_INPROGESS";

export const GET_LEDGERS_BY_ID = "GET_LEDGERS_BY_ID";
export const GET_LEDGERS_BY_ID_SUCCESS = "GET_LEDGERS_BY_ID_SUCCESS";
export const GET_LEDGERS_BY_ID_FAILURE = "GET_LEDGERS_BY_ID_FAILURE";

export const GET_TRANSACTIONS_BY_LEDGER_ID = "GET_TRANSACTIONS_BY_LEDGER_ID";
export const GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS =
  "GET_TRANSACTIONS_BY_LEDGER_ID_SUCCESS";
export const GET_TRANSACTIONS_BY_LEDGER_ID_FAILURE =
  "GET_TRANSACTIONS_BY_LEDGER_ID_FAILURE";

export const GET_GROUPS_LIST = "GET_GROUPS_LIST";
export const GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS";
export const GET_GROUPS_LIST_FAILURE = "GET_GROUPS_LIST_FAILURE";

export const GET_GROUPS_LIST_BY_USER = "GET_GROUPS_LIST_BY_USER";
export const GET_GROUPS_LIST_BY_USER_SUCCESS =
  "GET_GROUPS_LIST_BY_USER_SUCCESS";
export const GET_GROUPS_LIST_BY_USER_FAILURE =
  "GET_GROUPS_LIST_BY_USER_FAILURE";
export const GET_GROUPS_LIST_BY_USER_INPROGRESS =
  "GET_GROUPS_LIST_BY_USER_INPROGRESS";

export const GET_GROUP_LIST_WITH_CBAL = "GET_GROUP_LIST_WITH_CBAL";
export const GET_GROUP_LIST_WITH_CBAL_SUCCESS =
  "GET_GROUP_LIST_WITH_CBAL_SUCCESS";
export const GET_GROUP_LIST_WITH_CBAL_FAILURE =
  "GET_GROUP_LIST_WITH_CBAL_FAILURE";

export const GET_GROUP_DATA_BY_ID = "GET_GROUP_DATA_BY_ID";
export const GET_GROUP_DATA_BY_ID_SUCCESS = "GET_GROUP_DATA_BY_ID_SUCCESS";
export const GET_GROUP_DATA_BY_ID_FAILURE = "GET_GROUP_DATA_BY_ID_FAILURE";

export const TOP_RECEIVABLES_LIST = "TOP_RECEIVABLES_LIST";
export const TOP_RECEIVABLES_LIST_SUCCESS = "TOP_RECEIVABLES_LIST_SUCCESS";
export const TOP_RECEIVABLES_LIST_FAILURE = "TOP_RECEIVABLES_LIST_FAILURE";

export const TOP_PAYABLES_LIST = "TOP_PAYABLES_LIST";
export const TOP_PAYABLES_LIST_SUCCESS = "TOP_PAYABLES_LIST_SUCCESS";
export const TOP_PAYABLES_LIST_FAILURE = "TOP_PAYABLES_LIST_FAILURE";

export const GET_QUESTION_TRANSACTIONS_LIST = "GET_QUESTION_TRANSACTIONS_LIST";
export const GET_QUESTION_TRANSACTIONS_LIST_SUCCESS =
  "GET_QUESTION_TRANSACTIONS_LIST_SUCCESS";
export const GET_QUESTION_TRANSACTIONS_LIST_FAILURE =
  "GET_QUESTION_TRANSACTIONS_LIST_FAILURE";

//#region Report Sharing
export const SHARE_REPORT_INIT_STATE = "SHARE_REPORT_INIT_STATE";
export const SHARE_REPORT = "SHARE_REPORT";
export const SHARE_REPORT_SUCCESS = "SHARE_REPORT_SUCCESS";
export const SHARE_REPORT_FAILURE = "SHARE_REPORT_FAILURE";
//#endregion Report Sharing

//#region Attachments
export const ATT_INIT_STATUS = "ATT_INIT_STATUS";
export const ATTACHMENT_UPLOAD = "ATTACHMENT_UPLOAD";
export const ATTACHMENT_UPLOAD_SUCCESS = "ATTACHMENT_UPLOAD_SUCCESS";
export const ATTACHMENT_UPLOAD_FAILURE = "ATTACHMENT_UPLOAD_FAILURE";

export const GET_ATTACHMENT_LIST = "GET_ATTACHMENT_LIST";
export const GET_ATTACHMENT_LIST_SUCCESS = "GET_ATTACHMENT_LIST_SUCCESS";
export const GET_ATTACHMENT_LIST_FAILURE = "GET_ATTACHMENT_LIST_FAILURE";

export const ATTACHMENT_DELETE = "ATTACHMENT_DELETE";
export const ATTACHMENT_DELETE_SUCCESS = "ATTACHMENT_DELETE_SUCCESS";
export const ATTACHMENT_DELETE_FAILURE = "ATTACHMENT_DELETE_FAILURE";
////#endregion Attachments

//#region Tags
export const SAVE_TAGS = "SAVE_TAGS";
export const SAVE_TAGS_SUCCESS = "SAVE_TAGS_SUCCESS";
export const SAVE_TAGS_FAILURE = "SAVE_TAGS_FAILURE";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
export const CHIPS_TO_EMPTY = "CHIPS_TO_EMPTY";
////#endregion Tags

export const GET_TRANSACTIONS_BY_AMOUNTS = "GET_TRANSACTIONS_BY_AMOUNTS";
export const GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS =
  "GET_TRANSACTIONS_BY_AMOUNTS_SUCCESS";
export const GET_TRANSACTIONS_BY_AMOUNTS_FAILURE =
  "GET_TRANSACTIONS_BY_AMOUNTS_FAILURE";

export const GET_TRANSACTIONS_BY_COMBINATION =
  "GET_TRANSACTIONS_BY_COMBINATION";
export const GET_TRANSACTIONS_BY_COMBINATION_SUCCESS =
  "GET_TRANSACTIONS_BY_COMBINATION_SUCCESS";
export const GET_TRANSACTIONS_BY_COMBINATION_FAILURE =
  "GET_TRANSACTIONS_BY_COMBINATION_FAILURE";
export const SET_FILTERED_JSON = "SET_FILTERED_JSON";
export const RESET_FILTERED_JSON = "RESET_FILTERED_JSON";

export const GET_TRANSACTIONS_TYPES = "GET_TRANSACTIONS_TYPES";
export const GET_TRANSACTIONS_TYPES_SUCCESS = "GET_TRANSACTIONS_TYPES_SUCCESS";
export const GET_TRANSACTIONS_TYPES_FAILURE = "GET_TRANSACTIONS_TYPES_FAILURE";

export const REMARKS_SAVE = "REMARKS_SAVE";
export const REMARKS_SAVE_SUCCESS = "REMARKS_SAVE_SUCCESS";
export const REMARKS_SAVE_FAILURE = "REMARKS_SAVE_SUCCESS";
export const REMARKS_SAVE_DEFAULT = "REMARKS_SAVE_DEFAULT";

export const GET_REMARKS = "GET_REMARKS";
export const GET_REMARKS_SUCCESS = "GET_REMARKS_SUCCESS";
export const GET_REMARKS_FAILURE = "GET_REMARKS_FAILURE";

export const SAVE_QUERY = "SAVE_QUERY";
export const SAVE_QUERY_SUCCESS = "SAVE_QUERY_SUCCESS";
export const SAVE_QUERY_FAILURE = "SAVE_QUERY_FAILURE";

export const GET_QUERY_BY_TYPE = "GET_QUERY_BY_TYPE";
export const GET_QUERY_BY_TYPE_SUCCESS = "GET_QUERY_BY_TYPE_SUCCESS";
export const GET_QUERY_BY_TYPE_FAILURE = "GET_QUERY_BY_TYPE_FAILURE";
export const QUERY_SAVE_DEFAULT = "QUERY_SAVE_DEFAULT";

export const GET_QUERY_GROUP_LIST = "GET_QUERY_GROUP_LIST";
export const GET_QUERY_GROUP_LIST_SUCCESS = "GET_QUERY_GROUP_LIST_SUCCESS";
export const GET_QUERY_GROUP_LIST_FAILURE = "GET_QUERY_GROUP_LIST_FAILURE";

export const GET_QUERY_COUNT_INFO = "GET_QUERY_COUNT_INFO";
export const GET_QUERY_COUNT_INFO_SUCCESS = "GET_QUERY_COUNT_INFO_SUCCESS";
export const GET_QUERY_COUNT_INFO_FAILURE = "GET_QUERY_COUNT_INFO_FAILURE";

export const GET_QUERIES_BY_QUERY_GROUP = "GET_QUERIES_BY_QUERY_GROUP";
export const GET_QUERIES_BY_QUERY_GROUP_SUCCESS =
  "GET_QUERIES_BY_QUERY_GROUP_SUCCESS";
export const GET_QUERIES_BY_QUERY_GROUP_FAILURE =
  "GET_QUERIES_BY_QUERY_GROUP_FAILURE";

export const SAVE_MESSAGE = "SAVE_MESSAGE";
export const SAVE_MESSAGE_SUCCESS = "SAVE_MESSAGE_SUCCESS";
export const SAVE_MESSAGE_FAILURE = "SAVE_MESSAGE_FAILURE";
export const SAVE_MESSAGE_DEFAULT = "SAVE_MESSAGE_DEFAULT";

export const GET_QUERY_DATA = "GET_QUERY_DATA";
export const GET_QUERY_DATA_SUCCESS = "GET_QUERY_DATA_SUCCESS";
export const GET_QUERY_DATA_FAILURE = "GET_QUERY_DATA_FAILURE";

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";

export const GET_REGISTERS_BY_TYPE_AND_DATES =
  "GET_REGISTERS_BY_TYPE_AND_DATES";
export const GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS =
  "GET_REGISTERS_BY_TYPE_AND_DATES_SUCCESS";
export const GET_REGISTERS_BY_TYPE_AND_DATES_FAILURE =
  "GET_REGISTERS_BY_TYPE_AND_DATES_FAILURE";

export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const SAVE_USER_DEFAULT_STATUS = "SAVE_USER_DEFAULT_STATUS";

/*Organisation Region */

export const SAVE_ORGANISATION = "SAVE_ORGANISATION";
export const SAVE_ORGANISATION_SUCCESS = "SAVE_ORGANISATION_SUCCESS";
export const SAVE_ORGANISATION_FAILURE = "SAVE_ORGANISATION_FAILURE";
export const SAVE_ORGANISATION_DEFAULT_STATUS =
  "SAVE_ORGANISATION_DEFAULT_STATUS";

export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAILURE = "UPDATE_ORGANISATION_FAILURE";
export const UPDATE_ORGANISATION_DEFAULT_STATUS =
  "UPDATE_ORGANISATION_DEFAULT_STATUS";

export const GET_ORGANISATION_BY_ORG_ID = "GET_ORGANISATION_BY_ORG_ID";
export const GET_ORGANISATION_BY_ORG_ID_SUCCESS =
  "GET_ORGANISATION_BY_ORG_ID_SUCCESS";
export const GET_ORGANISATION_BY_ORG_ID_FAILURE =
  "GET_ORGANISATION_BY_ORG_ID_FAILURE";
export const GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS =
  "GET_ORGANISATION_BY_ORG_ID_DEFAULT_STATUS";

export const GET_ORGANISATION_BY_ORG_OWNER_ID =
  "GET_ORGANISATION_BY_ORG_OWNER_ID";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS =
  "GET_ORGANISATION_BY_ORG_OWNER_ID_SUCCESS";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE =
  "GET_ORGANISATION_BY_ORG_OWNER_ID_FAILURE";
export const GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS =
  "GET_ORGANISATION_BY_ORG_OWNER_ID_DEFAULT_STATUS";

export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const GET_EXPENSES_FAILURE = "GET_EXPENSES_FAILURE";

export const GET_EXPENSES_BY_AMOUNTS = "GET_EXPENSES_BY_AMOUNTS";
export const GET_EXPENSES_BY_AMOUNTS_SUCCESS =
  "GET_EXPENSES_BY_AMOUNTS_SUCCESS";
export const GET_EXPENSES_BY_AMOUNTS_FAILURE =
  "GET_EXPENSES_BY_AMOUNTS_FAILURE";

export const GET_EXPENSES_THIS_MONTH = "GET_EXPENSES_THIS_MONTH";
export const GET_EXPENSES_THIS_MONTH_SUCCESS =
  "GET_EXPENSES_THIS_MONTH_SUCCESS";
export const GET_EXPENSES_THIS_MONTH_FAILURE =
  "GET_EXPENSES_THIS_MONTH_FAILURE";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const CLEAR_AFER_LOGIN = "CLEAR_AFER_LOGIN";

export const SET_DATE_FILTER_DATA = "SET_DATE_FILTER_DATA";
export const RESET_DATE_FILTER_DATA = "RESET_DATE_FILTER_DATA";
export const SET_GLOBAL_FILTER_VALUE = "SET_GLOBAL_FILTER_VALUE";

export const GET_PAN_OR_GST_NO = "GET_PAN_OR_GST_NO";
export const GET_PAN_OR_GST_NO_SUCCESS = "GET_PAN_OR_GST_NO_SUCCESS";
export const GET_PAN_OR_GST_NO_FAILURE = "GET_PAN_OR_GST_NO_FAILURE";

export const GET_CASH_PAYMENTS = "GET_CASH_PAYMENTS";
export const GET_CASH_PAYMENTS_SUCCESS = "GET_CASH_PAYMENTS_SUCCESS";
export const GET_CASH_PAYMENTS_FAILURE = "GET_CASH_PAYMENTS_FAILURE";

export const GET_SALES_BY_COMBINATION = "GET_SALES_BY_COMBINATION";
export const GET_SALES_BY_COMBINATION_SUCCESS =
  "GET_SALES_BY_COMBINATION_SUCCESS";
export const GET_SALES_BY_COMBINATION_FAILURE =
  "GET_SALES_BY_COMBINATION_FAILURE";

export const GET_PURCHASE_INVOICE_LIST = "GET_PURCHASE_INVOICE_LIST";
export const GET_PURCHASE_INVOICE_LIST_SUCCESS =
  "GET_PURCHASE_INVOICE_LIST_SUCCESS";
export const GET_PURCHASE_INVOICE_LIST_FAILURE =
  "GET_PURCHASE_INVOICE_LIST_FAILURE";

export const GET_INVOICE_LIST_BY_TYPE = "GET_INVOICE_LIST_BY_TYPE";
export const GET_INVOICE_LIST_BY_TYPE_SUCCESS =
  "GET_INVOICE_LIST_BY_TYPE_SUCCESS";
export const GET_INVOICE_LIST_BY_TYPE_FAILURE =
  "GET_INVOICE_LIST_BY_TYPE_FAILURE";

//#region Tags

export const GET_PROFIT_AND_LOSS = "GET_PROFIT_AND_LOSS";
export const GET_PROFIT_AND_LOSS_SUCCESS = "GET_PROFIT_AND_LOSS_SUCCESS";
export const GET_PROFIT_AND_LOSS_FAILURE = "GET_PROFIT_AND_LOSS_FAILURE";

export const GET_PURCHASE_VALUE = "GET_PURCHASE_VALUE";
export const GET_PURCHASE_VALUE_SUCCESS = "GET_PURCHASE_VALUE_SUCCESS";
export const GET_PURCHASE_VALUE_FAILURE = "GET_PURCHASE_VALUE_FAILURE";

export const GET_SALES_VALUE = "GET_SALES_VALUE";
export const GET_SALES_VALUE_SUCCESS = "GET_SALES_VALUE_SUCCESS";
export const GET_SALES_VALUE_FAILURE = "GET_SALES_VALUE_FAILURE";

export const GET_FIXED_ASSET_VALUE = "GET_FIXED_ASSET_VALUE";
export const GET_FIXED_ASSET_VALUE_SUCCESS = "GET_FIXED_ASSET_VALUE_SUCCESS";
export const GET_FIXED_ASSET_VALUE_FAILURE = "GET_FIXED_ASSET_VALUE_FAILURE";

export const GET_CURRENT_ASSET_VALUE = "GET_CURRENT_ASSET_VALUE";
export const GET_CURRENT_ASSET_VALUE_SUCCESS =
  "GET_CURRENT_ASSET_VALUE_SUCCESS";
export const GET_CURRENT_ASSET_VALUE_FAILURE =
  "GET_CURRENT_ASSET_VALUE_FAILURE";

export const GET_CURRENT_LIABILITIES_VALUE = "GET_CURRENT_LIABILITIES_VALUE";
export const GET_CURRENT_LIABILITIES_VALUE_SUCCESS =
  "GET_CURRENT_LIABILITIES_VALUE_SUCCESS";
export const GET_CURRENT_LIABILITIES_VALUE_FAILURE =
  "GET_CURRENT_LIABILITIES_VALUE_FAILURE";

////#endregion Tags

//#inventory Region

export const GET_STOCK_ITEMS = "GET_STOCK_ITEMS";
export const GET_STOCK_ITEMS_SUCCESS = "GET_STOCK_ITEMS_SUCCESS";
export const GET_STOCK_ITEMS_FAILURE = "GET_STOCK_ITEMS_FAILURE";

export const GET_STOCK_GROUPS = "GET_STOCK_GROUPS";
export const GET_STOCK_GROUPS_SUCCESS = "GET_STOCK_GROUPS_SUCCESS";
export const GET_STOCK_GROUPS_FAILURE = "GET_STOCK_GROUPS_FAILURE";

export const GET_STOCK_VOUCHERS = "GET_STOCK_VOUCHERS";
export const GET_STOCK_VOUCHERS_SUCCESS = "GET_STOCK_VOUCHERS_SUCCESS";
export const GET_STOCK_VOUCHERS_FAILURE = "GET_STOCK_VOUCHERS_FAILURE";

export const GET_GODOWN = "GET_GODOWN";
export const GET_GODOWN_SUCCESS = "GET_GODOWN_SUCCESS";
export const GET_GODOWN_FAILURE = "GET_GODOWN_FAILURE";

export const GET_STOCK_ITEM_LIST_BY_TYPE_ID = "GET_STOCK_ITEM_LIST_BY_TYPE_ID";
export const GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS =
  "GET_STOCK_ITEM_LIST_BY_TYPE_ID_SUCCESS";
export const GET_STOCK_ITEM_LIST_BY_TYPE_ID_FAILURE =
  "GET_STOCK_ITEM_LIST_BY_TYPE_ID_FAILURE";

//#inventory Region

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";
export const CREATE_PAYMENT_INIT = "CREATE_PAYMENT_INIT";
export const CREATE_PAYMENT_DONE = "CREATE_PAYMENT_DONE";

export const GET_PAYMENT_MADE_FROM_LEDGER_LIST =
  "GET_PAYMENT_MADE_FROM_LEDGER_LIST";
export const GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS =
  "GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS";
export const GET_PAYMENT_MADE_FROM_LEDGER_LIST_FAILURE =
  "GET_PAYMENT_MADE_FROM_LEDGER_LIST_FAILURE";

export const GET_PAYMENT_MADE_TO_LEDGER_LIST =
  "GET_PAYMENT_MADE_TO_LEDGER_LIST";
export const GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS =
  "GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS";
export const GET_PAYMENT_MADE_TO_LEDGER_LIST_FAILURE =
  "GET_PAYMENT_MADE_TO_LEDGER_LIST_FAILURE";

export const GET_BILL_REF_NO = "GET_BILL_REF_NO";
export const GET_BILL_REF_NO_SUCCESS = "GET_BILL_REF_NO_SUCCESS";
export const GET_BILL_REF_NO_FAILURE = "GET_BILL_REF_NO_FAILURE";

export const GET_AMOUNT_BY_REF_NO = "GET_AMOUNT_BY_REF_NO";
export const GET_AMOUNT_BY_REF_NO_SUCCESS = "GET_AMOUNT_BY_REF_NO_SUCCESS";
export const GET_AMOUNT_BY_REF_NO_FAILURE = "GET_AMOUNT_BY_REF_NO_FAILURE";

export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY =
  "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY";
export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS =
  "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS";
export const GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_FAILURE =
  "GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_FAILURE";

export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES =
  "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES";
export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_SUCCESS =
  "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_SUCCESS";
export const GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_FAILURE =
  "GET_TRANSACTIONS_BY_ID_AND_BETWEEN_DATES_FAILURE";

export const CHEK_DUPLICATE_BILL_REF_NO = "CHEK_DUPLICATE_BILL_REF_NO";
export const CHEK_DUPLICATE_BILL_REF_NO_SUCCESS =
  "CHEK_DUPLICATE_BILL_REF_NO_SUCCESS";
export const CHEK_DUPLICATE_BILL_REF_NO_FAILURE =
  "CHEK_DUPLICATE_BILL_REF_NO_FAILURE";

export const GET_PURCHASE_GROUP_LIST = "GET_PURCHASE_GROUP_LIST";
export const GET_PURCHASE_GROUP_LIST_SUCCESS =
  "GET_PURCHASE_GROUP_LIST_SUCCESS";
export const GET_PURCHASE_GROUP_LIST_FAILURE =
  "GET_PURCHASE_GROUP_LIST_FAILURE";

export const CREATE_PURCHASE = "CREATE_PURCHASE";
export const CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_PURCHASE_FAILURE = "CREATE_PURCHASE_FAILURE";
export const CREATE_PURCHASE_INIT = "CREATE_PURCHASE_INIT";
export const CREATE_PURCHASE_DONE = "CREATE_PURCHASE_DONE";

export const CREATE_SALE = "CREATE_PURCHASE";
export const CREATE_SALE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
export const CREATE_SALE_FAILURE = "CREATE_PURCHASE_FAILURE";

export const GET_PAYMENT_OR_RECIPT_DATA = "GET_PAYMENT_OR_RECIPT_DATA";
export const GET_PAYMENT_OR_RECIPT_DATA_SUCCESS =
  "GET_PAYMENT_OR_RECIPT_DATA_SUCCESS";
export const GET_PAYMENT_OR_RECIPT_DATA_FAILURE =
  "GET_PAYMENT_OR_RECIPT_DATA_FAILURE";

export const GET_PURCHASE_AS_VOUCHER_DATA = "GET_PURCHASE_AS_VOUCHER_DATA";
export const GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS =
  "GET_PURCHASE_AS_VOUCHER_DATA_SUCCESS";
export const GET_PURCHASE_AS_VOUCHER_DATA_FAILURE =
  "GET_PURCHASE_AS_VOUCHER_DATA_FAILURE";

export const GET_JOURNAL_DATA = "GET_JOURNAL_DATA";
export const GET_JOURNAL_DATA_SUCCESS = "GET_JOURNAL_DATA_SUCCESS";
export const GET_JOURNAL_DATA_FAILURE = "GET_JOURNAL_DATA_FAILURE";

export const GET_CREATE_GROUP_DATA = "GET_CREATE_GROUP_DATA";
export const GET_CREATE_GROUP_DATA_SUCCESS = "GET_CREATE_GROUP_DATA_SUCCESS";
export const GET_CREATE_GROUP_DATA_ERROR = "GET_CREATE_GROUP_DATA_ERROR";

export const POST_CREATE_GROUP_DATA = "POST_CREATE_GROUP_DATA";
export const POST_CREATE_GROUP_DATA_SUCCESS = "POST_CREATE_GROUP_DATA_SUCCESS";
export const POST_CREATE_GROUP_DATA_ERROR = "POST_CREATE_GROUP_DATA_ERROR";
export const CLEAR_POST_GROUP_DATA_RESP = "CLEAR_POST_GROUP_DATA_RESP";

export const CLEAR_LOGIN_RESP_DATA = "CLEAR_LOGIN_RESP_DATA";

export const EMAIL_TOKEN = "EMAIL_TOKEN";
export const EMAIL_TOKEN_SUCCESS = "EMAIL_TOKEN_SUCCESS";
export const EMAIL_TOKEN_FAILURE = "EMAIL_TOKEN_FAILURE";

export const CLEAR_LOGIN_TOKEN = "CLEAR_LOGIN_TOKEN";

export const REQ_RESET_EMAIL = "REQ_RESET_EMAIL";
export const REQ_RESET_EMAIL_SUCCESS = "REQ_RESET_EMAIL_SUCCESS";
export const REQ_RESET_EMAIL_ERROR = "REQ_RESET_EMAIL_ERROR";

export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_ERROR = "CONFIRM_PASSWORD_ERROR";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

export const USER_EMAIl_EXISTS = "USER_EMAIl_EXISTS";
export const USER_EMAIl_EXISTS_SUCCESS = "USER_EMAIl_EXISTS_SUCCESS";
export const USER_EMAIl_EXISTS_ERROR = "USER_EMAIl_EXISTS_ERROR";

export const CLEAR_EMAIL_EXIST_RESP = "CLEAR_EMAIL_EXIST_RESP";

export const GET_SALES_LEDGER = "GET_SALES_LEDGER";
export const GET_SALES_LEDGER_SUCCESS = "GET_SALES_LEDGER_SUCCESS";
export const GET_SALES_LEDGER_ERROR = "GET_SALES_LEDGER_ERROR";

export const GET_LEDGER = "GET_LEDGER";
export const GET_LEDGER_SUCCESS = "GET_LEDGER_SUCCESS";
export const GET_LEDGER_ERROR = "GET_LEDGER_ERROR";

export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";

export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_ERROR = "GET_PROFILES_ERROR";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

export const ASSIGN_USERS = "ASSIGN_USERS";
export const ASSIGN_USERS_SUCCESS = "ASSIGN_USERS_SUCCESS";
export const ASSIGN_USERS_ERROR = "ASSIGN_USERS_ERROR";

export const GET_USER_COMPANIES = "GET_USER_COMPANIES";
export const GET_USER_COMPANIES_SUCCESS = "GET_USER_COMPANIES_SUCCESS";
export const GET_USER_COMPANIES_FAILURE = "GET_USER_COMPANIES_FAILURE";

export const GET_USER_COMPANIES_IN_LOGIN = "GET_USER_COMPANIES_IN_LOGIN";
export const GET_USER_COMPANIES_IN_LOGIN_SUCCESS =
  "GET_USER_COMPANIES_IN_LOGIN_SUCCESS";
export const GET_USER_COMPANIES_IN_LOGIN_FAILURE =
  "GET_USER_COMPANIES_IN_LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const STORE_TANSACTION_DATA = "STORE_TANSACTION_DATA";

export const GET_UNDERGROUP_LIST = "GET_UNDERGROUP_LIST";
export const GET_UNDERGROUP_LIST_SUCCESS = "GET_UNDERGROUP_LIST_SUCCESS";
export const GET_UNDERGROUP_LIST_FAILURE = "GET_UNDERGROUP_LIST_FAILURE";
export const CREATE_LEDGER = "CREATE_LEDGER";
export const CREATE_LEDGER_SUCCESS = "CREATE_LEDGER_SUCCESS";
export const CREATE_LEDGER_ERROR = "CREATE_LEDGER_ERROR";
export const CLEAR_CREATE_LEDGER_RESP = "CLEAR_CREATE_LEDGER_RESP";

export const GET_COMPANY_PROFILE_INFO = "GET_COMPANY_PROFILE_INFO";
export const GET_COMPANY_PROFILE_INFO_SUCCESS =
  "GET_COMPANY_PROFILE_INFO_SUCCESS";
export const GET_COMPANY_PROFILE_INFO_ERROR = "GET_COMPANY_PROFILE_INFO_ERROR";

export const GET_LEDGER_MAILING_DETAILS = "GET_LEDGER_MAILING_DETAILS";
export const GET_LEDGER_MAILING_DETAILS_SUCCESS =
  "GET_LEDGER_MAILING_DETAILS_SUCCESS";
export const GET_LEDGER_MAILING_DETAILS_FAILURE =
  "GET_LEDGER_MAILING_DETAILS_FAILURE";

export const GET_LEDGER_BANK_ACC_DETAILS = "GET_LEDGER_BANK_ACC_DETAILS";
export const GET_LEDGER_BANK_ACC_DETAILS_SUCCESS =
  "GET_LEDGER_BANK_ACC_DETAILS_SUCCESS";
export const GET_LEDGER_BANK_ACC_DETAILS_FAILURE =
  "GET_LEDGER_BANK_ACC_DETAILS_FAILURE";

export const GET_LEDGER_STATUTORY_DETAILS = "GET_LEDGER_STATUTORY_DETAILS";
export const GET_LEDGER_STATUTORY_DETAILS_SUCCESS =
  "GET_LEDGER_STATUTORY_DETAILS_SUCCESS";
export const GET_LEDGER_STATUTORY_DETAILS_FAILURE =
  "GET_LEDGER_STATUTORY_DETAILS_FAILURE";

export const GET_LEDGER_PARTY_DETAILS = "GET_LEDGER_PARTY_DETAILS";
export const GET_LEDGER_PARTY_DETAILS_SUCCESS =
  "GET_LEDGER_PARTY_DETAILS_SUCCESS";
export const GET_LEDGER_PARTY_DETAILS_FAILURE =
  "GET_LEDGER_PARTY_DETAILS_FAILURE";

export const GET_LEDGER_OTHER_DETAILS = "GET_LEDGER_OTHER_DETAILS";
export const GET_LEDGER_OTHER_DETAILS_SUCCESS =
  "GET_LEDGER_OTHER_DETAILS_SUCCESS";
export const GET_LEDGER_OTHER_DETAILS_FAILURE =
  "GET_LEDGER_OTHER_DETAILS_FAILURE";
// upate password from profile page
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
// init user profile data
export const INIT_USER_PROFILE_DATA = "INIT_USER_PROFILE_DATA";
export const INIT_USER_PROFILE_DATA_SUCCESS = "INIT_USER_PROFILE_DATA_SUCCESS";
export const INIT_USER_PROFILE_DATA_ERROR = "INIT_USER_PROFILE_DATA_ERROR";
// update user profile data
export const UPDATE_USER_PROFILE_DATA = "UPDATE_USER_PROFILE_DATA";
export const UPDATE_USER_PROFILE_DATA_SUCCESS =
  "UPDATE_USER_PROFILE_DATA_SUCCESS";
export const UPDATE_USER_PROFILE_DATA_ERROR = "UPDATE_USER_PROFILE_DATA_ERROR";

export const GET_BILL_PAY_RECEIVABLES = "GET_BILL_PAY_RECEIVABLES";
export const GET_BILL_PAY_RECEIVABLES_SUCCESS =
  "GET_BILL_PAY_RECEIVABLES_SUCCESS";
export const GET_BILL_PAY_RECEIVABLES_FAILURE =
  "GET_BILL_PAY_RECEIVABLES_FAILURE";
export const GET_BILL_PAY_RECEIVABLES_INIT = "GET_BILL_PAY_RECEIVABLES_INIT";
export const GET_BILL_PAY_RECEIVABLES_DONE = "GET_BILL_PAY_RECEIVABLES_DONE";

export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO =
  "GET_BILLS_BY_LEDGER_ID_AND_REF_NO";
export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS =
  "GET_BILLS_BY_LEDGER_ID_AND_REF_NO_SUCCESS";
export const GET_BILLS_BY_LEDGER_ID_AND_REF_NO_FAILURE =
  "GET_BILLS_BY_LEDGER_ID_AND_REF_NO_FAILURE";

export const GET_EXPENCE_GROUP_CHART_DATA = "GET_EXPENCE_GROUP_CHART_DATA";
export const GET_EXPENCE_GROUP_CHART_DATA_SUCCESS =
  "GET_EXPENCE_GROUP_CHART_DATA_SUCCESS";
export const GET_EXPENCE_GROUP_CHART_DATA_FAILURE =
  "GET_EXPENCE_GROUP_CHART_DATA_FAILURE";

export const GET_PROFIT_AND_LOSS_CHART_DATA = "GET_PROFIT_AND_LOSS_CHART_DATA";
export const GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS =
  "GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS";
export const GET_PROFIT_AND_LOSS_CHART_DATA_FAILURE =
  "GET_PROFIT_AND_LOSS_CHART_DATA_SUCCESS_FAILURE";

export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const GET_INVITED_USERS_SUCCESS = "GET_INVITED_USERS_SUCCESS";
export const GET_INVITED_USERS_ERROR = "GET_INVITED_USERS_ERROR";

export const GET_MY_TALLY_COMPANIES = "GET_MY_TALLY_COMPANIES";
export const GET_MY_TALLY_COMPANIES_SUCCESS = "GET_MY_TALLY_COMPANIES_SUCCESS";
export const GET_MY_TALLY_COMPANIES_ERROR = "GET_MY_TALLY_COMPANIES_ERROR";

export const GET_ALL_GROUP_LIST = "GET_ALL_GROUP_LIST";
export const GET_ALL_GROUP_LIST_SUCCESS = "GET_ALL_GROUP_LIST_SUCCESS";
export const GET_ALL_GROUP_LIST_FAILURE = "GET_ALL_GROUP_LIST_FAILURE";

export const GET_BOOK_START_AND_END_DATE = "GET_BOOK_START_AND_END_DATE";
export const GET_BOOK_START_AND_END_DATE_SUCCESS =
  "GET_BOOK_START_AND_END_DATE_SUCCESS";
export const GET_BOOK_START_AND_END_DATE_FAILURE =
  "GET_BOOK_START_AND_END_DATE_FAILURE";

export const GSTR2A_SUMMARY_INVOICE_INIT = "GSTR2A_SUMMARY_INVOICE_INIT";
export const GSTR2A_SUMMARY_INVOICE_FINISH = "GSTR2A_SUMMARY_INVOICE_FINISH";
export const GSTR2A_SUMMARY_INVOICE_SUCCESS = "GSTR2A_SUMMARY_INVOICE_SUCCESS";
export const GSTR2A_SUMMARY_INVOICE_FAILED = "GSTR2A_SUMMARY_INVOICE_FAILED";

export const GSTR2A_UPDATE_SUMMARY_INVOICE = "GSTR2A_UPDATE_SUMMARY_INVOICE";

export const DASHBOARD_INIT = "DASHBOARD_INIT";
export const DASHBOARD_FINISH = "DASHBOARD_FINISH";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILED = "DASHBOARD_FAILED";


export const GSTR2A_SUMMARY_CDN_INIT = "GSTR2A_SUMMARY_CDN_INIT";
export const GSTR2A_SUMMARY_CDN_FINISH = "GSTR2A_SUMMARY_CDN_FINISH";
export const GSTR2A_SUMMARY_CDN_SUCCESS = "GSTR2A_SUMMARY_CDN_SUCCESS";
export const GSTR2A_SUMMARY_CDN_FAILED = "GSTR2A_SUMMARY_CDN_FAILED";

export const GSTR2A_SUMMARY_TDS_INIT = "GSTR2A_SUMMARY_TDS_INIT";
export const GSTR2A_SUMMARY_TDS_FINISH = "GSTR2A_SUMMARY_TDS_FINISH";
export const GSTR2A_SUMMARY_TDS_SUCCESS = "GSTR2A_SUMMARY_TDS_SUCCESS";
export const GSTR2A_SUMMARY_TDS_FAILED = "GSTR2A_SUMMARY_TDS_FAILED";

export const GSTR2A_SUMMARY_IMPG_INIT = "GSTR2A_SUMMARY_IMPG_INIT";
export const GSTR2A_SUMMARY_IMPG_FINISH = "GSTR2A_SUMMARY_IMPG_FINISH";
export const GSTR2A_SUMMARY_IMPG_SUCCESS = "GSTR2A_SUMMARY_IMPG_SUCCESS";
export const GSTR2A_SUMMARY_IMPG_FAILED = "GSTR2A_SUMMARY_IMPG_FAILED";

export const GSTR2A_DOWNLOAD_STATUS_INIT = "GSTR2A_DOWNLOAD_STATUS_INIT";
export const GSTR2A_DOWNLOAD_STATUS_FINISH = "GSTR2A_DOWNLOAD_STATUS_FINISH";
export const GSTR2A_DOWNLOAD_STATUS_SUCCESS = "GSTR2A_DOWNLOAD_STATUS_SUCCESS";
export const GSTR2A_DOWNLOAD_STATUS_FAILED = "GSTR2A_DOWNLOAD_STATUS_FAILED";



export const GSTR2B_DOWNLOAD_STATUS_INIT = "GSTR2B_DOWNLOAD_STATUS_INIT";
export const GSTR2B_DOWNLOAD_STATUS_FINISH = "GSTR2B_DOWNLOAD_STATUS_FINISH";
export const GSTR2B_DOWNLOAD_STATUS_SUCCESS = "GSTR2B_DOWNLOAD_STATUS_SUCCESS";
export const GSTR2B_DOWNLOAD_STATUS_FAILED = "GSTR2B_DOWNLOAD_STATUS_FAILED";
export const GSTR2B_DETAILS_DATA = "GSTR2B_DETAILS_DATA";




export const GSTR2B_SINGLE_DOWNLOAD_SUCCESS = "GSTR2B_SINGLE_DOWNLOAD_SUCCESS";

export const GSTR2A_SINGLE_DOWNLOAD_SUCCESS = "GSTR2A_SINGLE_DOWNLOAD_SUCCESS";

export const GSTR2A_SINGLE_DOWNLOAD_MSG = "GSTR2A_SINGLE_DOWNLOAD_MSG";
export const GSTIN_SESSION_STATUS = "GSTIN_SESSION_STATUS";



//gstr2aTCS
export const GSTR2A_SUMMARY_TCS_INIT = "GSTR2A_SUMMARY_TCS_INIT";
export const GSTR2A_SUMMARY_TCS_FINISH = "GSTR2A_SUMMARY_TCS_FINISH";
export const GSTR2A_SUMMARY_TCS_SUCCESS = "GSTR2A_SUMMARY_TCS_SUCCESS";
export const GSTR2A_SUMMARY_TCS_UPDATE = "GSTR2A_SUMMARY_TCS_UPDATE";
export const GSTR2A_SUMMARY_TCS_FAILED = "GSTR2A_SUMMARY_TCS_FAILED";


export const GSTR_YEARS_LIST = "GSTR_YEARS_LIST";
//gstr2A downloadSatus download button
export const GSTR2A_DOWNLOAD_PERIOD = "GSTR2A_DOWNLOAD_PERIOD";

export const GSTR2B_DOWNLOAD_PERIOD = "GSTR2B_DOWNLOAD_PERIOD";

//Finicial year list
export const GSTR_SELECTED_FINANCIAL_YEAR = "GSTR_SELECTED_FINANCIAL_YEAR";


export const GSTR2A_PR_INVOICE_INIT = "GSTR2A_PR_INVOICE_INIT";
export const GSTR2A_PR_INVOICE_FINISH = "GSTR2A_PR_INVOICE_FINISH";
export const GSTR2A_PR_INVOICE_SUCCESS = "GSTR2A_PR_INVOICE_SUCCESS";
export const GSTR2A_PR_INVOICE_FAILED = "GSTR2A_PR_INVOICE_FAILED";

export const PURCHASE_INVOICE_DOWNLOAD_INIT = "PURCHASE_INVOICE_DOWNLOAD_INIT";
export const PURCHASE_INVOICE_DOWNLOAD_FINISH = "PURCHASE_INVOICE_DOWNLOAD_FINISH";

export const ITC_TRACKER_EXCEL_DOWNLOAD_INIT = "ITC_TRACKER_EXCEL_DOWNLOAD_INIT";
export const ITC_TRACKER_EXCEL_DOWNLOAD_FINISH = "ITC_TRACKER_EXCEL_DOWNLOAD_FINISH";


export const GSTR2A_PR_SUPPLIER_INIT = "GSTR2A_PR_SUPPLIER_INIT";
export const GSTR2A_PR_SUPPLIER_FINISH = "GSTR2A_PR_SUPPLIER_FINISH";
export const GSTR2A_PR_SUPPLIER_SUCCESS = "GSTR2A_PR_SUPPLIER_SUCCESS";
export const GSTR2A_PR_SUPPLIER_FAILED = "GSTR2A_PR_SUPPLIER_FAILED";

export const PURCHASE_SUPPLIER_DOWNLOAD_INIT = "PURCHASE_SUPPLIER_DOWNLOAD_INIT";
export const PURCHASE_SUPPLIER_DOWNLOAD_FINISH = "PURCHASE_SUPPLIER_DOWNLOAD_FINISH";

//ITC Tracker

export const ITC_TRACKER_INIT = "ITC_TRACKER_INIT";
export const ITC_TRACKER_FINISH = "ITC_TRACKER_FINISH";
export const ITC_TRACKER_SUCCESS = "ITC_TRACKER_SUCCESS";
export const ITC_TRACKER_FAILED = "ITC_TRACKER_FAILED";

export const ITC_AVAILABLE_DATA_UPDATE = "ITC_AVAILABLE_DATA_UPDATE";


// ITC tracker pending to be availed and 2b missing  in books

export const ITC_PENDING_2BMISSING_INIT = "ITC_PENDING_2BMISSING_INIT";
export const ITC_PENDING_2BMISSING_FINISH = "ITC_PENDING_2BMISSING_FINISH";
export const ITC_PENDING_2BMISSING_SUCCESS = "ITC_PENDING_2BMISSING_SUCCESS";
export const ITC_PENDING_2BMISSING_FAILED = "ITC_PENDING_2BMISSING_FAILED";


//Contacts 

export const CONTACT_INIT = "CONTACT_INIT";
export const CONTACT_FINISH = "CONTACT_FINISH";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAILED = "CONTACT_FAILED";

//GSTR2A RECONCILIATON Report

export const GSTR2A_RECONCILIATON_REPORT_INIT =
  "GSTR2A_RECONCILIATON_REPORT_INIT";
export const GSTR2A_RECONCILIATON_REPORT_FINISH =
  "GSTR2A_RECONCILIATON_REPORT_FINISH";
export const GSTR2A_RECONCILIATON_REPORT_SUCCESS =
  "GSTR2A_RECONCILIATON_REPORT_SUCCESS";
export const GSTR2A_RECONCILIATON_REPORT_FAILED =
  "GSTR2A_RECONCILIATON_REPORT_FAILED";


//GSTR2A RECONCILIATON Report

export const GSTR2B_RECONCILIATON_REPORT_INIT = "GSTR2B_RECONCILIATON_REPORT_INIT";
export const GSTR2B_RECONCILIATON_REPORT_FINISH = "GSTR2B_RECONCILIATON_REPORT_FINISH";
export const GSTR2B_RECONCILIATON_REPORT_SUCCESS = "GSTR2B_RECONCILIATON_REPORT_SUCCESS";
export const GSTR2B_RECONCILIATON_REPORT_FAILED = "GSTR2B_RECONCILIATON_REPORT_FAILED";

//Reconciliation Report Modal Details

export const GSTR2A_RECONCILIATON_REPORT_DETAILS_INIT = "GSTR2A_RECONCILIATON_REPORT_DETAILS_INIT";
export const GSTR2A_RECONCILIATON_REPORT_DETAILS_FINISH = "GSTR2A_RECONCILIATON_REPORT_DETAILS_FINISH";
export const GSTR2A_RECONCILIATON_REPORT_DETAILS_SUCCESS = "GSTR2A_RECONCILIATON_REPORT_DETAILS_SUCCESS";
export const GSTR2A_RECONCILIATON_REPORT_DETAILS_FAILED = "GSTR2A_RECONCILIATON_REPORT_DETAILS_FAILED";
export const GSTR2A_RECONCILIATON_REPORT_DETAILS_ID = "GSTR2A_RECONCILIATON_REPORT_DETAILS_ID";


export const GSTR2B_RECONCILIATON_REPORT_DETAILS_INIT = "GSTR2B_RECONCILIATON_REPORT_DETAILS_INIT";
export const GSTR2B_RECONCILIATON_REPORT_DETAILS_FINISH = "GSTR2B_RECONCILIATON_REPORT_DETAILS_FINISH";
export const GSTR2B_RECONCILIATON_REPORT_DETAILS_SUCCESS = "GSTR2B_RECONCILIATON_REPORT_DETAILS_SUCCESS";
export const GSTR2B_RECONCILIATON_REPORT_DETAILS_FAILED = "GSTR2B_RECONCILIATON_REPORT_DETAILS_FAILED";
export const GSTR2B_RECONCILIATON_REPORT_DETAILS_ID = "GSTR2B_RECONCILIATON_REPORT_DETAILS_ID";

//Reconciliation Report Delete

export const GSTR2A_RECONCILIATON_REPORT_DELETE_INIT =
  "GSTR2A_RECONCILIATON_REPORT_DELETE_INIT";
export const GSTR2A_RECONCILIATON_REPORT_DELETE_FINISH =
  "GSTR2A_RECONCILIATON_REPORT_DELETE_FINISH";
export const GSTR2A_RECONCILIATON_REPORT_DELETE_SUCCESS =
  "GSTR2A_RECONCILIATON_REPORT_DELETE_SUCCESS";
export const GSTR2A_RECONCILIATON_REPORT_DELETE_FAILED =
  "GSTR2A_RECONCILIATON_REPORT_DELETE_FAILED";

// single mail 

export const GSTR2A_SINGLE_MAIL_INIT = "GSTR2A_SINGLE_MAIL_INIT";
export const GSTR2A_SINGLE_MAIL_FINISH = "GSTR2A_SINGLE_MAIL_FINISH";
export const GSTR2A_SINGLE_MAIL_SUCCESS = "GSTR2A_SINGLE_MAIL_SUCCESS";
export const GSTR2A_SINGLE_MAIL_FAILED = "GSTR2A_SINGLE_MAIL_FAILED";
export const GSTR2A_SINGLE_MAIL_RESET = "GSTR2A_SINGLE_MAIL_RESET";

// bulk mail 

export const GSTR2A_BULK_MAIL_INIT = "GSTR2A_BULK_MAIL_INIT";
export const GSTR2A_BULK_MAIL_FINISH = "GSTR2A_BULK_MAIL_FINISH";
export const GSTR2A_BULK_MAIL_SUCCESS = "GSTR2A_BULK_MAIL_SUCCESS";
export const GSTR2A_BULK_MAIL_FAILED = "GSTR2A_BULK_MAIL_FAILED";
export const GSTR2A_BULK_MAIL_RESET = "GSTR2A_BULK_MAIL_RESET";

// bulk mail contact details

export const GSTIN_CONTACT_MAIL_INIT = "GSTIN_CONTACT_MAIL_INIT";
export const GSTIN_CONTACT_MAIL_FINISH = "GSTIN_CONTACT_MAIL_FINISH";
export const GSTIN_CONTACT_MAIL_SUCCESS = "GSTIN_CONTACT_MAIL_SUCCESS";
export const GSTIN_CONTACT_MAIL_FAILED = "GSTIN_CONTACT_MAIL_FAILED";

// Ineligible Credit 

export const GSTR2A_INELIGIBLE_CREDIT_INIT = "GSTR2A_INELIGIBLE_CREDIT_INIT";
export const GSTR2A_INELIGIBLE_CREDIT_FINISH = "GSTR2A_INELIGIBLE_CREDIT_FINISH";
export const GSTR2A_INELIGIBLE_CREDIT_SUCCESS = "GSTR2A_INELIGIBLE_CREDIT_SUCCESS";
export const GSTR2A_INELIGIBLE_CREDIT_FAILED = "GSTR2A_INELIGIBLE_CREDIT_FAILED";

// get data for Ineligible Credit 

export const GSTR2A_INELIGIBLE_DATA_INIT = "GSTR2A_INELIGIBLE_DATA_INIT";
export const GSTR2A_INELIGIBLE_DATA_FINISH = "GSTR2A_INELIGIBLE_DATA_FINISH";
export const GSTR2A_INELIGIBLE_DATA_SUCCESS = "GSTR2A_INELIGIBLE_DATA_SUCCESS";
export const GSTR2A_INELIGIBLE_DATA_UPDATE = "GSTR2A_INELIGIBLE_DATA_UPDATE";
export const GSTR2A_INELIGIBLE_DATA_FAILED = "GSTR2A_INELIGIBLE_DATA_FAILED";

export const GSTR2B_RECONCILIATON_REPORT_DELETE_INIT = "GSTR2B_RECONCILIATON_REPORT_DELETE_INIT";
export const GSTR2B_RECONCILIATON_REPORT_DELETE_FINISH = "GSTR2B_RECONCILIATON_REPORT_DELETE_FINISH";
export const GSTR2B_RECONCILIATON_REPORT_DELETE_SUCCESS = "GSTR2B_RECONCILIATON_REPORT_DELETE_SUCCESS";
export const GSTR2B_RECONCILIATON_REPORT_DELETE_FAILED = "GSTR2B_RECONCILIATON_REPORT_DELETE_FAILED";

export const GSTR2B_SUMMARY_INIT = "GSTR2B_SUMMARY_INIT";
export const GSTR2B_SUMMARY_FINISH = "GSTR2B_SUMMARY_FINISH";
export const GSTR2B_SUMMARY_SUCCESS = "GSTR2B_SUMMARY_SUCCESS";
export const GSTR2B_SUMMARY_FAILED = "GSTR2B_SUMMARY_FAILED";

export const GSTR2B_B2B_SUMMARY_INIT = "GSTR2B_B2B_SUMMARY_INIT";
export const GSTR2B_B2B_SUMMARY_FINISH = "GSTR2B_B2B_SUMMARY_FINISH";
export const GSTR2B_B2B_SUMMARY_SUCCESS = "GSTR2B_B2B_SUMMARY_SUCCESS";
export const GSTR2B_B2B_SUMMARY_FAILED = "GSTR2B_B2B_SUMMARY_FAILED";

export const GSTR2B_CDN_SUMMARY_INIT = "GSTR2B_CDN_SUMMARY_INIT";
export const GSTR2B_CDN_SUMMARY_FINISH = "GSTR2B_CDN_SUMMARY_FINISH";
export const GSTR2B_CDN_SUMMARY_SUCCESS = "GSTR2B_CDN_SUMMARY_SUCCESS";
export const GSTR2B_CDN_SUMMARY_FAILED = "GSTR2B_CDN_SUMMARY_FAILED";

export const GSTR2B_ISD_SUMMARY_INIT = "GSTR2B_ISD_SUMMARY_INIT";
export const GSTR2B_ISD_SUMMARY_FINISH = "GSTR2B_ISD_SUMMARY_FINISH";
export const GSTR2B_ISD_SUMMARY_SUCCESS = "GSTR2B_ISD_SUMMARY_SUCCESS";
export const GSTR2B_ISD_SUMMARY_FAILED = "GSTR2B_ISD_SUMMARY_FAILED";

export const GSTR2B_B2B_DOCUMENT_INIT = "GSTR2B_B2B_DOCUMENT_INIT";
export const GSTR2B_B2B_DOCUMENT_FINISH = "GSTR2B_B2B_DOCUMENT_FINISH";
export const GSTR2B_B2B_DOCUMENT_SUCCESS = "GSTR2B_B2B_DOCUMENT_SUCCESS";
export const GSTR2B_B2B_DOCUMENT_FAILED = "GSTR2B_B2B_DOCUMENT_FAILED";

export const GSTR2B_CDN_DOCUMENT_INIT = "GSTR2B_CDN_DOCUMENT_INIT";
export const GSTR2B_CDN_DOCUMENT_FINISH = "GSTR2B_CDN_DOCUMENT_FINISH";
export const GSTR2B_CDN_DOCUMENT_SUCCESS = "GSTR2B_CDN_DOCUMENT_SUCCESS";
export const GSTR2B_CDN_DOCUMENT_FAILED = "GSTR2B_CDN_DOCUMENT_FAILED";

export const GSTR2B_IMPG_DOCUMENT_INIT = "GSTR2B_IMPG_DOCUMENT_INIT";
export const GSTR2B_IMPG_DOCUMENT_FINISH = "GSTR2B_IMPG_DOCUMENT_FINISH";
export const GSTR2B_IMPG_DOCUMENT_SUCCESS = "GSTR2B_IMPG_DOCUMENT_SUCCESS";
export const GSTR2B_IMPG_DOCUMENT_FAILED = "GSTR2B_IMPG_DOCUMENT_FAILED";

export const GSTR2B_SUMMARY_DOWNLOAD_INIT = "GSTR2B_SUMMARY_DOWNLOAD_INIT";
export const GSTR2B_SUMMARY_DOWNLOAD_FINISH = "GSTR2B_SUMMARY_DOWNLOAD_FINISH";

export const GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_INIT = " GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_INIT";
export const GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_FINISH = "GSTR2B_SUPPLIER_DOCUMENT_DOWNLOAD_FINISH";

export const REPORTS_DOWNLOAD_INIT = "GSTR2A_SUMMARY_DOWNLOAD_INIT";
export const REPORTS_DOWNLOAD_FINISH = "GSTR2A_SUMMARY_DOWNLOAD_FINISH";

export const GSTR2A_INVOICE_DOWNLOAD_INIT = "GSTR2A_INVOICE_DOWNLOAD_INIT";
export const GSTR2A_INVOICE_DOWNLOAD_FINISH = "GSTR2A_INVOICE_DOWNLOAD_FINISH";

export const GSTR2A_CDN_DOWNLOAD_INIT = "GSTR2A_CDN_DOWNLOAD_INIT";
export const GSTR2A_CDN_DOWNLOAD_FINISH = "GSTR2A_CDN_DOWNLOAD_FINISH";

export const GSTR2A_TCS_DOWNLOAD_INIT = "GSTR2A_TCS_DOWNLOAD_INIT";
export const GSTR2A_TCS_DOWNLOAD_FINISH = "GSTR2A_TCS_DOWNLOAD_FINISH";

export const GSTR2A_TDS_DOWNLOAD_INIT = "GSTR2A_TDS_DOWNLOAD_INIT";
export const GSTR2A_TDS_DOWNLOAD_FINISH = "GSTR2A_TDS_DOWNLOAD_FINISH";

export const GSTR2A_IMPG_DOWNLOAD_INIT = "GSTR2A_IMPG_DOWNLOAD_INIT";
export const GSTR2A_IMPG_DOWNLOAD_FINISH = "GSTR2A_IMPG_DOWNLOAD_FINISH";

export const UPDATE_GSTR_2A_DOWNLOAD_STATUS_DATA = "UPDATE_GSTR_2A_DOWNLOAD_STATUS_DATA";



export const SET_INVOICE_TOTAL_VALUE = "SET_INVOICE_TOTAL_VALUE";
export const SET_INVOICE_TOTAL_TAXABLE_VALUE = "SET_INVOICE_TOTAL_TAXABLE_VALUE";
export const SET_INVOICE_TOTAL_IGST = "SET_INVOICE_TOTAL_IGST";
export const SET_INVOICE_TOTAL_SGST = "SET_INVOICE_TOTAL_SGST";
export const SET_INVOICE_TOTAL_CGST = "SET_INVOICE_TOTAL_CGST";
export const SET_INVOICE_TOTAL_CESS = "SET_INVOICE_TOTAL_CESS";
export const SET_INVOICE_TOTAL_NUM = "SET_INVOICE_TOTAL_NUM";




export const SET_CDN_TOTAL_VALUE = "SET_CDN_TOTAL_VALUE";
export const SET_CDN_TOTAL_TAXABLE_VALUE = "SET_CDN_TOTAL_TAXABLE_VALUE";
export const SET_CDN_TOTAL_IGST = "SET_CDN_TOTAL_IGST";
export const SET_CDN_TOTAL_SGST = "SET_CDN_TOTAL_SGST";
export const SET_CDN_TOTAL_CGST = "SET_CDN_TOTAL_CGST";
export const SET_CDN_TOTAL_CESS = "SET_CDN_TOTAL_CESS";
export const SET_CDN_TOTAL_NUM = "SET_CDN_TOTAL_NUM";



export const SET_TDS_TOTAL_VALUE = "SET_TDS_TOTAL_VALUE";
export const SET_TDS_TOTAL_IGST = "SET_TDS_TOTAL_IGST";
export const SET_TDS_TOTAL_SGST = "SET_TDS_TOTAL_SGST";
export const SET_TDS_TOTAL_CGST = "SET_TDS_TOTAL_CGST";
export const SET_TDS_TOTAL_NUM = "SET_TDS_TOTAL_NUM";




export const SET_IMPG_TOTAL_TAXABLE_VALUE = "SET_IMPG_TOTAL_TAXABLE_VALUE";
export const SET_IMPG_TOTAL_IGST = "SET_IMPG_TOTAL_IGST";
export const SET_IMPG_TOTAL_CESS = "SET_IMPG_TOTAL_CESS";
export const SET_IMPG_TOTAL_NUM = "SET_IMPG_TOTAL_NUM";




export const RECONCILE_CHECK_DOWNLOAD_INIT = "RECONCILE_CHECK_DOWNLOAD_INIT";
export const RECONCILE_CHECK_DOWNLOAD_FINISH = "RECONCILE_CHECK_DOWNLOAD_FINISH";
export const RECONCILE_CHECK_DOWNLOAD_SUCCESS = "RECONCILE_CHECK_DOWNLOAD_SUCCESS";
export const RECONCILE_CHECK_DOWNLOAD_FAILED = "RECONCILE_CHECK_DOWNLOAD_FAILED";

export const DOWNLOAD_FROM_MASTER_INIT = "DOWNLOAD_FROM_MASTER_INIT";
export const DOWNLOAD_FROM_MASTER_FINISH = "DOWNLOAD_FROM_MASTER_FINISH";
export const DOWNLOAD_FROM_MASTER_DATA = "DOWNLOAD_FROM_MASTER_DATA";



export const RECONCILE_DOWNLOAD_FROM_MASTER_INIT = "RECONCILE_DOWNLOAD_FROM_MASTER_INIT";
export const RECONCILE_DOWNLOAD_FROM_MASTER_FINISH = "RECONCILE_DOWNLOAD_FROM_MASTER_FINISH";
export const RECONCILE_DOWNLOAD_FROM_MASTER_DATA = "RECONCILE_DOWNLOAD_FROM_MASTER_DATA";


export const RECONCILE_REPORT_INIT = "RECONCILE_REPORT_INIT";
export const RECONCILE_REPORT_FINISH = "RECONCILE_REPORT_FINISH";
export const RECONCILE_REPORT_SUCCESS = "RECONCILE_REPORT_SUCCESS";
export const RECONCILE_REPORT_FAILED = "RECONCILE_REPORT_FAILED";



export const RECONCILE_REPORT_2B_INIT = "RECONCILE_REPORT_2B_INIT";
export const RECONCILE_REPORT_2B_FINISH = "RECONCILE_REPORT_2B_FINISH";
export const RECONCILE_REPORT_2B_SUCCESS = "RECONCILE_REPORT_2B_SUCCESS";
export const RECONCILE_REPORT_2B_FAILED = "RECONCILE_REPORT_2B_FAILED";


export const RECONCILE_REPORT_VIEW_MODAL = "RECONCILE_REPORT_VIEW_MODAL";

export const RECONCILE_DOWNLOAD_INIT = "RECONCILE_DOWNLOAD_INIT";
export const RECONCILE_DOWNLOAD_FINISH = "RECONCILE_DOWNLOAD_FINISH";
export const RECONCILE_DOWNLOAD_MASTER_DATA = "RECONCILE_DOWNLOAD_MASTER_DATA";
export const RECONCILE_DOWNLOAD_MASTER_MESSAGE = "RECONCILE_DOWNLOAD_MASTER_MESSAGE";
export const RECONCILE_DOWNLOAD_FAILED = "RECONCILE_DOWNLOAD_FAILED";
export const RESET_RECONCILE_DOWNLOAD_DATA = "RESET_RECONCILE_DOWNLOAD_DATA";


export const RECONCILE_2B_DOWNLOAD_INIT = "RECONCILE_2B_DOWNLOAD_INIT";
export const RECONCILE_2B_DOWNLOAD_FINISH = "RECONCILE_2B_DOWNLOAD_FINISH";
export const RECONCILE_2B_DOWNLOAD_MASTER_DATA = "RECONCILE_2B_DOWNLOAD_MASTER_DATA";
export const RECONCILE_2B_DOWNLOAD_FAILED = "RECONCILE_2B_DOWNLOAD_FAILED";

export const RESET_CHECK_DOWNLOAD_DATA = "RESET_CHECK_DOWNLOAD_DATA";

export const RECONCILE_NOW_REPORT_SUCCESS = "RECONCILE_NOW_REPORT_SUCCESS";
export const RECONCILE_NOW_REPORT_2B_SUCCESS = "RECONCILE_NOW_REPORT_2B_SUCCESS";

export const UPDATE_RECONCILE_REPORT_DATA = "UPDATE_RECONCILE_REPORT_DATA";

export const SUPPLIER_INIT = "SUPPLIER_INIT";
export const SUPPLIER_FINISH = "SUPPLIER_FINISH";
export const SUPPLIER_DATA = "SUPPLIER_DATA";
export const SUPPLIER_FAILED = "SUPPLIER_FAILED";

export const GET_SINGLE_SUPPLIER_INIT = "GET_SINGLE_SUPPLIER_INIT";
export const GET_SINGLE_SUPPLIER_FINISH = "GET_SINGLE_SUPPLIER_FINISH";
export const GET_SINGLE_SUPPLIER_DATA = "GET_SINGLE_SUPPLIER_DATA";
export const GET_SINGLE_SUPPLIER_FAILED = "GET_SINGLE_SUPPLIER_FAILED";

export const GET_SUPPLIER_TRANSACTIONS_INIT = "GET_SUPPLIER_TRANSACTIONS_INIT";
export const GET_SUPPLIER_TRANSACTIONS_FINISH = "GET_SUPPLIER_TRANSACTIONS_FINISH";
export const GET_SUPPLIER_TRANSACTIONS_DATA = "GET_SUPPLIER_TRANSACTIONS_DATA";
export const GET_SUPPLIER_TRANSACTIONS_FAILED = "GET_SUPPLIER_TRANSACTIONS_FAILED";

export const GET_SUPPLIER_EMAIL_HISTORY_INIT = "GET_SUPPLIER_EMAIL_HISTORY_INIT";
export const GET_SUPPLIER_EMAIL_HISTORY_FINISH = "GET_SUPPLIER_EMAIL_HISTORY_FINISH";
export const GET_SUPPLIER_EMAIL_HISTORY_DATA = "GET_SUPPLIER_EMAIL_HISTORY_DATA";
export const GET_SUPPLIER_EMAIL_HISTORY_FAILED = "GET_SUPPLIER_EMAIL_HISTORY_FAILED";

export const GET_SUPPLIER_EMAIL_PREVIEW_INIT = "GET_SUPPLIER_EMAIL_PREVIEW_INIT";
export const GET_SUPPLIER_EMAIL_PREVIEW_FINISH = "GET_SUPPLIER_EMAIL_PREVIEW_FINISH";
export const GET_SUPPLIER_EMAIL_PREVIEW_DATA = "GET_SUPPLIER_EMAIL_PREVIEW_DATA";
export const GET_SUPPLIER_EMAIL_PREVIEW_FAILED = "GET_SUPPLIER_EMAIL_PREVIEW_FAILED";

export const GSTR2B_SUPPLIER_INIT = "GSTR2B_SUPPLIER_INIT";
export const GSTR2B_SUPPLIER_FINISH = "GSTR2B_SUPPLIER_FINISH";
export const GSTR2B_SUPPLIER_SUCCESS = "GSTR2B_SUPPLIER_SUCCESS";
export const GSTR2B_SUPPLIER_FAILED = "GSTR2B_SUPPLIER_FAILED";

export const GSTR_PAIR_INIT = "GSTR_PAIR_INIT";
export const GSTR_PAIR_FINISH = "GSTR_PAIR_FINISH";
export const GSTR_PAIR_SUCCESS = "GSTR_PAIR_SUCCESS";
export const GSTR_PAIR_FAILED = "GSTR_PAIR_FAILED";

export const GSTR_UN_PAIR_INIT = "GSTR_UN_PAIR_INIT";
export const GSTR_UN_PAIR_FINISH = "GSTR_UN_PAIR_FINISH";
export const GSTR_UN_PAIR_SUCCESS = "GSTR_UN_PAIR_SUCCESS";
export const GSTR_UN_PAIR_FAILED = "GSTR_UN_PAIR_FAILED";

export const AUTO_RECONCILE2A_REPORT_INIT = "AUTO_RECONCILE2A_REPORT_INIT";
export const AUTO_RECONCILE2A_REPORT_FINISH = "AUTO_RECONCILE2A_REPORT_FINISH";
export const AUTO_RECONCILE2A_REPORT_DATA = "AUTO_RECONCILE2A_REPORT_DATA";
export const AUTO_RECONCILE2A_REPORT_FAILED = "AUTO_RECONCILE2A_REPORT_FAILED";

export const AUTO_RECONCILE2B_REPORT_INIT = "AUTO_RECONCILE2B_REPORT_INIT";
export const AUTO_RECONCILE2B_REPORT_FINISH = "AUTO_RECONCILE2B_REPORT_FINISH";
export const AUTO_RECONCILE2B_REPORT_DATA = "AUTO_RECONCILE2B_REPORT_DATA";
export const AUTO_RECONCILE2B_REPORT_FAILED = "AUTO_RECONCILE2B_REPORT_FAILED";


export const AUTO_REFRESH_REPORT_INIT = "AUTO_REFRESH_REPORT_INIT";
export const AUTO_REFRESH_REPORT_FINISH = "AUTO_REFRESH_REPORT_FINISH";
export const AUTO_REFRESH_REPORT_SUCCESS = "AUTO_REFRESH_REPORT_SUCCESS";
export const AUTO_REFRESH_REPORT_FAILED = "AUTO_REFRESH_REPORT_FAILED";

export const RECONCILE_REASONS_LIST = "RECONCILE_REASONS_LIST";


export const UPDATE_RECONCILE_REASONS_INIT = "UPDATE_RECONCILE_REASONS_INIT";
export const UPDATE_RECONCILE_REASONS_FINISH = "UPDATE_RECONCILE_REASONS_FINISH";
export const UPDATE_RECONCILE_REASONS_SUCCESS = "UPDATE_RECONCILE_REASONS_SUCCESS";
export const UPDATE_RECONCILE_REASONS_FAILED = "UPDATE_RECONCILE_REASONS_FAILED";


export const GSTR_YEARS_LIST_INIT = "GSTR_YEARS_LIST_INIT";
export const GSTR_YEARS_LIST_FINISH = "GSTR_YEARS_LIST_FINISH";

export const GSTR2A_SUMMARY_INVOICE_DATA_UPDATE = "GSTR2A_SUMMARY_INVOICE_DATA_UPDATE";

export const CHECK_MOBILE_NO_VERIFIED = "CHECK_MOBILE_NO_VERIFIED";
export const CHECK_MOBILE_NO_VERIFIED_SUCCESS = "CHECK_MOBILE_NO_VERIFIED_SUCCESS";
export const CHECK_MOBILE_NO_VERIFIED_FAILURE = "CHECK_MOBILE_NO_VERIFIED_FAILURE";


export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const OTP_VERIFICATION_RESET = "OTP_VERIFICATION_RESET";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE";

export const SUBMIT_OTP = "SUBMIT_OTP";
export const SUBMIT_OTP_RESET = "SUBMIT_OTP_RESET";
export const SUBMIT_OTP_SUCCESS = "SUBMIT_OTP_SUCCESS";
export const SUBMIT_OTP_FAILURE = "SUBMIT_OTP_FAILURE";

export const PURCHASE_REG_FILE_UPLOAD = "PURCHASE_REG_FILE_UPLOAD";
export const PURCHASE_REG_FILE_UPLOAD_SUCCESS = "PURCHASE_REG_FILE_UPLOAD_SUCCESS";
export const PURCHASE_REG_FILE_UPLOAD_FAILURE = "PURCHASE_REG_FILE_UPLOAD_FAILURE";

export const VALIDATE_CSV_FILE = "VALIDATE_CSV_FILE";
export const VALIDATE_CSV_FILE_SUCCESS = "VALIDATE_CSV_FILE_SUCCESS";
export const VALIDATE_CSV_FILE_FAILURE = "VALIDATE_CSV_FILE_FAILURE";


export const DOWNLOAD_ERRORS_CSV = "DOWNLOAD_ERRORS_CSV";
export const DOWNLOAD_ERRORS_CSV_SUCCESS = "DOWNLOAD_ERRORS_CSV_SUCCESS";
export const DOWNLOAD_ERRORS_CSV_FAILURE = "DOWNLOAD_ERRORS_CSV_FAILURE";

export const SAVE_SOCIAL_AUTH_DATA = "SAVE_SOCIAL_AUTH_DATA";
export const SAVE_SOCIAL_AUTH_DATA_SUCCESS = "SAVE_SOCIAL_AUTH_DATA_SUCCESS";
export const SAVE_SOCIAL_AUTH_DATA_FAILURE = "SAVE_SOCIAL_AUTH_DATA_FAILURE";
export const RESET_SOCIAL_AUTH_DATA = "RESET_SOCIAL_AUTH_DATA";


export const RESEND_VERIFICATION_LINK = "RESEND_VERIFICATION_LINK";
export const RESEND_VERIFICATION_LINK_SUCCESS = "RESEND_VERIFICATION_LINK_SUCCESS";
export const RESEND_VERIFICATION_LINK_FAILURE = "RESEND_VERIFICATION_LINK_FAILURE";



export const SET_ACTIVATION_PENDING_DATA = "SET_ACTIVATION_PENDING_DATA";
export const SET_ACTIVATION_PENDING_DATA_SUCCESS = "SET_ACTIVATION_PENDING_DATA_SUCCESS";
export const SET_ACTIVATION_PENDING_DATA_FAILURE = "SET_ACTIVATION_PENDING_DATA_FAILURE";

export const APPLE_SIGN_IN_FAILED = "APPLE_SIGN_IN_FAILED";

export const SET_DASHBOARD_EXTERNAL_SUPPLIER_DETAILS = "SET_DASHBOARD_EXTERNAL_SUPPLIER_DETAILS";

export const RESEND_INVITATION_LINK = "RESEND_INVITATION_LINK";
export const RESEND_INVITATION_LINK_SUCCESS = "RESEND_INVITATION_LINK_SUCCESS";
export const RESEND_INVITATION_LINK_FAILURE = "RESEND_INVITATION_LINK_FAILURE";


export const USER_VERIFICATION = "USER_VERIFICATION";
export const USER_VERIFICATION_SUCCESS = "USER_VERIFICATION_SUCCESS";
export const USER_VERIFICATION_FAILURE = "USER_VERIFICATION_FAILURE";


export const VERIFY_RESET_PASSWORD = "VERIFY_RESET_PASSWORD";
export const VERIFY_RESET_PASSWORD_SUCCESS = "VERIFY_RESET_PASSWORD_SUCCESS";
export const VERIFY_RESET_PASSWORD_FAILURE = "VERIFY_RESET_PASSWORD_FAILURE";

export const SUBMIT_RESET_PASSWORD_DATA = "SUBMIT_RESET_PASSWORD_DATA";
export const SUBMIT_RESET_PASSWORD_DATA_SUCCESS = "SUBMIT_RESET_PASSWORD_DATA_SUCCESS";
export const SUBMIT_RESET_PASSWORD_DATA_FAILURE = "SUBMIT_RESET_PASSWORD_DATA_FAILURE";

export const DELETE_PURCHASE_INVOICE_LIST = "DELETE_PURCHASE_INVOICE_LIST";
export const DELETE_PURCHASE_INVOICE_LIST_SUCCESS = "DELETE_PURCHASE_INVOICE_LIST_SUCCESS";
export const DELETE_PURCHASE_INVOICE_LIST_FAILURE = "DELETE_PURCHASE_INVOICE_LIST_FAILURE";


export const REGISTER_INVITEE = "REGISTER_INVITEE"
export const REGISTER_INVITEE_SUCCESS = "REGISTER_INVITEE_SUCCESS"
export const REGISTER_INVITEE_FAILURE = "REGISTER_INVITEE_FAILURE"



export const ACTIVE_TAB_INDEX = "ACTIVE_TAB_INDEX"

export const DELETE_ASSIGN_USERS = "DELETE_ASSIGN_USERS"
export const DELETE_ASSIGN_USERS_SUCCESS = "DELETE_ASSIGN_USERS_SUCCESS"
export const DELETE_ASSIGN_USERS_FAILURE = "DELETE_ASSIGN_USERS_FAILURE"

export const SAVE_USER_FEEDBACK = "SAVE_USER_FEEDBACK";
export const SAVE_USER_FEEDBACK_SUCCESS = "SAVE_USER_FEEDBACK_SUCCESS";
export const SAVE_USER_FEEDBACK_FAILURE = "SAVE_USER_FEEDBACK_FAILURE";
export const SAVE_USER_FEEDBACK_DEFAULT = "SAVE_USER_FEEDBACK_DEFAULT";




export const SET_BOOKS_INVOICE_COUNT = "SET_BOOKS_INVOICE_COUNT";
export const SET_BOOKS_INVOICE_TAXABLE_VAL = "SET_BOOKS_INVOICE_TAXABLE_VAL";
export const SET_BOOKS_INVOICE_IGST = "SET_BOOKS_INVOICE_IGST";
export const SET_BOOKS_INVOICE_SGST = "SET_BOOKS_INVOICE_SGST";
export const SET_BOOKS_INVOICE_CGST = "SET_BOOKS_INVOICE_CGST";
export const SET_BOOKS_INVOICE_CESS = "SET_BOOKS_INVOICE_CESS";
export const SET_BOOKS_SUPPLIER_COUNT = "SET_BOOKS_SUPPLIER_COUNT";


export const UPDATE_CONTACT_BY_ID = "UPDATE_CONTACT_BY_ID"
export const UPDATE_CONTACT_BY_ID_SUCCESS = "UPDATE_CONTACT_BY_ID_SUCCESS"
export const UPDATE_CONTACT_BY_ID_FAILURE = "UPDATE_CONTACT_BY_ID_FAILURE"

export const SELECTED_THEME = "SELECTED_THEME";

export const SELECTED_GSTIN_LIST_STORE = "SELECTED_GSTIN_LIST_STORE";

export const VIEW_RECON_REPORT = "VIEW_RECON_REPORT";
export const VIEW_RECON_REPORT_ID = "VIEW_RECON_REPORT_ID";
export const VIEW_RECON_REPORT_TYPE = "VIEW_RECON_REPORT_TYPE";

export const REPORT_ROUTE_PARAM = "REPORT_ROUTE_PARAM";