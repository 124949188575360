import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import Tracker from '@openreplay/tracker';
import OpenReplay from '@openreplay/tracker/cjs';
import cookie from 'react-cookies'
import trackerAssist from '@openreplay/tracker-assist';
import { startOpenReplay } from "src/util/globalUrls";



const OpenReplayComponent = () => {
    const userData = useSelector((state) => state?.userReduser?.loginUserData);
    const tracker = new OpenReplay({
        projectKey: "emArUlneIi8H7mxT1Yeb",
        onStart: ({ sessionID }) => console.log("OpenReplay tracker started with session: ", sessionID),
        // ingestPoint: "https://openreplay.finsights.biz/ingest",
        __DISABLE_SECURE_MODE: window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? true : false
    });
    const options = {
        callConfirm: ConfirmOptions,
        controlConfirm: ConfirmOptions,
        // config: object,
        // onAgentConnect?: onAgentConnect(),
        // onCallStart?: () => onCallStart(),
        // onRemoteControlStart?: onCallStart(),
    }
    const ConfirmOptions = {
        text: "Hello Murali",
        style: { color: 'red', borderRadius: '10px' }, // style object (i.e {color: 'red', borderRadius: '10px'})
        confirmBtn: ButtonOptions,
        declineBtn: ButtonOptions
    }
    const ButtonOptions = {
        innerHTML: "Hello Assist", // to pass an svg string or text
        style: { color: 'red', borderRadius: '10px' }, // style object (i.e {color: 'red', borderRadius: '10px'})
    }
    const onAgentConnect = () => {
        console.log("Live session started")
        const onAgentDisconnect = () => console.log("Live session stopped")
        return onAgentDisconnect
    }
    const onCallStart = () => {
        console.log("Call started")
        const onCallEnd = () => console.log("Call ended")
        return onCallEnd
    }



    useEffect(() => {
        if (userData && !Array.isArray(userData) && cookie.load("GST_USER_DATA") && startOpenReplay) {
            tracker.use(trackerAssist({}))
            tracker.start({
                userID: userData?.userEmail,
                metadata: {
                    plan: "free"
                }
            });

        } else {
            tracker.stop();
        }
    }, [userData]);



    return <></>;
};

export default OpenReplayComponent;
