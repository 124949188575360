import {DOWNLOAD_STATUS_INIT, DOWNLOAD_STATUS_FINISH, DOWNLOAD_STATUS_SUCCESS, DOWNLOAD_STATUS_FAILED, DOWNLOAD_STATUS_SINGLE_INIT, DOWNLOAD_STATUS_SINGLE_FINISH, DOWNLOAD_STATUS_SINGLE_SUCCESS, DOWNLOAD_STATUS_SINGLE_FAILED, FROM_MONTH, TO_MONTH, SET_DOWNLOAD_PERIOD} from 'src/action-type/Gstr3b';

const initialState = {
    fetchingAll: false,
    data3b: null,
    downloadStatusSuccess: null,
    singleFetching: false,
    singleDownloadStatus: null,
    singleDownloadMessage: null,
    fromMonth: null,
    toMonth: null,
    downloadPeriod: null,
};

function DownloaStatusReducer3b(state=initialState, action) {
    switch(action.type) {
        case DOWNLOAD_STATUS_INIT:
            return {...state, fetchingAll: true}

        case DOWNLOAD_STATUS_FINISH:
            return {...state, fetchingAll: false}

        case DOWNLOAD_STATUS_SUCCESS:
            return {...state, data3b: action.payload, downloadStatusSuccess: true}

        case DOWNLOAD_STATUS_FAILED:
            return {...state, downloadStatusSuccess: false}

        case DOWNLOAD_STATUS_SINGLE_INIT:
            return {...state, singleFetching: true}

        case DOWNLOAD_STATUS_SINGLE_FINISH:
            return {...state, singleFetching: false}

        case DOWNLOAD_STATUS_SINGLE_SUCCESS:
            return {...state, singleDownloadStatus: action.payload}

        case DOWNLOAD_STATUS_SINGLE_FAILED:
            return {...state, singleDownloadMessage: action.payload}

            case FROM_MONTH:
                return {...state, fromMonth: action.payload}
    
            case TO_MONTH:
                return {...state, toMonth: action.payload}
                
        case SET_DOWNLOAD_PERIOD:
            return {...state, downloadPeriod: action.payload}

        default:
            return state;
    }
};
 
export default DownloaStatusReducer3b;