import {
  SHARE_REPORT,
  SHARE_REPORT_FAILURE,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_INIT_STATE,

  AUTO_RECONCILE2A_REPORT_INIT,
  AUTO_RECONCILE2A_REPORT_FINISH,
  AUTO_RECONCILE2A_REPORT_DATA,
  AUTO_RECONCILE2A_REPORT_FAILED,


  AUTO_RECONCILE2B_REPORT_INIT,
  AUTO_RECONCILE2B_REPORT_FINISH,
  AUTO_RECONCILE2B_REPORT_DATA,
  AUTO_RECONCILE2B_REPORT_FAILED,

  AUTO_REFRESH_REPORT_INIT,
  AUTO_REFRESH_REPORT_FINISH,
  AUTO_REFRESH_REPORT_SUCCESS,
  AUTO_REFRESH_REPORT_FAILED,

  REPORTS_DOWNLOAD_INIT,
  REPORTS_DOWNLOAD_FINISH,

  SET_BOOKS_INVOICE_COUNT,
  SET_BOOKS_SUPPLIER_COUNT,
  SET_BOOKS_INVOICE_TAXABLE_VAL,
  SET_BOOKS_INVOICE_IGST,
  SET_BOOKS_INVOICE_SGST,
  SET_BOOKS_INVOICE_CGST,
  SET_BOOKS_INVOICE_CESS,
  REPORT_ROUTE_PARAM,

} from "src/actions/actionTypes";
import { ReportSharingStatus } from "src/util/enums";

const initialState = {
  reportSharingStatus: ReportSharingStatus.NONE,


  auto2aReportReqStatus: false,
  auto2aReportData: null,
  auto2aReportMessage: '',


  auto2bReportReqStatus: false,
  auto2bReportData: null,
  auto2bReportMessage: '',

  reasonsUpdateData: null,

  autoRefreshReportReqStatus: false,

  selectedYearRange: '',

  reportsDownloadStatus: false,


  invoiceCount: null,
  supplierCount: null,
  totalTaxableValue: null,
  invoiceTotalIGST: null,
  invoiceTotalSGST: null,
  invoiceTotalCGST: null,
  invoiceTotalCESS: null,

  reportRouteParam: null,

};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {

    case REPORT_ROUTE_PARAM: {
      return {...state, reportRouteParam: action.payload}
    }

    case SET_BOOKS_SUPPLIER_COUNT : {
      return {...state, supplierCount: action.payload}
    }

    case SET_BOOKS_INVOICE_COUNT : {
      return {...state, invoiceCount: action.payload}
    }
    case SET_BOOKS_INVOICE_TAXABLE_VAL : {
      return {...state, totalTaxableValue: action.payload}
    }
    case SET_BOOKS_INVOICE_IGST : {
      return {...state, invoiceTotalIGST: action.payload}
    }
    case SET_BOOKS_INVOICE_SGST : {
      return {...state, invoiceTotalSGST: action.payload}
    }
    case SET_BOOKS_INVOICE_CGST : {
      return {...state, invoiceTotalCGST: action.payload}
    }
    case SET_BOOKS_INVOICE_CESS : {
      return {...state, invoiceTotalCESS: action.payload}
    }

    case "CHANGE_YEAR_RANGE": {
      return { ...state, selectedYearRange: action.payload }
    }


    //// 2b
    case AUTO_RECONCILE2B_REPORT_INIT: {
      return { ...state, auto2bReportReqStatus: true }
    }

    case AUTO_RECONCILE2B_REPORT_FINISH: {
      return { ...state, auto2bReportReqStatus: false }
    }

    case AUTO_RECONCILE2B_REPORT_DATA: {
      return { ...state, auto2bReportData: action.payload }
    }

    case AUTO_RECONCILE2B_REPORT_FAILED: {
      return { ...state, auto2bReportMessage: action.payload }
    }

    //// 2b ends here...



    //// 2a
    case AUTO_RECONCILE2A_REPORT_INIT: {
      return { ...state, auto2aReportReqStatus: true }
    }

    case AUTO_RECONCILE2A_REPORT_FINISH: {
      return { ...state, auto2aReportReqStatus: false }
    }

    case AUTO_RECONCILE2A_REPORT_DATA: {
      return { ...state, auto2aReportData: action.payload }
    }

    case AUTO_RECONCILE2A_REPORT_FAILED: {
      return { ...state, auto2aReportMessage: action.payload }
    }

    //// 2a ends here...


    case AUTO_REFRESH_REPORT_INIT: {
      return { ...state, autoRefreshReportReqStatus: true }
    }


    case AUTO_REFRESH_REPORT_FINISH: {
      return { ...state, autoRefreshReportReqStatus: false }
    }

    case "CHECK_RESONS_UPDATE_DATA": {
      return { ...state, reasonsUpdateData: action.payload }
    }


    case SHARE_REPORT_INIT_STATE: {
      return { ...state, reportSharingStatus: ReportSharingStatus.NONE };
    }
    case SHARE_REPORT: {
      return { ...state, reportSharingStatus: ReportSharingStatus.INPROGRESS };
    }
    case SHARE_REPORT_SUCCESS: {
      return { ...state, reportSharingStatus: ReportSharingStatus.SUCCESS };
    }
    case SHARE_REPORT_FAILURE: {
      return { ...state, reportSharingStatus: ReportSharingStatus.FAILED };
    }


    case REPORTS_DOWNLOAD_INIT: {
      return { ...state, reportsDownloadStatus: true }
    }
    case REPORTS_DOWNLOAD_FINISH: {
      return { ...state, reportsDownloadStatus: false }
    }


    default:
      return state;
  }
}
