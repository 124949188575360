import {
    SAVE_MESSAGE,
    SAVE_MESSAGE_SUCCESS,
    SAVE_MESSAGE_FAILURE,
    GET_MESSAGE,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_FAILURE,
    SAVE_MESSAGE_DEFAULT,
    SAVE_USER_FEEDBACK,
    SAVE_USER_FEEDBACK_SUCCESS,
    SAVE_USER_FEEDBACK_FAILURE,
    SAVE_USER_FEEDBACK_DEFAULT
} from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";

const initialState = {
    chatStatus: InsertFunctionStatus.NONE,
    messages: []
};

export default function chatReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_MESSAGE: {
            return { ...state, chatStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_MESSAGE_SUCCESS: {
            return { ...state, chatStatus: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_MESSAGE_FAILURE: {
            return { ...state, chatStatus: InsertFunctionStatus.FAILED };
        }
        case SAVE_MESSAGE_DEFAULT: {
            return { ...state, chatStatus: InsertFunctionStatus.NONE };
        }
        case GET_MESSAGE_SUCCESS: {
            return { ...state, messages: action.payload };
        }
        case SAVE_USER_FEEDBACK: {
            return { ...state, feedbackSaveStatus: InsertFunctionStatus.INPROGRESS };
        }
        case SAVE_USER_FEEDBACK_SUCCESS: {
            return { ...state, feedbackSaveStatus: InsertFunctionStatus.SUCCESS };
        }
        case SAVE_USER_FEEDBACK_FAILURE: {
            return { ...state, feedbackSaveStatus: InsertFunctionStatus.FAILED };
        }
        case SAVE_USER_FEEDBACK_DEFAULT: {
            return { ...state, feedbackSaveStatus: InsertFunctionStatus.NONE };
        }
        default:
            return state;
    }
}

