import {
    GET_CASH_PAYMENTS_SUCCESS, 
    GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS,
    GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS,
    GET_AMOUNT_BY_REF_NO_SUCCESS,
    GET_BILL_REF_NO_SUCCESS,
    GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS,
    CHEK_DUPLICATE_BILL_REF_NO_SUCCESS
} from "src/actions/actionTypes";
import { getList, InsertFunctionStatus } from "src/util/enums";

const initialState = {
   cashPayments: "",
   paymentMadeFromLedgerList: [],
   paymentMadeToLedgerList: [],
   billRefByLedger:[],
   amountByBillRef:[],
   ledgerListForDoubleEntry:[],
   duplocateCount:{}
};

export default function cashPaymentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CASH_PAYMENTS_SUCCESS: {
            return { ...state, cashPayments: action.payload };
        }
        case GET_PAYMENT_MADE_FROM_LEDGER_LIST_SUCCESS: {
            return { ...state, paymentMadeFromLedgerList: action.payload}
        }
        case GET_PAYMENT_MADE_TO_LEDGER_LIST_SUCCESS: {
            return { ...state, paymentMadeToLedgerList: action.payload}
        }
        case GET_AMOUNT_BY_REF_NO_SUCCESS: {
            return { ...state, amountByBillRef: action.payload}
        }
        case GET_BILL_REF_NO_SUCCESS: {
            return { ...state, billRefByLedger: action.payload}
        }
        case GET_LEDGER_LIST_FOR_DOUBLE_ENTRY_SUCCESS: {
            return { ...state, ledgerListForDoubleEntry: action.payload}
        }
        case CHEK_DUPLICATE_BILL_REF_NO_SUCCESS: {
            return { ...state, duplocateCount: action.payload}
        }
        
        default:
            return state;
    }
}
