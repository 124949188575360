import { combineReducers } from "redux";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import questions, { questionSelected } from "./questionReducer";
import { ledger } from "./ledgerReducer";
import bankLedgerReducer from "./bankLedgersReduer";
import groupLedgerReducer from "./groupLedgerReducer";
import voucherReducer from "./voucherReducer";
import groupsReducer from "./groupsReducer";
import reportsReducer from "./reportsReducer";
import attachmentsReducer from "./attachmentsReducer";
import chipsReducer from "./chipsReducer";
import remarksReducer from "./remarksReducer";
import queryReducer from "./queryReducer";
import chatReducer from "./chatReducer";
import registration from "./registrationReducer";
import expenseReduser from "./expenseReducer";
import trnasactionTypeDateFilterReducer from "./trnasactionTypeDateFilterReducer";
import miscReducer from "./miscReducer";
import cashPaymentsReducer from "./cashPaymentsReducer";
import salesReducer from "./salesReducer";
import purchaseReducer from "./purchaseReducer";
import dashBoardReducer from "./dashBoardReducer";
import invenoryReducer from "./inventoryReducer";
import globalFilterReducer from "./globalFilterReducer"
import paymentReducer from "./paymentReducer"
import getGroupDataReducer from './getGroupDataReducer';
import postGroupDataReducer from './postGroupDataReducer';
import salesOrderReducer from './salesOrderReducer'
import userReduser from './userReducer'
import chartReducer from './chartReducer';
import Gstr1Reducer from './Gstr/Gstr1Reducer';
import DownloadStatusReducer from './Gstr/DownloadStatusReducer';
import Gstr1TransactionsReducer from './Gstr/Gstr1TransactionsReducer';
import SummaryReducer3b from './Gstr-3b/summaryReducer3b';
import DownloaStatusReducer3b from './Gstr-3b/downloadStatusReducer3b';
import GstAuthReducer from './gstr-auth';
import gstr2aReducer from './gstr2aReducer';
import reconcileReducer from './reconcileReducer';
import Gstr2bReducer from './Gstr2b/Gstr2bReducer';
import supplierReducer from './supplierReducer';


const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userReduser"]
}


const rootReducer = combineReducers({
  changeState,
  questions: questions,
  questionSelected,
  ledger,
  bankLedgerReducer,
  groupLedgerReducer,
  voucherReducer,
  groupsReducer,
  reportsReducer,
  attachmentsReducer,
  chipsReducer,
  remarksReducer,
  queryReducer,
  chatReducer,
  registration,
  expenseReduser,
  trnasactionTypeDateFilterReducer,
  miscReducer,
  cashPaymentsReducer,
  salesReducer,
  purchaseReducer,
  dashBoardReducer,
  invenoryReducer,
  globalFilterReducer,
  paymentReducer,
  getGroupDataReducer,
  postGroupDataReducer,
  salesOrderReducer,
  userReduser,
  chartReducer,
  Gstr1Reducer,
  GstAuthReducer,
  DownloadStatusReducer,
  Gstr1TransactionsReducer,
  SummaryReducer3b,
  DownloaStatusReducer3b,
  gstr2aReducer,
  Gstr2bReducer,
  reconcileReducer,
  supplierReducer
});

export default persistReducer(persistConfig, rootReducer);

