import {
  GET_SALES_LEDGER,
  GET_SALES_LEDGER_SUCCESS,
  GET_SALES_LEDGER_ERROR,

  GET_LEDGER,
  GET_LEDGER_SUCCESS,
  GET_LEDGER_ERROR,

  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
   } from "src/actions/actionTypes";
   
   const initialState = {
     salesLedgerList:{},
     ledgersList:[],
     itemList:[]

   };
   export default function salesOrderReducer(state = initialState, action) {
     switch (action.type) {
       case GET_SALES_LEDGER_SUCCESS: {
         return { ...state, salesLedgerList: action.payload };
       }case GET_SALES_LEDGER_ERROR: {
        return { ...state, salesLedgerList: action.payload };
      }

      case GET_LEDGER_SUCCESS: {
        return { ...state, ledgersList: action.payload };
      }case GET_LEDGER_ERROR: {
       return { ...state, ledgersList: action.payload };
     }

     case GET_ITEMS_SUCCESS: {
      return { ...state, itemList: action.payload };
    }case GET_ITEMS_ERROR: {
     return { ...state, itemList: action.payload };
   }

       default:
         return state;
     }
   }
   